import React, { useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { userSignUp } from 'appRedux/thunks/auth';
import ErrorBoundary from 'components/common/ErrorBoundary';
import { Grid, Container, Typography } from 'components/library';
import { parseQueryString, fm } from 'utils/string';
import SignupForm from 'components/Register/SignupForm';
import { setAuthFields } from 'appRedux/actions';

const RegistrationForm = (props) => {
  const dispatch = useDispatch();
  const { emailAddress = '' } = useMemo(() => parseQueryString(props.location.search), []);

  useEffect(() => {
    dispatch(setAuthFields({ signupFormErrors: {} }));
  }, []);

  const handleSubmit = (formData) => {
    dispatch(userSignUp(formData, props.history));
  };

  return (
    <ErrorBoundary>
      <Container component="main" maxWidth="sm">
        <Grid container alignItems="center" justifyContent="center" spacing={2}>
          <Grid item>
            <Typography variant="h6" align="center">
              {fm('singup_bos')}
            </Typography>
            <Typography align="center">
              {fm('signup_description_text')}
            </Typography>
          </Grid>
          <SignupForm defaultEmail={emailAddress} onSubmit={handleSubmit} />
        </Grid>
      </Container>
    </ErrorBoundary>
  );
};

export default RegistrationForm;
