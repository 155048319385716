import React from 'react';
import Logo from 'images/Logo-bos.png';
import { fm } from 'utils/string';
import { DoneIcon } from 'components/library/icons';
import { registerNews } from 'components/common/LoginSignUp/infoTexts';
import {
  Grid,
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Container,
} from 'components/library';

const SignupInfo = () => (
  <Container component="main" maxWidth="xs">
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <img
            height={48}
            width="auto"
            src={Logo}
            alt="Bokning och Schema Online Logo"
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography component="span" align="center">
          <Box fontWeight="bold">
            {fm('trial_plan')}
          </Box>
        </Typography>
      </Grid>
      <Typography component="span">
        <Box>
          {fm('free_trial_message')}
        </Box>
      </Typography>

      <Grid item xs={12}>
        <List dense disablePadding>
          {registerNews.map((news) => (
            <ListItem key={news}>
              <ListItemIcon>
                <DoneIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary={fm(news)} />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  </Container>
);

export default SignupInfo;
