import {
  SET_CALENDAR_FIELDS,
  SET_BIG_CALENDAR_FIELDS,
  SET_SMART_SCHEDULE_FIELDS,
  SET_USERS_PROFILE_IMAGES,
} from '../actions';

const initialState = {
  loading: true,
  activeTab: 0,
  resources: [],
  redDays: [],
  events: {},
  requests: [],
  employeeTimeReports: {},
  orders: {},
  bookingPunches: [],
  orderPunches: [],
  salaryCodeReports: {},
  bigCalendar: {
    records: [],
    orders: [],
  },
  orderWorkingHours: {
    records: [],
  },
  workingHours: {
    records: [],
  },
  smartSchedule: {},
  profileImages: [],
  scheduleRecords: {},
};

function calendar (state = initialState, action) {
  switch (action.type) {
    case SET_CALENDAR_FIELDS:
      return {
        ...state,
        ...action.payload,
      };

    case SET_BIG_CALENDAR_FIELDS: {
      return {
        ...state,
        bigCalendar: {
          ...state.bigCalendar,
          ...action.payload,
        },
      };
    }

    case SET_SMART_SCHEDULE_FIELDS:
      return {
        ...state,
        smartSchedule: {
          ...state.smartSchedule,
          ...action.payload,
        },
      };

    case SET_USERS_PROFILE_IMAGES:
      return {
        ...state,
        profileImages: {
          ...state.profileImages,
          ...action.payload,
        },
      };

    default:
      return state;
  }
}

export default calendar;
