import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'utils/lodash';
import { Grid, Hidden } from 'components/library';
import { fm } from 'utils/string';
import ItemRow from './ItemRow';

const PriceSection = (props) => {
  const { proposalItems = [], companyDetails = {}, proposal } = props;

  return (
    <Grid item sm={12}>
      <Hidden smDown>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr style={{ backgroundColor: '#1891DC', padding: '10px', color: '#FFFFFF', fontWeight: 'normal' }}>
              <th align="left" style={{ padding: '8px' }}>{fm('item')}</th>
              <th align="center">{fm('quantity')}</th>
              <th align="center">{fm('unit')}</th>
              <th align="center">{fm('price_per_unit')}</th>
              <th align="center">{fm('discount')}</th>
              <th align="center">{fm('rut_rot')}</th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(proposalItems) && proposalItems.map((item, index) => (
              <tr
                key={item.id}
                style={{ backgroundColor: index % 2 === 1 ? '#F7F7F8' : 'transparent', padding: '20px' }}
              >
                <td style={{ padding: '8px' }}>{item.title}</td>
                <td align="center" style={{ padding: '8px' }}>{item.quantity}</td>
                <td align="center" style={{ padding: '8px' }}>{item.unit}</td>
                <td align="center" style={{ padding: '8px' }}>{item.perUnit}</td>
                <td align="center" style={{ padding: '8px' }}>{item.discount > 0 ? `${item.discount}%` : '-'}</td>
                <td align="center" style={{ padding: '8px' }}>
                  {item.isHouseWork ? proposal.houseworkType.toUpperCase() : '-'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Hidden>
      <Hidden mdUp />
      <Hidden mdUp>
        {proposalItems.map((item) => (
          <ItemRow
            key={item.id}
            item={item}
            companyDetails={companyDetails}
            proposal={proposal}
          />
        ))}
      </Hidden>
    </Grid>
  );
};

PriceSection.propTypes = {
  proposalItems: PropTypes.shape([]),
  companyDetails: PropTypes.shape({}),
  proposal: PropTypes.shape({
    houseworkType: PropTypes.string,
  }),
};

export default PriceSection;
