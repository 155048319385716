export const loginNews = [
  'login_news_1',
  'login_news_2',
  'login_news_3',
  'login_news_4',
  'login_news_5',
];

export const registerNews = [
  'register_news_1',
  'register_news_2',
  'register_news_3',
  'register_news_4',
  'register_news_5',
  'register_news_6',
];
