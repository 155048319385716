import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { fm } from 'utils/string';
import { Box, Typography, Grid, Hidden } from 'components/library';
import { ErrorIcon } from 'components/library/icons';

export default class ErrorBoundary extends React.Component {
  constructor () {
    super();
    this.state = { hasError: false };
  }

  static getDerivedStateFromError (/* error */) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch (error, errorInfo) {
    Sentry.captureException(error, { extra: errorInfo });
    console.error(error); // eslint-disable-line
    console.error(errorInfo); // eslint-disable-line
  }

  render () {
    return this.state.hasError ? (
      <Grid container alignItems="center" justifyContent="center" alignContent="center">
        <Hidden xsDown>
          <Grid item sm={12}>
            <Box textAlign="center" mt={5}>
              <ErrorIcon color="error" />
              <Typography variant="h5">
                {fm('something_went_wrong', 'Something went wrong.')}
              </Typography>
              <Typography variant="body1">
                {fm('issue_has_reported', 'Issue has been reported to administrator.')}
              </Typography>
            </Box>
          </Grid>
        </Hidden>
      </Grid>
    ) : this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};
