import getItemsTotalCosts, { getItemCost } from 'utils/getCost';
import { orderPriceType } from 'utils/enum';
import { flatten } from 'utils/lodash';
import { formatDate, formatStrings } from 'utils/dateTime';
import { getFilterCustomerId, getFilterEmployeeId, getFilterServiceId, getFilterTagId } from '../../utility';

export const pendingInvoicesFilters = (filters) => ({
  start_date: filters.startDate,
  end_date: filters.endDate,
  invoice_date: filters.invoiceDate,
  status: filters.bookingStatus,
  booking_price: filters.bookingPrice,
  type: filters.customerType === 'view_all' ? undefined : filters.customerType,
  advance_search: filters.advanceSearch,
  per_page: filters.perPage,
  page: filters.page,
  customer_id: getFilterCustomerId(filters),
  employee_id: getFilterEmployeeId(filters),
  service_id: getFilterServiceId(filters),
  tag_id: getFilterTagId(filters),
});

export const getFormattedPendingInvoiceRecords = (records, company) => records.map((record) => {
  const skipOrderForProjectId = {};

  const orders = record.orders.map((order) => {
    const orderWithCustomer = { ...order, customer: record.customer };
    const orderItems = order.orderItems.map(
      (orderItem) => ({ ...orderItem, ...getItemCost(orderItem, orderWithCustomer) }),
    );

    return {
      ...order,
      orderItems,
      price: getItemsTotalCosts(orderItems, orderWithCustomer),
      selectable: Boolean(order.orderItems.length),
    };
  });

  const totalPrice = orders.reduce((result, order) => {
    if (skipOrderForProjectId[order.projectId]) return result;
    if (order.priceType === orderPriceType.fixed) skipOrderForProjectId[order.projectId] = true;
    result.total += order.price.total;
    result.toPay += order.price.toPay;
    return result;
  }, { total: 0, toPay: 0 });

  const hasSelectableOrder = orders.some((order) => order.selectable);

  const orderTags = flatten(orders.map((order) => order.orderTags.map((orderTag) => orderTag.tag)));

  const customer = {
    ...record.customer,
    selectable: hasSelectableOrder
      && ((company.fortnoxIntegrated && record.customer.fortnoxId)
      || (company.vismaIntegrated && record.customer.vismaNumber)),
  };

  return { ...record, orders, customer, orderTags, totalPrice };
});

export const getFormattedPendingInvoicesOrders = (orders) => orders.map((order) => {
  const orderItems = order.orderItems.map((orderItem) => ({ ...orderItem, ...getItemCost(orderItem, order) }));

  return {
    ...order,
    orderItems,
    selectable: Boolean(order.orderItems.length),
    price: getItemsTotalCosts(orderItems, order),
    employeePresenceTimes: order.employeePresenceTimes?.map((employeePresenceTime) => ({
      ...employeePresenceTime,
      startTime: employeePresenceTime.startTime
        ? formatDate(employeePresenceTime.startTime.split('+')[0], formatStrings.defaulTime) : '',
      endTime: employeePresenceTime.endTime
        ? formatDate(employeePresenceTime.endTime.split('+')[0], formatStrings.defaulTime) : '',
    })),
  };
});
