import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Hidden } from 'components/library';
import Wrapper from 'components/common/LoginSignUp/Wrapper';

import LoginForm from './LoginForm';
import News from './News';

export default function RegisterAccount (props) {
  return (
    <Wrapper>
      <Grid item xs={12} lg={6}>
        <LoginForm history={props.history} location={props.location} />
      </Grid>
      <Hidden mdDown>
        <Grid item xs={12} lg={6}>
          <News />
        </Grid>
      </Hidden>
    </Wrapper>
  );
}

RegisterAccount.propTypes = {
  history: PropTypes.shape({}),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};
