import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  IconButton,
  Tooltip,
} from 'components/library';
import { requestDownloadProposalDocuments } from 'appRedux/owner/proposals/thunk';
import { fm } from 'utils/string';
import { isEmpty } from 'utils/lodash';
import { GetAppIcon } from 'components/library/icons';

const Attachments = (props) => {
  const dispatch = useDispatch();
  const { documents = [] } = props;

  const onClickDownloadDocument = (attachment) => {
    dispatch(requestDownloadProposalDocuments(attachment));
  };

  return (
    <Box>
      {!isEmpty(documents) && (
        <Typography>
          <Box component="span" fontWeight={600}>
            {fm('attached_documents')}
          </Box>
        </Typography>
      )}
      <Grid container>
        <Grid item xs={12} md={6}>
          {documents.map((doc) => (
            <List dense disablePadding>
              <ListItem disableGutters>
                <ListItemIcon>
                  <Tooltip title={fm('download')} placement="top" arrow>
                    <IconButton aria-label="download">
                      <GetAppIcon onClick={() => onClickDownloadDocument(doc)} />
                    </IconButton>
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary={`${doc.filename}`}
                />
              </ListItem>
            </List>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Attachments;

Attachments.propTypes = {
  documents: PropTypes.shape([]),
};
