import { createAsyncThunk } from '@reduxjs/toolkit';
import snakify from 'utils/snakify';
import { pick, keyBy, get } from 'utils/lodash';
import { setFilters, setSnackbarError, setSnackbarSuccess } from 'appRedux/actions';
import FileSaver from 'file-saver';
import { getFilterEmployeeId, getFilterCustomerId, getFilterServiceId, getFormattedListData } from '../../utility';
import {
  getFormattedEmployeeDetailedRecords,
  getFormattedCustomerReports,
  getFormattedSchedulesData,
  getFormattedServiceReports,
} from './selector';

const getFilters = (filters) => ({
  ...snakify(pick(['page', 'perPage', 'startDate', 'endDate'])(filters)),
  order_status: filters.orderStatus === 'view_all' ? undefined : filters.orderStatus,
  customer_id: getFilterCustomerId(filters),
  service_id: getFilterServiceId(filters),
  employee_id: getFilterEmployeeId(filters),
  order: filters.order,
  order_by: snakify(filters.orderBy),
  user_active_status: filters.userActiveStatus === 'view_all' ? undefined : filters.userActiveStatus,
});

export const requestReportsStats = createAsyncThunk(
  'reportsNext/fetchEmployeeReportsStats',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const query = { params: getFilters(filters) };
      const response = await extra.axios.get('/api/v3/reports/report_stats', query);
      return { ...response.data.data };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_reports_orders_reports_failed'));
      return { records: [] };
    }
  },
);

export const requestEmployeeReports = createAsyncThunk(
  'reportsNext/fetchEmployeeReports',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const query = { params: getFilters(filters) };
      const response = await extra.axios.get('/api/v3/reports/employee_reports', query);
      const { records, ...pagination } = getFormattedListData(response.data.data);
      dispatch(setFilters({ ...pagination, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_reports_employees_reports_failed'));
      return { records: [] };
    }
  },
);

export const requestEmployeeDetailedReports = createAsyncThunk(
  'reportsNext/fetchEmployeeDetailedReports',
  async ({ name, employeeId, subtablePagination }, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = {
        ...getFilters({ ...filters, ...subtablePagination }),
        employee_id: employeeId,
      };
      const response = await extra.axios.get('/api/v3/reports/employee_detailed_report_totals', { params });
      const { records, ...paginationFields } = getFormattedEmployeeDetailedRecords(response.data.data);
      return { employeeId, records, subtablePagination: paginationFields };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_reports_employees_detailed_failed'));
      return { records: [] };
    }
  },
);

export const requestCustomerReports = createAsyncThunk(
  'reportsNext/fetchCustomerReports',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const query = { params: getFilters(filters) };
      const response = await extra.axios.get('/api/v3/reports/customer_reports', query);
      const { records, ...pagination } = getFormattedCustomerReports(response.data.data);
      dispatch(setFilters({ ...pagination, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_reports_customer_reports_failed'));
      return { records: [] };
    }
  },
);

export const requestCustomerDetailedReports = createAsyncThunk(
  'reportsNext/fetchCustomerDetailedReports',
  async ({ name, customerId, subtablePagination }, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = {
        ...getFilters({ ...filters, ...subtablePagination }),
        customer_id: customerId,
      };
      const response = await extra.axios.get('/api/v3/reports/customer_detailed_report_totals', { params });
      const { records, ...paginationFields } = getFormattedCustomerReports(response.data.data);
      return { customerId, records, subtablePagination: paginationFields };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_reports_customers_detailed_failed'));
      return { records: [] };
    }
  },
);

export const requestEmployeeSchedules = createAsyncThunk(
  'reportsNext/fetchEmployeeSchedules',
  async (name, { extra, dispatch, getState }) => {
    const { filters } = extra.getState(name, getState);
    try {
      const params = { page: 1, per_page: 30 };
      const response = await extra.axios.get('/api/v3/schedules', { params });
      const schedules = get('data.data.schedules')(response);
      const { records } = getFormattedSchedulesData(schedules, filters);
      return { scheduleRecords: keyBy('id')(records) };
    } catch (err) {
      dispatch(setSnackbarError('snackbar.schedules.gettingSchedules.error'));
    }
  },
);

export const requestServiceReports = createAsyncThunk(
  'reportsNext/fetchServiceReports',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const query = { params: getFilters(filters) };
      const response = await extra.axios.get('/api/v3/reports/service_report_totals', query);
      const { records, ...pagination } = getFormattedServiceReports(response.data.data);
      dispatch(setFilters({ ...pagination, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_reports_service_reports_failed'));
      return { records: [] };
    }
  },
);

export const requestServiceDetailedReports = createAsyncThunk(
  'reportsNext/fetchServiceDetailedReports',
  async ({ name, serviceId, subtablePagination }, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = {
        ...getFilters({ ...filters, ...subtablePagination }),
        service_id: serviceId,
      };
      const response = await extra.axios.get('/api/v3/reports/service_detailed_report_totals', { params });
      const { records, ...paginationFields } = getFormattedServiceReports(response.data.data);
      return { serviceId, records, subtablePagination: paginationFields };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_reports_services_detailed_failed'));
      return { records: [] };
    }
  },
);

export const requestDownloadEmployeesReport = createAsyncThunk(
  'reportsNext/downloadEmployeesReport',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = getFilters(filters);
      const response = await extra.axios.get('api/v3/reports/employee_reports_excel', { params });
      const { xlsxFile } = response.data.data;
      fetch(`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${xlsxFile.content}`)
        .then((res) => res.blob())
        .then((blob) => FileSaver.saveAs(blob, xlsxFile.name));
      dispatch(setSnackbarSuccess('snackbar_reports_employee_downloaded'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_reports_employee_download_failed'));
      return { records: [] };
    }
  },
);

export const requestDownloadCustomersReport = createAsyncThunk(
  'reportsNext/downloadCustomersReport',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = getFilters(filters);
      const response = await extra.axios.get('api/v3/reports/customer_reports_excel', { params });
      const { xlsxFile } = response.data.data;
      fetch(`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${xlsxFile.content}`)
        .then((res) => res.blob())
        .then((blob) => FileSaver.saveAs(blob, xlsxFile.name));
      dispatch(setSnackbarSuccess('snackbar_reports_customer_downloaded'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_reports_customer_download_failed'));
      return { records: [] };
    }
  },
);

export const requestDownloadServicesReport = createAsyncThunk(
  'reportsNext/downloadServicesReport',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = getFilters(filters);
      const response = await extra.axios.get('api/v3/reports/service_report_totals_excel', { params });
      const { xlsxFile } = response.data.data;
      fetch(`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${xlsxFile.content}`)
        .then((res) => res.blob())
        .then((blob) => FileSaver.saveAs(blob, xlsxFile.name));
      dispatch(setSnackbarSuccess('snackbar_reports_service_downloaded'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_reports_service_download_failed'));
      return { records: [] };
    }
  },
);
