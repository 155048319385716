export const getFormattedSkillRequestBody = (details) => ({
  skill: {
    title: details.title,
    color_id: details.colorId,
    description: details.description,
    skill_junctions_attributes: details.skilledUsers?.map((user) => ({
      user_id: user.userId,
      id: user.id,
      _destroy: user.destroy,
    })),
  },
});
