import React from 'react';
import Logo from 'images/Logo-bos.png';
import {
  Grid,
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Container,
} from 'components/library';
import { fm } from 'utils/string';
import { DoneIcon, ExploreIcon } from 'components/library/icons';
import { loginNews } from 'components/common/LoginSignUp/infoTexts';
import urls from 'utils/urls';
import useStyles from './styles';

const News = () => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <img
              height={48}
              width="auto"
              src={Logo}
              id="bos-login-page-logo"
              alt="Bokning och Schema Online Logo"
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography component="span" className={classes.lightGray} align="center">
            <Box fontWeight="bold" fontSize={30}>
              {fm('whats_new')}
              <Box component="span" ml={2}>
                <ExploreIcon htmlColor="#5d5d5d" />
              </Box>
            </Box>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <List dense disablePadding>
            {loginNews.map((news) => (
              <ListItem key={news}>
                <ListItemIcon>
                  <DoneIcon fontSize="medium" />
                </ListItemIcon>
                <ListItemText
                  primary={fm(news)}
                />
              </ListItem>
            ))}
          </List>
        </Grid>

        <Grid item xs={12}>
          <Typography
            component="a"
            color="textPrimary"
            align="center"
            href={urls.appRequestFeatureUrl()}
            target="_blank"
            rel="noopener"
          >
            <Box fontSize={16} mt={2} className={classes.link}>
              {fm('read_more')}
            </Box>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default News;
