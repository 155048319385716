import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { requestResetPassword } from 'appRedux/thunks/auth';
import { Grid, Container, TextField, Typography, Button } from 'components/library';
import ErrorBoundary from 'components/common/ErrorBoundary';
import bosLogoPng from 'images/Logo-bos.png';
import { fm } from 'utils/string';

const Register = (props) => {
  const submitForm = (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    if (!email) return;
    props.requestResetPassword(email, props.history);
    event.target.reset();
  };

  return (
    <ErrorBoundary>
      <Container component="main" maxWidth="xs">
        <Grid container justifyContent="center" alignItems="center" spacing={2} className="mt-20">
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <img
                height={48}
                width="auto"
                src={bosLogoPng}
                alt="Bokning och Schema Online Logo"
              />
            </Grid>
          </Grid>
          <Grid xs={12} item>
            <Typography component="h1" variant="h5" align="center">
              <FormattedMessage id="reset-password-title" defaultMessage="Reset Password" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={submitForm}>
              <TextField
                variant="outlined"
                size="small"
                type="email"
                margin="normal"
                required
                fullWidth
                id="emailInput"
                label={fm('email', 'Email address')}
                name="email"
                autoFocus
              />
              <Button
                type="submit"
                size="small"
                fullWidth
              >
                {fm('reset-password', 'Reset Password')}
              </Button>
            </form>
          </Grid>
          <Grid item>
            <Typography variant="caption">
              {fm('reset_password_description')}
            </Typography>
          </Grid>
          <Grid item>
            <Link to="/login" variant="body2">
              {fm('back-to-login', 'Take me back to login')}
            </Link>
          </Grid>
        </Grid>
      </Container>
    </ErrorBoundary>
  );
};

export default withRouter(connect(null, { requestResetPassword })(Register));
