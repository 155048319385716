import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { fm } from 'utils/string';
import { useFormik } from 'formik';
import {
  CircularProgress,
  Grid,
  ComposedTextField,
  FormControlLabel,
  Box,
  ErrorMessage,
  Button,
  Checkbox,
  Link,
  Select,
  MenuItem,
} from 'components/library';
import urls from 'utils/urls';
import { organisationTypesList } from 'utils/constant';
import { Link as ButtonLink } from 'react-router-dom';
import { organisationTypes } from 'utils/enum';
import useStyles from './styles';
import validationSchema from './validationSchema';

export default function SignupForm (props) {
  const classes = useStyles();
  const application = useSelector((state) => state.application);
  const auth = useSelector((state) => state.auth);
  const { signupFormErrors } = auth;

  const { values, handleChange, errors, ...formik } = useFormik({
    initialValues: {
      email: props.defaultEmail,
      password: '',
      passwordConfirmation: '',
      name: '',
      companyName: '',
      acceptTerms: false,
      orgType: organisationTypes.limitedLiability,
      identificationNum: '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: props.onSubmit,
  });

  return (
    <Grid item xs={12}>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ComposedTextField
              autoFocus
              size="small"
              fullWidth
              name="name"
              id="name"
              error={Boolean(errors.name)}
              helperText={errors.name}
              label={fm('name')}
              placeholder={fm('enter_name')}
              value={values.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <ComposedTextField
              name="companyName"
              id="company_name"
              error={Boolean(errors.companyName)}
              helperText={errors.companyName}
              label={fm('company_name')}
              value={values.companyName}
              onChange={handleChange}
              size="small"
              fullWidth
              placeholder={fm('company_name')}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              name="orgType"
              value={values.orgType}
              onChange={handleChange}
              inputLabelId="organisation_type"
              label={fm('Organisation type', 'Organisation type')}
              fullWidth
              error={Boolean(errors.orgType)}
              helperText={errors.orgType}
            >
              {organisationTypesList.map(
                (type) => <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>,
              )}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <ComposedTextField
              type="string"
              size="small"
              id="identificationNum"
              name="identificationNum"
              placeholder={fm('identification_num')}
              fullWidth
              value={values.identificationNum}
              error={Boolean(signupFormErrors.identificationNum || errors.identificationNum)}
              helperText={signupFormErrors.identificationNum || errors.identificationNum}
              onChange={handleChange}
              label={fm('identification_num')}
            />
          </Grid>
          <Grid item xs={12}>
            <ComposedTextField
              type="email"
              size="small"
              id="Email"
              name="email"
              placeholder={fm('enter_email')}
              fullWidth
              value={values.email}
              error={Boolean(signupFormErrors.email || errors.email)}
              helperText={signupFormErrors.email || errors.email}
              onChange={handleChange}
              label={fm('email')}
            />
          </Grid>
          <Grid item xs={12}>
            <ComposedTextField
              type="password"
              size="small"
              name="password"
              id="password"
              autoComplete="password"
              error={Boolean(errors.password)}
              helperText={errors.password}
              label={fm('password')}
              value={values.password}
              onChange={handleChange}
              placeholder={fm('choose_password')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <ComposedTextField
              name="passwordConfirmation"
              id="password_confirm"
              autoComplete="password"
              error={Boolean(errors.passwordConfirmation)}
              helperText={errors.passwordConfirmation}
              label={fm('confirm_password')}
              value={values.passwordConfirmation}
              onChange={handleChange}
              type="password"
              size="small"
              placeholder={fm('confirm_password')}
              fullWidth
            />
          </Grid>
        </Grid>
      </form>
      <Grid item xs={12}>
        <ul>
          <li>{fm('use_few_words')}</li>
          <li>{fm('symbol_msg')}</li>
        </ul>
      </Grid>
      <Box display="flex" alignItems="center" my={2} ml={1}>
        <FormControlLabel
          className="mr-2"
          onChange={handleChange}
          name="acceptTerms"
          control={<Checkbox checked={values.acceptTerms} value="Policy" color="primary" />}
          label={fm('accept_terms_conditions')}
        />
        <Link
          target="_blank"
          rel="noopener"
          href={urls.getPrivacyPolicyPdfLink()}
        >
          {fm('privacy_policy')}
        </Link>
      </Box>
      <ErrorMessage text={errors.acceptTerms} />
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Grid item>
            <ButtonLink to="/login" variant="body2" className={classes.link}>
              {fm('back_to_login')}
            </ButtonLink>
          </Grid>
          <Grid item>
            <Button
              onClick={formik.handleSubmit}
              fullWidth
            >
              {application.isLoading
                ? <CircularProgress size={25} color="inherit" />
                : fm('register')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

SignupForm.propTypes = {
  onSubmit: PropTypes.func,
  defaultEmail: PropTypes.string,
};
