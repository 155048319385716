/* eslint-disable no-restricted-syntax */
import camelize from 'camelize';
import snakify from 'utils/snakify';
import { getTimeFromNumber } from 'utils/helpers';
import parse from 'date-fns/parse';
import { fm, htmlStringToString } from 'utils/string';
import { isEmpty, isArray, orderBy, sortBy, pick, map, keyBy, omit } from 'utils/lodash';
import { getDefaultEmployeeEndtime } from 'components/Booking/BookingModal/utility';
import { unassignedId } from 'components/Calendar/Scheduler/utility';
import getItemsTotalCosts, { getItemCost } from 'components/Booking/BookingModal/PriceTab/getTotalCosts';
import {
  bookingStatus,
  bookingTimeTypes,
  calendarEventTypes,
  projectTimeTypesString,
  shiftTimeTypes,
} from 'utils/enum';
import {
  getUnixTimeInHoursMinutes,
  getDifferenceBetweenTime,
  formatDate,
  getHourMinutesInUnixTime,
  getStandardDate,
  epochTimeToFloat,
  addYears,
  formatStrings,
  eachDayOfInterval,
  startOfMonth,
  endOfMonth,
} from 'utils/dateTime';
import {
  getFilterSupervisorId,
  getFilterCustomerId,
  getFilterServiceId,
  getFilterTagId,
  getId,
  parseJson,
  getFormattedPaginationFields,
} from 'appRedux/selectors/utility';
import { formatOrderEmployee } from 'appRedux/owner/orders/selector';
import { getStringForJsonBookingNotes } from '../utility';

const BgColors = { Active: '#F7F7F7', Completed: '#F7F7F7', Canceled: '#F7F7F7' };
const parseDateFormat = 'yyyy-MM-dd HH:mm';

const getFormattedEvent = (event, employee = {}, options = {}) => {
  // `booking_start_time` is employees time.
  // `start_time` is booking time.
  // unassigned bookings have booking_start_time null
  const { resourceIdFieldName = 'employeeId' } = options;
  const startTime = employee.startTime || event.startTime;
  const endTime = employee.endTime || event.endTime || startTime || '08:00';
  const totalEmployeeWorkTime = getUnixTimeInHoursMinutes((event.employeesData || []).reduce((tot, emp) => {
    tot += emp.paidTime;
    return tot;
  }, 0));

  const notesString = getStringForJsonBookingNotes(parseJson(event.notes, event.notes));

  return {
    ...event,
    employeeStartTime: employee.startTime
      ? parse(`${event.startDate} ${startTime}`, parseDateFormat, new Date()) : null,
    startTime,
    endTime,
    breakTime: employee.breakTime,
    totalBookingTime: getDifferenceBetweenTime(endTime, startTime),
    paidTime: employee.paidTime,
    notes: notesString ? htmlStringToString(notesString)?.toLowerCase() : '',
    employeeSequenceNum: employee.sequenceNum,
    id: event.sequenceNum,
    isRecurring: event.isRecurringInstance || event.isRecurring,
    start: parse(`${event.startDate} ${startTime}`, parseDateFormat, new Date()),
    end: parse(`${event.startDate} ${endTime}`, parseDateFormat, new Date()),
    budgetTime: getUnixTimeInHoursMinutes(event.budgetTime) || '00:00',
    totalEmployeeWorkTime,
    resourceId: event[resourceIdFieldName] || unassignedId,
    movable: !event.invoiceId,
    commented: Boolean(event.commented),
    smsScheduled: Boolean(event.smsScheduled),
    invoiceSent: Boolean(event.invoiceId),
    tagsData: parseJson(event.tagsData, []),
    eventType: calendarEventTypes.booking,
    keyId: getId(5),
  };
};

const pickCommonRequestFields = pick(['id', 'comment', 'status', 'wholeday']);
const getFormattedRequest = (event) => {
  const bgColor = BgColors[event.status];

  return {
    ...pickCommonRequestFields(event),
    bgColor,
    employeeName: event.employee_name,
    employeeId: event.employee_id,
    resourceId: event.employee_id,
    companyId: event.company_id,
    requestForWork: event.request_for_work,
    statusUpdatedById: event.Status_updated_by_id,
    statusUpdatedByName: event.Status_updated_by_name,
    sequenceNum: event.sequence_num,
    parentId: event.parent_id,
    start: parse(`${event.start_date} ${event.start_time}`, parseDateFormat, new Date()),
    end: parse(`${event.end_date} ${event.end_time}`, parseDateFormat, new Date()),
    startTime: event.start_time,
    endTime: event.end_time,
    movable: false,
    title: event.request_for ? fm('work') : fm('holiday'),
    eventType: calendarEventTypes.request,
    keyId: getId(5),
  };
};

const getFormattedResources = (resources = [], filters) => {
  const formatResources = map((r) => ({ ...r, keyId: getId(5) }));

  if (!isEmpty(filters.employee_ids)) {
    return formatResources(resources.filter((res) => filters.employee_ids.includes(res.id)));
  }

  return formatResources(orderBy(['isActive'], ['desc'])(sortBy((r) => r.name)(resources)));
};

export const getFormattedResourcesCustomers = (customers) => customers.map((customer) => ({
  ...customer,
  keyId: getId(5),
}));

export const getFormattedCalendarUserGroups = (userGroups, users, filters, companyId) => {
  let resources = [...userGroups, ...users].map((data) => ({
    ...data,
    id: data.title ? `ug-${data.id}` : `em-${data.id}`,
    originalId: data.id,
    keyId: getId(5),
  }));

  if (isEmpty(filters.employee_ids) && !filters.supervisor_id) {
    const unassignedResource = {
      id: unassignedId,
      name: 'Unassigned shifts',
      formattedScheduledTime: '00:00',
      isActive: true,
      keyId: getId(5),
      companyId,
    };
    resources = [unassignedResource, ...resources];
  }

  resources = orderBy(['title', 'name'], ['asc', 'asc'])(resources);

  return resources;
};

export const getFormattedCalendarEmployees = (employees, filters, companyId) => {
  let resources = getFormattedResources(employees, filters);
  if (isEmpty(filters.employee_ids) && !filters.supervisor_id && !filters.skill_id) {
    const unassignedResource = {
      id: unassignedId,
      name: 'Unassigned shifts',
      formattedScheduledTime: '00:00',
      isActive: true,
      keyId: getId(5),
      companyId,
    };
    resources = [unassignedResource, ...resources];
  }

  if (filters.supervisor_id) {
    resources = orderBy(['isSupervisor', 'name'], ['desc', 'asc'])(resources);
  }

  return resources;
};

export const getFormattedCalendarServices = (services, filters) => {
  if (!isEmpty(filters.service_ids)) {
    return services.filter((res) => filters.service_ids.includes(res.id));
  }

  const formattedServices = services.map((service) => ({
    ...service,
    colorCode: service.serviceColorCode,
    colorId: service.serviceColorId,
  }));

  return formattedServices;
};

const formatBookingEmployees = (employees, employeeTimeData) => employees.map((employee) => {
  const employeeTime = employeeTimeData.find((etd) => etd.id === employee.id);

  return {
    ...employee,
    ...employeeTime,
    employeeId: employee.id,
    endTime: getUnixTimeInHoursMinutes(employeeTime.endTime),
    startTime: getUnixTimeInHoursMinutes(employeeTime.startTime),
  };
});

export const getFormattedCalendarBookingsForEmployeeTypeResource = (responseBookings) => {
  if (!isArray(responseBookings)) return [];
  const bookingUserGroupIdMap = responseBookings.reduce((bookingsMap, booking) => {
    booking.customerAddresses = booking.customerAddresses ? parseJson(booking.customerAddresses) : [];
    booking.customerKeys = booking.customerKeys ? parseJson(booking.customerKeys) : [];
    booking.employeesData = formatBookingEmployees(
      parseJson(booking.employeesData, []), parseJson(booking.employeesTimeData, []),
    );
    if (!booking.employeesData.length) bookingsMap.unassigned.push(getFormattedEvent(booking));

    for (const bookingEmployee of booking.employeesData) {
      const formattedBooking = getFormattedEvent(booking, bookingEmployee);
      if (!bookingsMap[bookingEmployee.employeeId]) bookingsMap[bookingEmployee.employeeId] = [];
      bookingsMap[bookingEmployee.employeeId].push(formattedBooking);
    }
    return bookingsMap;
  }, { unassigned: [] });

  const events = Object.keys(bookingUserGroupIdMap).reduce((eventsObj, employeeSqnNum) => {
    eventsObj[employeeSqnNum || unassignedId] = orderBy(
      [(d) => ({ 1: 1, 2: 3, 3: 2 })[d.timeType], (d) => d.employeeStartTime || d.start, (d) => d.customerName],
      ['asc', 'asc', 'asc'],
    )(bookingUserGroupIdMap[employeeSqnNum]);
    return eventsObj;
  }, {});

  return events;
};

export const getFormattedCalendarBookingsForUserGroupTypeResource = (responseBookings) => {
  if (!isArray(responseBookings)) return [];

  const bookingEmployeeIdMap = responseBookings.reduce((bookingsMap, booking) => {
    booking.customerAddresses = booking.customerAddresses ? parseJson(booking.customerAddresses) : [];
    booking.customerKeys = booking.customerKeys ? parseJson(booking.customerKeys) : [];
    booking.userGroupIds = parseJson(booking.userGroupIds, []);
    booking.employeesData = formatBookingEmployees(
      parseJson(booking.employeesData, []), parseJson(booking.employeesTimeData, []),
    );

    if (!booking.employeesData.length) bookingsMap.unassigned.push(getFormattedEvent(booking));

    for (const userGroupId of booking.userGroupIds) {
      const resourceId = `ug-${userGroupId}`;
      const formattedBooking = getFormattedEvent(booking);
      if (!bookingsMap[resourceId]) bookingsMap[resourceId] = [];
      bookingsMap[resourceId].push(formattedBooking);
    }

    for (const bookingEmployee of booking.employeesData) {
      const resourceId = `em-${bookingEmployee.employeeId}`;
      const formattedBooking = getFormattedEvent(booking, bookingEmployee);
      if (!bookingsMap[resourceId]) bookingsMap[resourceId] = [];
      bookingsMap[resourceId].push(formattedBooking);
    }

    return bookingsMap;
  }, { unassigned: [] });

  const events = Object.keys(bookingEmployeeIdMap).reduce((eventsObj, resourceId) => {
    eventsObj[resourceId] = sortBy(['start', 'customerName'])(bookingEmployeeIdMap[resourceId]);
    return eventsObj;
  }, {});

  return events;
};

export const getFormattedCalendarBookingsForServiceTypeResource = (response) => {
  if (!isArray(response)) return [];
  return response.reduce((finalResult, nextBooking) => {
    nextBooking.customerAddresses = nextBooking.customerAddresses ? parseJson(nextBooking.customerAddresses) : [];
    nextBooking.customerKeys = nextBooking.customerKeys ? parseJson(nextBooking.customerKeys) : [];
    nextBooking.employeesData = formatBookingEmployees(
      parseJson(nextBooking.employeesData, []), parseJson(nextBooking.employeesTimeData, []),
    );
    const booking = getFormattedEvent(nextBooking, undefined, { resourceIdFieldName: 'serviceId' });
    if (!finalResult[booking.serviceId]) finalResult[booking.serviceId] = [];
    finalResult[booking.serviceId].push(booking);
    const eventsSortByTimeAndCustomerName = Object.keys(finalResult).reduce((eventsObj, resourceId) => {
      eventsObj[resourceId] = sortBy(['start', 'customerName'])(finalResult[resourceId]);
      return eventsObj;
    }, {});
    return eventsSortByTimeAndCustomerName;
  }, {});
};

export const getFormattedCalendarBookingsForCustomerTypeResource = (response) => {
  if (!isArray(response)) return [];
  return response.reduce((finalResult, nextBooking) => {
    nextBooking.customerAddresses = nextBooking.customerAddresses ? parseJson(nextBooking.customerAddresses) : [];
    nextBooking.customerKeys = nextBooking.customerKeys ? parseJson(nextBooking.customerKeys) : [];
    nextBooking.employeesData = formatBookingEmployees(
      parseJson(nextBooking.employeesData, []), parseJson(nextBooking.employeesTimeData, []),
    );
    const booking = getFormattedEvent(nextBooking, undefined, { resourceIdFieldName: 'customerId' });
    if (!finalResult[booking.customerId]) finalResult[booking.customerId] = [];
    finalResult[booking.customerId].push(booking);
    const eventsSortByTimeAndService = Object.keys(finalResult).reduce((eventsObj, resourceId) => {
      eventsObj[resourceId] = sortBy(['start', 'service'])(finalResult[resourceId]);
      return eventsObj;
    }, {});
    return eventsSortByTimeAndService;
  }, {});
};

export const getFormattedSalaryCodeReports = (salaryCodeReports) => Object.keys(salaryCodeReports)
  .reduce((result, employeeId) => {
    result[employeeId] = salaryCodeReports[employeeId].map((report) => ({
      ...camelize(report),
      salaryDetailsData: { ...parseJson(report.salary_details_data)[0], codeType: 3 },
      timeCodeType: 3,
      eventType: calendarEventTypes.timeReport,
      start: new Date(report.added_date),
      keyId: getId(5),
    }));
    return result;
  }, {});

export const getFormattedRequests = (response) => ({
  requests: response.map(getFormattedRequest),
});

const getFormattedPunchTime = (data) => ({
  ...camelize(data),
  start: new Date(data.punch_date),
  eventType: calendarEventTypes.punchTime,
  keyId: getId(5),
});

const getFormattedOrderPunchTime = (data) => ({
  ...data,
  punchId: data.id,
  start: new Date(data.punchInTime),
  bookingStartDate: new Date(data.employeePresenceTime.startTime),
  punchDate: formatDate(new Date(data.punchInTime), formatStrings.filtersDate),
  eventType: calendarEventTypes.punchTime,
  keyId: getId(5),
  order: true,
  punchInLatitude: data.punchInLatitude === '' ? null : data.punchInLatitude,
  punchInLongitude: data.punchInLongitude === '' ? null : data.punchInLongitude,
  punchOutLatitude: data.punchOutLatitude === '' ? null : data.punchOutLatitude,
  punchOutLongitude: data.punchOutLongitude === '' ? null : data.punchOutLongitude,
  punchIn: data.punchInTime ? formatDate(new Date(data.punchInTime.split('+')[0]), formatStrings.defaulTime) : null,
  punchOut: data.punchOutTime ? formatDate(new Date(data.punchOutTime.split('+')[0]), formatStrings.defaulTime) : null,
});

export const getFormattedPunchTimeList = (response) => ({
  // creating unique key
  bookingPunches: keyBy(
    (punch) => `${punch.bookingSequenceNum}-${punch.employeeId}`,
  )(response.map(getFormattedPunchTime)),
});

export const getFormattedOrderPunchTimeList = (response) => ({
  // creating unique key
  orderPunches: keyBy(
    (punch) => `${(punch.employeePresenceTime.order.sequenceNum)}-${punch.employeeId}`,
  )(response.map(getFormattedOrderPunchTime)),
});

export const getFormattedTimeReport = ({ shift_details_data: shiftDetailsData, ...restReport }) => {
  const shiftDetails = JSON.parse(shiftDetailsData)[0];
  const workingTime = getUnixTimeInHoursMinutes(shiftDetails.working_time);
  const startTime = shiftDetails.code_type === shiftTimeTypes.presence
    ? getUnixTimeInHoursMinutes(shiftDetails.start_time)
    : workingTime;
  return {
    code: shiftDetails.code,
    timeCodeName: shiftDetails.code_name,
    timeCodeType: shiftDetails.code_type,
    startTime,
    comment: shiftDetails.comment,
    id: restReport.id,
    adminApproved: restReport.approved,
    endTime: getUnixTimeInHoursMinutes(shiftDetails.end_time),
    workingTime,
    start: parse(`${restReport.added_date} ${startTime}`, parseDateFormat, new Date()),
    eventType: calendarEventTypes.timeReport,
    resourceId: restReport.employee_id,
    keyId: getId(5),
    companyId: restReport.company_id,
  };
};

export const getFormattedTimeReports = (response = {}) => ({
  employeeTimeReports: Object.keys(response).reduce((result, employeeId) => {
    result[employeeId] = response[employeeId].map(getFormattedTimeReport);
    return result;
  }, {}),
});

export const formatBigCalendarBookingResponse = (response = []) => ({
  events: orderBy([(record) => getHourMinutesInUnixTime(record.start_time), 'customer_name'], ['asc', 'asc'])(response)
    .map((record) => ({
      ...camelize(record),
      title: record.service,
      start: parse(`${record.start_date} ${record.start_time}`, parseDateFormat, new Date()),
      end: parse(`${record.start_date} ${record.end_time || record.start_time}`, parseDateFormat, new Date()),
      employeeData: parseJson(record.employee_data),
      allDay: false,
      keyId: getId(5),
    })),
});

export const formatBigCalendarOrderResponse = (response = []) => ({
  events: orderBy([(record) => getHourMinutesInUnixTime(record.startTime), 'customerName'], ['asc', 'asc'])(response)
    .map((record) => ({
      ...record,
      virtual: !record.orderId,
      title: record.serviceName,
      originalDate: record.date,
      start: parse(`${record.date} ${record.startTime}`, parseDateFormat, new Date()),
      end: parse(`${record.date} ${record.endTime || record.startTime}`, parseDateFormat, new Date()),
      allDay: false,
      keyId: getId(5),
      order: true,
      orderEmployees: (record.orderEmployees).map(formatOrderEmployee),
    })),
});

export const getFormattedFieldsForUpdateEventApiRequest = (data, draggedBooking) => {
  const oldEmployeeSeqNum = data.oldEmployeeSeqNum === unassignedId ? '' : data.oldEmployeeSeqNum;
  const bookingStartTime = !oldEmployeeSeqNum && draggedBooking.timeType === bookingTimeTypes.specificTime
    ? draggedBooking.startTime : data.startTime;
  const bookingEndTime = !oldEmployeeSeqNum && draggedBooking.timeType === bookingTimeTypes.specificTime
    ? draggedBooking.endTime : data.endTime;
  const breakTime = oldEmployeeSeqNum ? data.breakTime : 0;
  return {
    ...snakify({
      bookingStartTime,
      bookingEndTime,
      breakTime,
      oldEmployeeSeqNum,
      booking: { startDate: formatDate(data.startDate, 'yyyy-MM-dd') },
      paidTime: (bookingEndTime && bookingStartTime)
        ? getDifferenceBetweenTime(
          getDifferenceBetweenTime(bookingEndTime, bookingStartTime),
          getUnixTimeInHoursMinutes(breakTime),
        ) : null,
    }),
    employeeIds: data.employeeIds ? [data.employeeIds] : null,
  };
};

export const getFormattedFieldsForUpdateOrderEventApiRequest = (data, draggedOrder) => {
  const prevOrderEmployee = data.oldEmployeeSeqNum === unassignedId
    ? null
    : draggedOrder.orderEmployees.find((emp) => (
      emp.sequenceNum === data.oldEmployeeSeqNum || emp.employee?.sequenceNum === data.oldEmployeeSeqNum
    ));

  const oldEmployeeSeqNum = data.oldEmployeeSeqNum === unassignedId ? '' : data.oldEmployeeSeqNum;
  const startTime = prevOrderEmployee && prevOrderEmployee.startTime ? prevOrderEmployee.startTime : null;
  const endTime = prevOrderEmployee && prevOrderEmployee.endTime ? prevOrderEmployee.endTime : null;
  const breakTime = prevOrderEmployee && prevOrderEmployee.breakTime ? prevOrderEmployee.breakTime : '00:00';
  return {
    ...snakify({
      original_date: draggedOrder.date,
      project_id: draggedOrder.projectId ?? undefined,
      startTime: startTime ? startTime.split('+')[0] : null,
      endTime: endTime ? endTime.split('+')[0] : null,
      breakTime,
      oldEmployeeSeqNum,
      order: {
        date: formatDate(data.date, formatStrings.filtersDate),
      },
      paidTime: (endTime && startTime)
        ? getDifferenceBetweenTime(
          getDifferenceBetweenTime(endTime, startTime),
          breakTime,
        ) : null,
    }),
    employeeIds: data.employeeIds ? [data.employeeIds] : null,
  };
};

export const getFormattedCalendarDataApiRequestParams = (filters = {}) => ({
  statuses: isArray(filters.bookingStatus)
    ? filters.bookingStatus.map((status) => status.value)
    : undefined,
  start_date: filters.startDate,
  end_date: filters.endDate,
  customer_id: getFilterCustomerId(filters),
  employee_ids: filters.employees?.map((employee) => employee.id),
  service_ids: filters.services?.map((service) => service.id),
  invoice_type: filters.invoiceStatus,
  tag_id: getFilterTagId(filters),
  active_employees: !filters.showDeactivatedEmployees,
  supervisor_id: getFilterSupervisorId(filters),
  user_group_id: filters.userGroup?.id,
  published: filters.publishStatus === 'view_all' ? undefined : filters.publishStatus,
  department_id: filters.department?.id,
  area_id: filters.area?.id,
  project_id: filters.projectId,
  company_ids: filters.companyIds,
  customer_area: filters.customerArea?.title,
  skill_id: filters.skill?.id,
});

export const getFormattedCalendarRequestDataApiRequestParams = (filters = {}) => ({
  start_date: filters.startDate,
  end_date: filters.endDate,
  service_id: getFilterServiceId(filters),
  company_ids: filters.companyIds,
  skill_id: filters.skill?.id,
});

export const getFormattedCalendarPunchTimeDataApiRequestParams = (filters = {}) => ({
  start_date: filters.startDate,
  end_date: filters.endDate,
  company_ids: filters.companyIds,
});

export const getFormattedBookingBigCalendarFilterParams = (filters = {}) => ({
  start_date: filters.startDate,
  end_date: filters.endDate,
  statuses: isArray(filters.bookingStatus)
    ? filters.bookingStatus.map((status) => status.value)
    : undefined,
  per_page: filters.perPage,
  page: filters.page,
  employee_ids: filters.employees?.map((employee) => employee.id),
  service_id: getFilterServiceId(filters),
  customer_id: getFilterCustomerId(filters),
  booking_id: filters.bookingId,
  invoice_type: filters.invoiceStatus,
  seen_us: filters.seenUs,
  advance_search: filters.bookingId,
  tag_id: getFilterTagId(filters),
  department_id: filters.department?.id,
  area_id: filters.area?.id,
});

export const getFormattedBookingChecklists = (response) => {
  const bookingChecklists = Object.values(response).map((bookingChecklist) => ({
    taskCategory: {
      id: bookingChecklist[0].taskCategoryId,
      title: bookingChecklist[0].taskCategoryTitle,
    },
    tasks: orderBy('orderNumber', 'asc')(bookingChecklist.map((task, idx) => ({
      id: task.taskId,
      title: task.taskName,
      completed: task.completed,
      bookingTaskId: task.id,
      orderNumber: task.orderNumber ? task.orderNumber : idx + 1,
      checklistId: task.checklistId,
      taskCategoryId: task.taskCategoryId,
      connected: task.connected,
    }))),
  }));
  return bookingChecklists;
};

const formatBookingItemsDataAndCalculateCost = (booking) => (booking.bookingItems || [])
  .map(({ unit, ...rest }) => ({
    ...rest,
    itemDiscount: rest.discountValue,
    ...(rest.integrationDetail && { integrationDetail: parseJson(rest.integrationDetail) }),
    unit: unit === 'tim' ? 'h' : unit,
  }))
  .map((item) => ({ ...item, ...getItemCost(item, booking) }));

export const getFormattedEmployeesCopiedBooking = (booking, details) => {
  let bookingEmployees = []; // by default empty when copy booking from employee to unassgined
  if (details.employee.id !== unassignedId && isEmpty(booking.bookingEmployees)) {
    const bookingStartTime = booking.timeType === bookingTimeTypes.specificTime ? booking.startTime : null;
    const bookingEndTime = booking.timeType === bookingTimeTypes.specificTime
      ? (booking.endTime ?? getDefaultEmployeeEndtime(bookingStartTime)) : null;
    const breakTime = (bookingEndTime && bookingStartTime) ? '00:00' : null;
    bookingEmployees.push({
      ...pick(
        ['name', 'isActive', 'colorCode', 'isSupervisor', 'supervisorId', 'keyId', 'supervisorName'],
      )(details.employee),
      breakTime,
      startTime: bookingStartTime,
      endTime: bookingEndTime,
      paidTime: (bookingEndTime && bookingStartTime)
        ? getDifferenceBetweenTime(getDifferenceBetweenTime(bookingEndTime, bookingStartTime), breakTime)
        : null,
      employeeId: details.employee.id,
      sequenceNum: details.employeeIds,
    });
  } else {
    bookingEmployees = booking.bookingEmployees.map((emp) => (emp.sequenceNum !== details.oldEmployeeSeqNum ? emp : {
      ...emp,
      ...pick(
        ['name', 'isActive', 'colorCode', 'isSupervisor', 'supervisorId', 'keyId', 'supervisorName'],
      )(details.employee),
      breakTime: getTimeFromNumber(details.breakTime),
      paidTime: getTimeFromNumber(details.paidTime),
      employeeId: details.employee.id,
      sequenceNum: details.employeeIds,
    }));
  }

  return {
    ...booking,
    bookingEmployees,
    bookingPriceAttributes: getItemsTotalCosts(formatBookingItemsDataAndCalculateCost(booking), booking),
    totalHours: booking.totalHours ? getUnixTimeInHoursMinutes(booking.totalHours) : null,
    startDate: getStandardDate(details.startDate),
  };
};

// Used to format copy booking data
export const getCopyBookingData = ({ booking }) => {
  let bookingItems = formatBookingItemsDataAndCalculateCost(booking);
  bookingItems = bookingItems.map((d) => omit(['id', 'bookingId', 'createdAt', 'updatedAt'])(d));

  return {
    ...omit([
      'sequenceNum',
      'id',
      'createdAt',
      'updatedAt',
      'bookingPrice',
      'bookingsEmployees',
      'fortnoxId',
      'vismaId',
      'invoiceId',
      'statusUpdateById',
      'statusUpdateByName',
      'vismaInvoiceNumber',
      'vismaStatus',
      'deletePending',
      'errorIntegration',
      'fortnoxStatus',
      'lastRecurringInstance',
      'punches',
      'updateInProgress',
      'invoiceInprogress',
      'isRecurringInstance',
      'itemsCount',
      'parentBookingId',
      'isScheduled',
      'scheduledMessage',
      'endDate',
      'startDate',
    ])(booking),
    ...(Boolean(booking.isRecurringInstance) && { endDate: getStandardDate(addYears(new Date(), 1)) }),
    startDate: getStandardDate(),
    status: bookingStatus.active,
    notes: getStringForJsonBookingNotes(parseJson(booking.notes, booking.notes)),
    smsScheduled: Boolean(booking.smsScheduled),
    budgetTime: getTimeFromNumber(booking.budgetTime),
    commented: Boolean(booking.commented),
    isRecurring: booking.isRecurringInstance,
    repeatDays: booking.isRecurringInstance ? booking.repeatDays : null,
    repeatInterval: booking.isRecurringInstance ? booking.repeatInterval : null,
    repeatType: booking.isRecurringInstance ? booking.repeatType : null,
    monthRepeat: booking.isRecurringInstance ? booking.monthRepeat : null,
    bookingPriceAttributes: getItemsTotalCosts(bookingItems, booking),
    totalEmployeeWorkTime: getUnixTimeInHoursMinutes((booking.bookingsEmployees || []).reduce((tot, emp) => {
      tot += getHourMinutesInUnixTime(emp.paidTime);
      return tot;
    }, 0)),
    bookingAddresses: (booking.bookingAddresses || []).map(
      (bookingAddress) => omit(['id', 'createdAt', 'updatedAt', 'bookingId'])(bookingAddress),
    ),
    bookingItems,
    bookingEmployees: (booking.bookingsEmployees || []).map((employee) => ({
      ...omit(['id', 'bookingEmployeesId', 'timeRequestStatus'])(employee),
      breakTime: getTimeFromNumber(employee.breakTime),
      invoiceTime: epochTimeToFloat(employee.invoiceTime),
      overtimes: [],
    })),
    bookingTags: booking.bookingTags.map((tag) => ({ tagId: tag.tagId, title: tag.tagTitle })),
    bookingChecklists: getFormattedBookingChecklists(booking.bookingChecklists),
  };
};

export const getFormattedWorkingHours = (records = []) => ({
  records: records.map((time) => ({
    ...time,
    activeBookingHours: getUnixTimeInHoursMinutes(time.activeBookingsTime),
    completedBookingHours: getUnixTimeInHoursMinutes(time.completedBookingsTime),
    totalHours: getUnixTimeInHoursMinutes(time.activeBookingsTime + time.completedBookingsTime),
  })),
  totalWorkingHours: getUnixTimeInHoursMinutes(records.reduce(
    (prev, current) => prev + current.activeBookingsTime + current.completedBookingsTime, 0,
  )),
});

export const getFormattedEmployeesCopiedOrder = (order, details) => {
  let orderEmployees = []; // by default empty when copy booking from employee to unassgined
  if (details.employee.id !== unassignedId && isEmpty(order.orderEmployees)) {
    const orderStartTime = order.timeType === projectTimeTypesString.specificTime ? order.startTime : null;
    const orderEndTime = order.timeType === projectTimeTypesString.specificTime
      ? (order.endTime ?? getDefaultEmployeeEndtime(orderStartTime)) : null;
    const breakTime = (orderEndTime && orderStartTime) ? '00:00' : null;
    orderEmployees.push({
      ...pick(
        ['name', 'isActive', 'colorCode', 'isSupervisor', 'supervisorId', 'keyId', 'supervisorName'],
      )(details.employee),
      breakTime,
      startTime: orderStartTime,
      endTime: orderEndTime,
      paidTime: (orderEndTime && orderStartTime)
        ? getDifferenceBetweenTime(getDifferenceBetweenTime(orderEndTime, orderStartTime), breakTime)
        : null,
      employeeId: details.employee.id,
      sequenceNum: details.employeeIds,
    });
  } else if (details.employee.id === unassignedId) {
    orderEmployees = [];
  } else {
    orderEmployees = order.orderEmployees.map((emp) => (emp.sequenceNum !== details.oldEmployeeSeqNum ? emp : {
      ...emp,
      ...pick(
        ['name', 'isActive', 'colorCode', 'isSupervisor', 'supervisorId', 'keyId', 'supervisorName'],
      )(details.employee),
      breakTime: getTimeFromNumber(details.breakTime),
      paidTime: getTimeFromNumber(details.paidTime),
      employeeId: details.employee.id,
      sequenceNum: details.employeeIds,
    }));
  }

  return {
    ...order,
    orderEmployees,
    orderPriceAttributes: getItemsTotalCosts(formatBookingItemsDataAndCalculateCost(order), order),
    totalHours: order.totalHours ? order.totalHours : null,
    date: getStandardDate(details.date),
  };
};

export const getFormattedSmartScheduleDataApiRequestParams = (filters = {}) => ({
  start_date: filters.startDate,
  end_date: filters.endDate,
  customer_id: getFilterCustomerId(filters),
  employee_id: filters.employeeId,
  service_ids: filters.services?.map((service) => service.id),
  service_id: filters.serviceId,
  invoice_type: filters.invoiceStatus,
  tag_id: getFilterTagId(filters),
  active_employees: !filters.showDeactivatedEmployees,
  supervisor_id: getFilterSupervisorId(filters),
  user_group_id: filters.userGroup?.id,
  published: filters.publishStatus === 'view_all' ? undefined : filters.publishStatus,
  department_id: filters.department?.id,
  area: filters.area?.title,
  per_page: filters.perPage,
  page: filters.page,
  statuses: isArray(filters.bookingStatus)
    ? filters.bookingStatus.map((status) => status.value)
    : undefined,
  booking_id: filters.bookingId,
});

export const getFormattedSmartScheduleEmployeeDataApiRequestParams = (filters = {}) => ({
  start_date: filters.startDate,
  end_date: filters.endDate,
  customer_id: getFilterCustomerId(filters),
  employee_ids: filters.employees?.map((employee) => employee.id),
  service_ids: filters.services?.map((service) => service.id),
  service_id: filters.serviceId,
  invoice_type: filters.invoiceStatus,
  tag_id: getFilterTagId(filters),
  active_employees: !filters.showDeactivatedEmployees,
  supervisor_id: getFilterSupervisorId(filters),
  user_group_id: filters.userGroup?.id,
  published: filters.publishStatus === 'view_all' ? undefined : filters.publishStatus,
  department_id: filters.department?.id,
  area: filters.area?.title,
  statuses: isArray(filters.bookingStatus)
    ? filters.bookingStatus.map((status) => status.value)
    : undefined,
  booking_id: filters.bookingId,
  query: filters.query,
});

export const getFormattedSmartScheduleBookingsResponse = (response = {}) => ({
  ...getFormattedPaginationFields(response),
  records: response.records.map((record) => ({
    ...record,
    start: parse(`${record.startDate} ${record.bookingStartTime}`, parseDateFormat, new Date()),
    end: record.endDate && parse(`${record.endDate} ${record.bookingEndTime}`, parseDateFormat, new Date()),
    eventType: calendarEventTypes.booking,
  })),
});

export const getFormattedEmployeesData = (response) => response.map((record) => ({
  ...record,
  totalPaidTime: Number(record.totalPaidTime),
}));

export const getFormattedBookingsWithoutEmployeesData = (response) => response.records.map((record) => ({
  ...record,
  start: parse(`${record.startDate} ${record.startTime}`, parseDateFormat, new Date()),
  end: record.endDate && parse(`${record.endDate} ${record.endTime}`, parseDateFormat, new Date()),
  eventType: calendarEventTypes.booking,
}));

export const getFormattedFieldsForAssignBookingApiRequest = (details, draggedBooking) => ({
  bookings_employee: {
    employee_id: details.id,
    booking_start_time: draggedBooking.bookingStartTime,
    booking_end_time: draggedBooking.bookingEndTime,
    break_time: draggedBooking.breakTime || 0,
    paid_time: getUnixTimeInHoursMinutes(draggedBooking.paidTime),
  },
});

const getFormattedOrderEvent = (event, employee = {}, options = {}) => {
  const { resourceIdFieldName = 'employeeId' } = options;
  const startTime = employee.startTime ? formatDate(new Date(employee.startTime.split('+')[0]), formatStrings.defaulTime) : event.startTime || '00:00';
  const endTime = employee.endTime && formatDate(new Date(employee.endTime.split('+')[0]), formatStrings.defaulTime) || event.endTime || startTime || '00:00';

  const employeeStartTime = employee.startTime
    ? formatDate(new Date(employee.startTime.split('+')[0]), formatStrings.defaulTime)
    : '00:00';
  const employeeEndTime = employee.endTime
    ? formatDate(new Date(employee.endTime.split('+')[0]), formatStrings.defaulTime)
    : '00:00';

  const totalEmployeeWorkTime = getUnixTimeInHoursMinutes((event.orderEmployees || []).reduce((tot, emp) => {
    tot += emp.paidTime;
    return tot;
  }, 0));
  const paidTimeData = employeeStartTime && employeeEndTime && employee.breakTime
    ? getDifferenceBetweenTime(getDifferenceBetweenTime(employeeEndTime, employeeStartTime), employee.breakTime)
    : null;

  const notesString = event.orderNote?.notes;

  return {
    ...event,
    customer: {
      address: event.customerAddress,
      id: event.customerId,
      mobile: event.customerMobile,
      name: event.customerName,
      vatIncluded: event.customerVatIncluded,
    },
    employeeStartTime: employee.startTime
      ? parse(`${event.date} ${startTime}`, parseDateFormat, new Date()) : null,
    startTime,
    endTime,
    breakTime: employee.breakTime,
    paidTime: getHourMinutesInUnixTime(paidTimeData),
    notes: notesString ?? '',
    employeeSequenceNum: !isEmpty(employee) && (employee.sequenceNum ?? employee.employee.sequenceNum),
    employeeId: !isEmpty(employee) ? employee.employeeId : undefined,
    id: event.orderId ?? `${event.projectSequenceNum}-${formatDate(event.date, formatStrings.customDate)}`,
    virtual: !event.orderId,
    order: true,
    start: parse(`${event.date} ${startTime}`, parseDateFormat, new Date()),
    end: parse(`${event.date} ${endTime}`, parseDateFormat, new Date()),
    totalEmployeeWorkTime,
    resourceId: event[resourceIdFieldName] || employee?.employeeId || unassignedId,
    movable: !event.invoiceId,
    commented: Boolean(event.commented),
    scheduledMessage: Boolean(event.scheduledMessage),
    invoiceSent: Boolean(event.invoiceId),
    tagsData: parseJson(event.tagsData, []),
    eventType: calendarEventTypes.order,
    keyId: getId(5),
    customerKeys: event.customerKeys.map((key) => key.keyManagement),
    sequenceNum: event.orderSequenceNum
      ?? `${event.projectSequenceNum}-${formatDate(event.date, formatStrings.customDate)}`,
  };
};

export const getFormattedCalendarOrdersForEmployeeTypeResource = (responseOrders) => {
  if (!isArray(responseOrders)) return [];
  const orderUserGroupIdMap = responseOrders.reduce((orderMap, order) => {
    if (!order.orderEmployees.length) orderMap.unassigned.push(getFormattedOrderEvent(order));

    for (const orderEmployee of order.orderEmployees) {
      const formattedOrder = getFormattedOrderEvent(order, orderEmployee);
      if (!orderMap[orderEmployee.employeeId]) orderMap[orderEmployee.employeeId] = [];
      orderMap[orderEmployee.employeeId].push(formattedOrder);
    }
    return orderMap;
  }, { unassigned: [] });

  const events = Object.keys(orderUserGroupIdMap).reduce((eventsObj, employeeSqnNum) => {
    eventsObj[employeeSqnNum || unassignedId] = orderBy(
      [(d) => ({ 1: 1, 2: 3, 3: 2 })[d.timeType], (d) => d.employeeStartTime || d.start, (d) => d.customerName],
      ['asc', 'asc', 'asc'],
    )(orderUserGroupIdMap[employeeSqnNum]);
    return eventsObj;
  }, {});

  return events;
};

export const getFormattedCalendarOrdersForUserGroupTypeResource = (responseOrders) => {
  if (!isArray(responseOrders)) return [];

  const orderEmployeeIdMap = responseOrders.reduce((orderMap, order) => {
    order.userGroupIds = parseJson(order.userGroupIds, []);
    if (!order.orderEmployees.length) orderMap.unassigned.push(getFormattedOrderEvent(order));

    const userGroupIdsArray = Array.isArray(order.userGroupIds)
      ? order.userGroupIds
      : typeof order.userGroupIds === 'string'
      ? order.userGroupIds.slice(1, -1).split("],:[").flatMap(ids => ids.split(","))
        .map(id => parseInt(id, 10))
      : [];

    for (const userGroupId of userGroupIdsArray) {
      const resourceId = `ug-${userGroupId}`;
      const formattedOrder = getFormattedOrderEvent(order);
      if (!orderMap[resourceId]) orderMap[resourceId] = [];
      orderMap[resourceId].push(formattedOrder);
    }

    for (const orderEmployee of order.orderEmployees) {
      const resourceId = `em-${orderEmployee.employeeId}`;
      const formattedOrder = getFormattedOrderEvent(order, orderEmployee);
      if (!orderMap[resourceId]) orderMap[resourceId] = [];
      orderMap[resourceId].push(formattedOrder);
    }

    return orderMap;
  }, { unassigned: [] });

  const events = Object.keys(orderEmployeeIdMap).reduce((eventsObj, resourceId) => {
    eventsObj[resourceId] = sortBy(['start', 'customerName'])(orderEmployeeIdMap[resourceId]);
    return eventsObj;
  }, {});

  return events;
};

export const getFormattedCalendarOrdersForServiceTypeResource = (response) => {
  if (!isArray(response)) return [];
  return response.reduce((finalResult, nextOrder) => {
    const order = getFormattedOrderEvent(nextOrder, undefined, { resourceIdFieldName: 'serviceId' });
    if (!finalResult[order.serviceId]) finalResult[order.serviceId] = [];
    finalResult[order.serviceId].push(order);
    const eventsSortByTimeAndCustomerName = Object.keys(finalResult).reduce((eventsObj, resourceId) => {
      eventsObj[resourceId] = sortBy(['start', 'customerName'])(finalResult[resourceId]);
      return eventsObj;
    }, {});
    return eventsSortByTimeAndCustomerName;
  }, {});
};

export const getFormattedCalendarOrdersForCustomerTypeResource = (response) => {
  if (!isArray(response)) return [];
  return response.reduce((finalResult, nextOrder) => {
    const order = getFormattedOrderEvent(nextOrder, undefined, { resourceIdFieldName: 'customerId' });
    if (!finalResult[order.customerId]) finalResult[order.customerId] = [];
    finalResult[order.customerId].push(order);
    const eventsSortByTimeAndService = Object.keys(finalResult).reduce((eventsObj, resourceId) => {
      eventsObj[resourceId] = sortBy(['start', 'service'])(finalResult[resourceId]);
      return eventsObj;
    }, {});
    return eventsSortByTimeAndService;
  }, {});
};

export const getFormattedSchedulesData = (response = {}, filters) => {
  const getMonthlyTime = (schedule, daysCount) => {
    const dayTimeMapping = [
      schedule.sundayTime,
      schedule.mondayTime,
      schedule.tuesdayTime,
      schedule.wednesdayTime,
      schedule.thursdayTime,
      schedule.fridayTime,
      schedule.saturdayTime,
    ];

    return dayTimeMapping.reduce(
      (sum, time, day) => sum + (daysCount[day] || 0) * time, 0,
    );
  };

  const daysInMonth = eachDayOfInterval(startOfMonth(filters.startDate), endOfMonth(filters.endDate));
  const daysCount = daysInMonth.reduce((dayCounts, date) => {
    const dayOfWeek = date.getDay();
    dayCounts[dayOfWeek] = (dayCounts[dayOfWeek] || 0) + 1;
    return dayCounts;
  }, {});

  return {
    ...getFormattedPaginationFields(response),
    records: (response.records || []).map((schedule) => ({
      ...schedule,
      monday: schedule.mondayTime,
      tuesday: schedule.tuesdayTime,
      wednesday: schedule.wednesdayTime,
      thursday: schedule.thursdayTime,
      friday: schedule.fridayTime,
      saturday: schedule.saturdayTime,
      sunday: schedule.sundayTime,
      monthlyTime: getMonthlyTime(schedule, daysCount),
    })),
  };
};
