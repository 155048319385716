import { createAsyncThunk } from '@reduxjs/toolkit';
import { getStandardDate, convertTimeStringToLocalString } from 'utils/dateTime';
import { setSnackbarError, setSnackbarSuccess } from 'appRedux/actions';
import { getFormattedBodyForPunchInOut, getFormattedProjectFilterParams } from './selector';

export const requestCreateVirtualOrder = async (axios, params) => {
  const response = await axios.post('/api/v3/orders/create_order', params);
  const { order } = response.data.data;
  return order;
};

export const requestProjects = createAsyncThunk(
  'projects/fetchProjects',
  async (customerId, { extra, dispatch, getState }) => {
    const { employee: { projects: { filters } } } = getState();
    try {
      const params = {
        start_date: filters.startDate || getStandardDate(),
        end_date: filters.endDate,
        supervisor_only: true,
        customer_id: customerId,
      };
      const response = await extra.axios.get('/api/v3/employee/projects', { params });
      const { records } = response.data.data.projects;
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_orders_error'));
    }
  },
);

export const requestProject = createAsyncThunk(
  'projects/fetchProject',
  async (projectId, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get(`/api/v3/employee/projects/${projectId}`);
      const { project } = response.data.data;
      return { project };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_orders_error'));
    }
  },
);

export const requestAddOrderTime = createAsyncThunk(
  'projects/addOrderTime',
  async (data, { extra, dispatch }) => {
    try {
      const params = {
        project_id: data.projectId,
        order_date: data.date,
      };
      const order = await requestCreateVirtualOrder(extra.axios, params, dispatch);
      const presenceId = order.orderEmployees.find((emp) => emp.employeeId === data.employeeId).id;
      const body = {
        employee_presence_times: {
          start_time: convertTimeStringToLocalString(data.startTime, data.date),
          end_time: convertTimeStringToLocalString(data.endTime, data.date),
          break_time: data.breakTime,
        },
        comment: data.comment,
      };
      await extra.axios.put(`/api/v3/employee_presence_times/${presenceId}`, body);
      dispatch(requestProject(data.projectId));
      dispatch(setSnackbarSuccess('snackbar_order_time_added'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_orders_error'));
    }
  },
);

export const requestPunchInOnProject = createAsyncThunk(
  'projects/punchInOnProject',
  async (data, { extra, dispatch }) => {
    try {
      const params = {
        project_id: data.projectId,
        order_date: data.date,
      };
      const order = await requestCreateVirtualOrder(extra.axios, params, dispatch);
      const body = getFormattedBodyForPunchInOut({ ...data, orderId: order.id });
      await extra.axios.post('/api/v3/employee/employee_punches', body);
      dispatch(setSnackbarSuccess('snackbar.employeeView.punchedIn'));
      dispatch(requestProject(data.projectId));
    } catch (err) {
      dispatch(setSnackbarError('snackbar.employeeView.punch.error'));
    }
  },
);

export const requestPunchOutOnProject = createAsyncThunk(
  'projects/punchOutOnProject',
  async (punch, { extra, dispatch }) => {
    try {
      const body = getFormattedBodyForPunchInOut(punch);
      await extra.axios.put(`/api/v3/employee/employee_punches/${punch.id}`, body);
      dispatch(setSnackbarSuccess('snackbar.employeeView.punchedOut'));
      dispatch(requestProject(punch.projectId));
    } catch (err) {
      dispatch(setSnackbarError('snackbar.employeeView.punch.error'));
    }
  },
);

export const requestSearchEmployeeProjectCustomers = createAsyncThunk(
  'projects/fetchCustomers',
  async (name, { extra, dispatch }) => {
    try {
      const params = { name };
      const response = await extra.axios.get('/api/v3/employee/projects/search_customer', { params });
      const { records } = response.data.data.customers;
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_orders_error'));
    }
  },
);

export const requestProjectOrders = createAsyncThunk(
  'project/fetchEmployeeProjectOrders',
  async (data, { extra, dispatch }) => {
    try {
      const params = getFormattedProjectFilterParams(data);
      const response = await extra.axios.get(`/api/v3/employee/projects/${data.projectId}/project_orders`, { params });
      const { orders } = response.data.data;
      return { records: orders.records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_project_orders_get_error'));
    }
  },
);
