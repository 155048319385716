import * as Yup from 'yup';
import { fm } from 'utils/string';

const changePasswordFormSchema = Yup.object().shape({
  password: Yup.string()
    .shouldNotContainEmoji()
    .test('password', fm('password_must_be_6_letter'), (password) => password?.length > 5)
    .required('company.password.required'),
  confirmPassword: Yup.string()
    .shouldNotContainEmoji()
    .test(
      'confirmPassword',
      fm('company.password_match.required'),
        function (confirmPassword) { //eslint-disable-line
        return !this.parent.password || confirmPassword === this.parent.password;
      },
    ),
});

export default changePasswordFormSchema;
