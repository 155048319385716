import {
  getFilterEmployeeId,
  getFilterCustomerId,
  getFilterServiceId,
} from 'appRedux/selectors/utility';
import { getFormattedPaginationFields } from 'appRedux/utility';
import { formatDate, formatStrings, getTimeFromNumber } from 'utils/dateTime';
import { userRoles } from 'utils/enum';
import { get, isEmpty } from 'utils/lodash';

export const getFormattedTimeReqParams = (filters) => ({
  start_date: filters.startDate,
  end_date: filters.endDate,
  page: filters.page,
  per_page: filters.perPage,
  code_type: filters.codeType === 'view_all' ? undefined : filters.codeType,
  shift_type: filters.shiftType === 'view_all' ? undefined : filters.shiftType,
  approved: filters.approved === 'view_all' ? undefined : filters.approved,
  status: filters.bookingStatus === 'view_all' ? undefined : filters.bookingStatus,
  employee_id: getFilterEmployeeId(filters) || filters.employeeId,
  customer_id: getFilterCustomerId(filters),
  service_id: getFilterServiceId(filters),
  code_ids: filters.timeCodes?.map((timeCode) => timeCode.id),
  user_active_status: filters.userActiveStatus === 'view_all' ? undefined : filters.userActiveStatus,
  request_for: filters.requestFor,
  punch_in_distance: filters.punchInDistance,
  punch_out_distance: filters.punchOutDistance,
  punch_in_comparator: filters.punchInComparator,
  punch_out_comparator: filters.punchOutComparator,
  employee_punches: filters.employeePunches === 'view_all' ? undefined : filters.employeePunches,
  skill_id: filters.skill?.id,
});

export const getFormattedTimesheetReqParams = (filters) => ({
  start_date: filters.startDate,
  end_date: filters.endDate,
  page: filters.page,
  per_page: filters.perPage,
  customer_id: getFilterCustomerId(filters),
  employee_id: filters.employeeId,
  shift_type: filters.shiftType === 'view_all' ? undefined : filters.shiftType,
  code_type: filters.codeType === 'view_all' ? undefined : filters.codeType,
  approved: filters.approved === 'view_all' ? undefined : filters.approved,
});

export const getFormattedPunchesData = (response) => ({
  ...getFormattedPaginationFields(response),
  records: (response.records || []).map((record) => ({
    ...record,
    id: record.id
      ?? `${record.projectSequenceNum}-${formatDate(record.date, formatStrings.customDate)}-${record.employeeId}`,
    virtual: !record.id,
    uniqueIdentifier: !record.id
      ? `${record.projectSequenceNum}-${formatDate(record.date, formatStrings.customDate)}-${record.employeeId}`
      : undefined,
    virtualId: record.id ?? `${record.projectSequenceNum}-${formatDate(record.date, formatStrings.customDate)}`,
    employeePunch: record.employeePunch && ({
      ...record.employeePunch,
      punchOutTime: formatDate(record.employeePunch.punchOutTime, formatStrings.defaulTime),
      punchInTime: formatDate(record.employeePunch.punchInTime, formatStrings.defaulTime),
      punchInLatitude: Number(record.employeePunch.punchInLatitude),
      punchOutLatitude: Number(record.employeePunch.punchOutLatitude),
      punchInLongitude: Number(record.employeePunch.punchInLongitude),
      punchOutLongitude: Number(record.employeePunch.punchOutLongitude),
    }),
    shiftPunchOutTime: record.shiftPunchOutTime && ({
      ...record.shiftPunchOutTime,
      punchOutTime: getTimeFromNumber(record.shiftPunchOutTime.punchOutTime),
    }),
    shiftPunchInTime: record.shiftPunchInTime && ({
      ...record.shiftPunchInTime,
      punchInTime: getTimeFromNumber(record.shiftPunchInTime.punchInTime),
    }),
    shiftDetails: record.shiftDetails && record.shiftDetails.map((shift) => ({
      ...shift,
      startTime: getTimeFromNumber(shift.startTime),
      endTime: getTimeFromNumber(shift.endTime),
    })),
    employeeStartTime: record.employeeStartTime ? new Date(record.employeeStartTime.split('+')[0]) : null,
    employeeEndTime: record.employeeEndTime ? new Date(record.employeeEndTime.split('+')[0]) : null,
  })),
});

const getOverviewRecordSelectable = (record) => {
  if (record.customerName) {
    return (
      record.employeeStartTime
      && record.employeeEndTime
      && !record.updateInProgress
      && !record.employeePresenceTimeApprovals
    );
  }
  return !record.updateInProgress && !record.shiftTimeApprovals.some(
    (d) => [userRoles.admin, userRoles.owner].includes(d.approvedBy.role),
  );
};

export const getFormattedOverviewResponse = (response) => ({
  ...getFormattedPaginationFields(response),
  records: (response.records || []).map((record) => ({
    ...record,
    employeeStartTime: record.employeeStartTime ? new Date(record.employeeStartTime.split('+')[0]) : null,
    employeeEndTime: record.employeeEndTime ? new Date(record.employeeEndTime.split('+')[0]) : null,
    shiftDetails: get('shiftDetails[0]')(record),
    salaryDetails: get('salaryDetails[0]')(record),
    selectable: Boolean(getOverviewRecordSelectable(record)),
    sequenceNum: record.sequenceNum
      ?? `${record.projectSequenceNum}-${formatDate(record.date, formatStrings.customDate)}`,
    virtualId: record.id
      ? undefined
      : `${record.projectSequenceNum}-${formatDate(record.date, formatStrings.customDate)}-${record.employeeId}`,
    id: record.id,
    virtual: !record.id,
  })),
});

export const getFormattedSalaryEmployeeTimesheetSingleDayRecords = (response) => ({
  ...getFormattedPaginationFields(response),
  records: (response.records || []).map((record) => ({
    ...record,
    employeeStartTime: record.employeeStartTime ? new Date(record.employeeStartTime.split('+')[0]) : null,
    employeeEndTime: record.employeeEndTime ? new Date(record.employeeEndTime.split('+')[0]) : null,
    shiftDetails: record.shiftDetails ? record.shiftDetails[0] : {},
    salaryDetails: record.salaryDetails ? record.salaryDetails[0] : {},
    virtualId: record.id ?? `${record.projectSequenceNum}-${formatDate(record.date, formatStrings.customDate)}`,
    sequenceNum: record.sequenceNum
      ?? `${record.projectSequenceNum}-${formatDate(record.date, formatStrings.customDate)}`,
    id: record.id,
    virtual: !record.id,
    uniqueIdentifier: !record.id
      ? `${record.projectSequenceNum}-${formatDate(record.date, formatStrings.customDate)}-${record.employeeId}`
      : undefined,
  })),
});

export const getFormattedTimesheetResponse = (response) => ({
  ...getFormattedPaginationFields(response),
  records: (response.records || []).map((record) => ({
    ...record,
    date: formatDate(record.date, formatStrings.filtersDate),
    punchTime: getTimeFromNumber(record.punchTime),
    orderTime: getTimeFromNumber(record.orderTime),
    presenceTime: getTimeFromNumber(record.presenceTime),
  })),
});

export const getFormattedEmployeesResponse = (response) => ({
  ...getFormattedPaginationFields(response),
  records: (response.records || []).map((record) => ({
    ...record,
    punchTime: getTimeFromNumber(record.totalOrdersPunchesDuration),
    orderTime: getTimeFromNumber(record.totalOrdersDuration),
  })),
});
