import React from 'react';
import { makeStyles } from 'components/library';

const Image = () => {
  const style = makeStyles({
    parent: {
      position: 'relative',
      marginTop: 'auto',
    },
    dot: {
      height: '280px',
      width: '280px',
      borderRadius: '50%',
      display: 'inline-block',
    },
    dot1: {
      backgroundColor: '#458DE4',
      position: 'absolute',
    },
    dot2: {
      backgroundColor: '#4BD1F8',
      marginLeft: '125px',
      position: 'absolute',
    },
    dot3: {
      backgroundColor: '#FFCA00',
      marginLeft: '250px',
      zIndex: '100',
      position: 'absolute',
      overflow: 'hidden',
    },
  });

  const classes = style();

  return (
    <div className={classes.parent}>
      <span className={`${classes.dot} ${classes.dot1}`} />
      <span className={`${classes.dot} ${classes.dot2}`} />
      <span className={`${classes.dot} ${classes.dot3}`} />
    </div>
  );
};

export default Image;
