import React, { useCallback, useMemo, useEffect, useState } from 'react';
import camelize from 'camelize';
import axios from 'utils/axios';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { parseQueryString } from 'utils/string';
import snakify from 'utils/snakify';
import { Grid, Container } from 'components/library';
import { requestChangePassword } from 'appRedux/thunks/auth';
import ErrorBoundary from 'components/common/ErrorBoundary';
import ChangePasswordForm from 'components/ChangePassword/ChangePasswordForm';
import BosLogo from 'images/Logo-bos.png';
import LinkExpired from './LinkExpired';

export default function ChangePassword (props) {
  const dispatch = useDispatch();
  const [isTokenValid, setIsTokenValid] = useState(true);
  const queryParams = useMemo(
    () => camelize(parseQueryString(props.location.search)),
    [props.location.search],
  );

  useEffect(() => {
    async function validateToken() {
      const { resetPasswordToken } = queryParams;
      if (!resetPasswordToken) return setIsTokenValid(false);
      const response = await axios.post('/users/validate_reset_password_token', snakify({ resetPasswordToken }));
      setIsTokenValid(response.data.data.isTokenValid);
    }
    validateToken();
  }, []);

  const submitForm = useCallback((formData) => {
    const { password, confirmPassword } = formData;
    dispatch(requestChangePassword({ password, confirmPassword, queryParams }, props.history));
  }, [queryParams]);

  return (
    <ErrorBoundary>
      <Container maxWidth="sm">
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <img
                height={48}
                width="auto"
                src={BosLogo}
                alt="Bokning och Schema Online Logo"
              />
            </Grid>
          </Grid>
          {isTokenValid ? <ChangePasswordForm onSubmit={submitForm} /> : <LinkExpired />}
        </Grid>
      </Container>
    </ErrorBoundary>
  );
}

ChangePassword.propTypes = {
  history: PropTypes.shape({}),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};
