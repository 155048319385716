import { combineReducers } from 'redux';
import employeeProfile from 'appRedux/employee/profile';
import employeeBooking from 'appRedux/employee/booking';
import employeeOrders from 'appRedux/employee/orders';
import employeeProjects from 'appRedux/employee/projects';
import employeeRequest from 'appRedux/employee/request';
import employeeTimeManagement from 'appRedux/employee/timeManagement';
import department from 'appRedux/owner/department';
import bookingIssues from 'appRedux/superUser/bookingIssues';
import proposalSignature from 'appRedux/superUser/proposalSignature';
import area from 'appRedux/owner/area';
import proposals from 'appRedux/owner/proposals';
import projects from 'appRedux/owner/projects';
import productUpdates from 'appRedux/owner/productUpdates';
import productModules from 'appRedux/owner/productModules';
import orders from 'appRedux/owner/orders';
import timeManagementNext from 'appRedux/owner/timeManagement';
import overtime from 'appRedux/owner/overtime';
import pushNotification from 'appRedux/owner/pushNotifications';
import bookingProjectMigration from 'appRedux/owner/bookingProjectMigration';
import bookingOrderMigration from 'appRedux/owner/bookingOrderMigration';
import fileManager from 'appRedux/owner/fileManager';
import workCode from 'appRedux/owner/workCode';
import skills from 'appRedux/owner/skills';
import ebokaBookings from 'appRedux/owner/eboka/bookings';
import ebokaIntegration from 'appRedux/owner/eboka/integration';
import companyIntegration from 'appRedux/owner/companyIntegration';
import invoicesNext from 'appRedux/owner/invoices';
import reportsNext from 'appRedux/owner/reports';
import customersNext from 'appRedux/owner/customers';
import auth from './auth';
import bookings from './bookings';
import recurrings from './recurringBookings';
import profile from './profile';
import confirmModal from './confirmModal';
import infoModal from './infoModal';
import customers from './customers';
import userGroups from './userGroups';
import services from './services';
import items from './items';
import notification from './notification';
import reports from './reports';
import invoices from './invoices';
import calendar from './calendar';
import map from './map';
import application from './application';
import superAdmin from './superAdmin';
import requests from './requests';
import employeeView from './employeeView';
import history from './history';
import timeManagement from './timeManagement';
import filters from './filters';
import dashboard from './dashboard';
import snackbar from './snackbar';
import users from './users';
import tags from './tags';
import schedules from './schedules';
import comment from './comment';
import sms from './sms';
import autocomplete from './autocomplete';
import checklists from './checklists';
import keys from './keys';
import color from './color';
import openApi from './openApi';

const reducers = combineReducers({
  area,
  proposals,
  projects,
  orders,
  productUpdates,
  productModules,
  application,
  auth,
  autocomplete,
  bookings,
  calendar,
  checklists,
  color,
  comment,
  confirmModal,
  customers,
  customersNext,
  dashboard,
  department,
  employeeView,
  filters,
  fileManager,
  userGroups,
  timeManagementNext,
  history,
  infoModal,
  invoices,
  invoicesNext,
  items,
  keys,
  map,
  notification,
  overtime,
  profile,
  recurrings,
  reportsNext,
  reports,
  requests,
  schedules,
  services,
  snackbar,
  superAdmin,
  tags,
  timeManagement,
  openApi,
  users,
  sms,
  workCode,
  skills,
  pushNotification,
  companyIntegration,
  bookingProjectMigration,
  bookingOrderMigration,
  eboka: combineReducers({
    integration: ebokaIntegration,
    bookings: ebokaBookings,
  }),
  superUser: combineReducers({
    bookingIssues,
    proposalSignature,
  }),
  employee: combineReducers({
    profile: employeeProfile,
    booking: employeeBooking,
    orders: employeeOrders,
    request: employeeRequest,
    timeManagement: employeeTimeManagement,
    projects: employeeProjects,
  }),
});

export default reducers;
