import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, TextField, Button, Container } from 'components/library';
import { fm } from 'utils/string';
import { useFormik } from 'formik';
import validationSchema from './validationSchema';

export default function ChangePassswordForm (props) {
  const { values, handleChange, errors, ...formik } = useFormik({
    initialValues: { password: '', confirmPassword: '' },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: props.onSubmit,
  });

  return (
    <>
      <Grid item xs={12}>
        <Box align="center">
          {fm('change_new_password_title')}
        </Box>
      </Grid>
      <form onSubmit={formik.handleSubmit}>
        <Container maxWidth="xs">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                size="small"
                name="password"
                id="password"
                label={fm('password')}
                fullWidth
                value={values.password}
                type="password"
                onChange={handleChange}
                error={Boolean(errors.password)}
                helperText={errors.password}
                placeholder={fm('password')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                size="small"
                name="confirmPassword"
                id="confirmPassword"
                label={fm('repeat_password')}
                fullWidth
                value={values.confirmPassword}
                type="password"
                error={Boolean(errors.confirmPassword)}
                helperText={errors.confirmPassword}
                onChange={handleChange}
                placeholder={fm('repeat_password')}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
              >
                {fm('change_password')}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </form>
    </>
  );
}

ChangePassswordForm.propTypes = {
  onSubmit: PropTypes.func,
};
