import React from 'react';
import { Grid, Box, Typography } from 'components/library';
import { fm } from 'utils/string';
import { Link } from 'react-router-dom';
import ExpiredLink from 'images/ExpiredLink.png';

export default function LinkExpiredMessage () {
  return (
    <Grid xs={12}>
      <Grid container direction="column" alignContent="center" alignItems="center" spacing={2}>
        <Grid item>
          <Box mt={6}>
            <img
              alt="expired link"
              src={ExpiredLink}
              height={48}
              width="auto"
            />
          </Box>
        </Grid>
        <Grid item>
          <Typography variant="h5">{fm('link_expired_title')}</Typography>
        </Grid>
        <Grid item>
          <Typography>
            <Box textAlign="center">
              {fm('change_password_link_expired')}
            </Box>
          </Typography>
        </Grid>
        <Grid>
          <Box my={4}>
            <Link to="/forgot-password">
              {fm('generate_reset_password_new_link')}
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
