import React from 'react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFormattedListData } from 'appRedux/utility';
import { setSnackbarError, setFilters, setSnackbarSuccess } from 'appRedux/actions';
import { omit, isEmpty, get } from 'utils/lodash';
import { names } from 'utils/enum';
import snakify from 'utils/snakify';
import FileSaver from 'file-saver';
import { alert } from 'components/library/Alert';
import { fm } from 'utils/string';
import {
  getFormattedTimeReqParams,
  getFormattedPunchesData,
  getFormattedOverviewResponse,
  getFormattedTimesheetReqParams,
  getFormattedTimesheetResponse,
  getFormattedEmployeesResponse,
} from './selector';

export const requestPunchesData = createAsyncThunk(
  'timeManagement/fetchPunchesData',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = getFormattedTimeReqParams(filters);
      const response = await extra.axios.get('/api/v3/time/punches', { params });
      const { records, ...nextFilters } = getFormattedPunchesData(response.data.data);
      dispatch(setFilters({ ...nextFilters, name }));
      return { records };
    } catch (err) {
      return dispatch(setSnackbarError('snackbar_punches_error'));
    }
  },
);

export const requestOverviewRecords = createAsyncThunk(
  'timeManagement/fetchOverviewData',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = getFormattedTimeReqParams(filters);
      const response = await extra.axios.get('/api/v3/time/synopsis', { params });
      const { records, ...nextFilters } = getFormattedOverviewResponse(response.data.data);
      dispatch(setFilters({ ...nextFilters, name }));
      return { records };
    } catch (err) {
      return dispatch(setSnackbarError('snackbar_overview_error'));
    }
  },
);

export const requestSalaryRecords = createAsyncThunk(
  'timeManagement/fetchSalaryData',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = getFormattedTimeReqParams(filters);
      const response = await extra.axios.get('/api/v3/time/employees', { params });
      const { records, ...nextFilters } = getFormattedListData(response.data.data);
      dispatch(setFilters({ ...nextFilters, name }));
      return { records };
    } catch (err) {
      return dispatch(setSnackbarError('snackbar_salary_error'));
    }
  },
);

export const requestEmployeeTimesheetSummary = createAsyncThunk(
  'timeManagement/fetchTimesheetSummary',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const { filters: salaryFilters } = extra.getState(names.timeManagementSalary, getState);
      const params = getFormattedTimesheetReqParams({
        ...salaryFilters,
        page: filters.page,
        perPage: filters.perPage,
        employeeId: filters.employeeId,
      });
      const response = await extra.axios.get('/api/v3/time/employees/timesheet_summary', { params });
      return { summary: response.data.data };
    } catch (err) {
      return dispatch(setSnackbarError('snackbar_salary_error'));
    }
  },
);

export const requestEmployeeTimesheetRecords = createAsyncThunk(
  'timeManagement/fetchTimesheetData',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const { filters: salaryFilters } = extra.getState(names.timeManagementSalary, getState);
      const params = getFormattedTimesheetReqParams({
        ...salaryFilters,
        page: filters.page,
        perPage: filters.perPage,
        employeeId: filters.employeeId,
      });
      const response = await extra.axios.get('/api/v3/time/employees/timesheet', { params });
      const { records, ...nextFilters } = getFormattedTimesheetResponse(response.data.data);
      dispatch(setFilters({ ...nextFilters, name }));
      return { records };
    } catch (err) {
      return dispatch(setSnackbarError('snackbar_salary_error'));
    }
  },
);

export const requestEmployeeCustomerRecords = createAsyncThunk(
  'timeManagement/fetchCustomerData',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const { filters: salaryFilters } = extra.getState(names.timeManagementSalary, getState);
      const params = getFormattedTimesheetReqParams({
        ...salaryFilters,
        page: filters.page,
        perPage: filters.perPage,
        employeeId: filters.employeeId,
      });
      const response = await extra.axios.get('/api/v3/time/employees/customers', { params });
      const { records, ...nextFilters } = getFormattedEmployeesResponse(response.data.data);
      dispatch(setFilters({ ...nextFilters, name }));
      return { records };
    } catch (err) {
      return dispatch(setSnackbarError('snackbar_salary_error'));
    }
  },
);

export const requestEmployeeServicesRecords = createAsyncThunk(
  'timeManagement/fetchServicesData',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const { filters: salaryFilters } = extra.getState(names.timeManagementSalary, getState);
      const params = getFormattedTimesheetReqParams({
        ...salaryFilters,
        page: filters.page,
        perPage: filters.perPage,
        employeeId: filters.employeeId,
      });
      const response = await extra.axios.get('/api/v3/time/employees/services', { params });
      const { records, ...nextFilters } = getFormattedEmployeesResponse(response.data.data);
      dispatch(setFilters({ ...nextFilters, name }));
      return { records };
    } catch (err) {
      return dispatch(setSnackbarError('snackbar_salary_error'));
    }
  },
);

export const requestEmployeeProjectsRecords = createAsyncThunk(
  'timeManagement/fetchProjectData',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const { filters: salaryFilters } = extra.getState(names.timeManagementSalary, getState);
      const params = getFormattedTimesheetReqParams({
        ...salaryFilters,
        page: filters.page,
        perPage: filters.perPage,
        employeeId: filters.employeeId,
      });
      const response = await extra.axios.get('/api/v3/time/employees/projects', { params });
      const { records, ...nextFilters } = getFormattedEmployeesResponse(response.data.data);
      dispatch(setFilters({ ...nextFilters, name }));
      return { records };
    } catch (err) {
      return dispatch(setSnackbarError('snackbar_salary_error'));
    }
  },
);

export const requestSalaryStats = createAsyncThunk(
  'timeManagement/fetchSalaryStats',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = getFormattedTimeReqParams(filters);
      const response = await extra.axios.get('/api/v3/time/employees/stats', { params });
      return { stats: response.data.data };
    } catch (err) {
      return dispatch(setSnackbarError('snackbar_salary_error'));
    }
  },
);

export const requestExportSalaryExcelReport = createAsyncThunk(
  'timeManagement/fetchSalaryExcelReport',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = getFormattedTimeReqParams(filters);
      const response = await extra.axios.get('/api/v3/time/employees/excel', {
        params: omit(['page', 'per_page'])(params),
      });
      if (!response.data.data.xlsxFile) {
        return dispatch(setSnackbarError('snackbar.employee.time.no.record.export'));
      }
      fetch(`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data.data.xlsxFile.content}`)
        .then((res) => res.blob())
        .then((blob) => FileSaver.saveAs(blob, response.data.data.xlsxFile.name));

      if (!isEmpty(response.data.data.invalidEmployeeTimeBookings)) {
        return { invalidEmployeeTimeRecords: response.data.data.invalidEmployeeTimeBookings };
      }
    } catch (err) {
      return dispatch(setSnackbarError('snackbar_salary_export_error'));
    }
  },
);

export const requestExportSalaryPxmlReport = createAsyncThunk(
  'timeManagement/fetchSalaryPxmlReport',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = {
        ...getFormattedTimeReqParams(filters),
        include_schedule: filters.requestFor === 'Fortnox' ? filters.includeSchedule : undefined,
      };
      const response = await extra.axios.get('/api/v3/time/employees/paxml', {
        params: omit(['page', 'per_page'])(params),
      });
      if (!response.data.data.paxmlData) {
        return dispatch(setSnackbarError('snackbar.employee.time.no.record.export'));
      }

      fetch(`data:application/xml;base64,${response.data.data.paxmlData.content}`)
        .then((res) => res.blob())
        .then((blob) => FileSaver.saveAs(blob, response.data.data.paxmlData.name));

      if (!isEmpty(response.data.data.invalidEmployeeTimeBookings)) {
        return { invalidEmployeeTimeRecords: response.data.data.invalidEmployeeTimeBookings };
      }
    } catch (err) {
      const codesMissingVismaCodes = get('response.data.errors[0].details.codes')(err);
      if (codesMissingVismaCodes) {
        alert(
          <div>
            <div>{fm('following_work_codes_missing_visma_codes')}:</div>
            <ul>
              {codesMissingVismaCodes.map(({ code }) => <li key={code}>{code}</li>)}
            </ul>
          </div>,
          { title: fm('visma_error') },
        );
      }
      return dispatch(setSnackbarError('snackbar_salary_export_error'));
    }
  },
);

export const requestApproveEmployeeTime = createAsyncThunk(
  'timeManagement/approveEmployeeTime',
  async (data, { extra, dispatch, rejectWithValue }) => {
    try {
      const params = {
        approve_type: data.approveType,
        shift_times: data.shiftTimes,
        employee_presence_times: data.employeePresenceTimes,
        virtual_orders: snakify(data.virtualOrders),
      };
      await extra.axios.put('/api/v3/time/employees/approve', params);
      dispatch(setSnackbarSuccess('time_approve_in_progress'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_approve_time_error'));
      return rejectWithValue();
    }
  },
);
