import { fm } from 'utils/string';
import { projectPriceTypes } from 'components/Project/ProjectDrawer/utility';
import { getItemCost } from 'utils/getCost';

export const orderDialogTabs = [
  {
    title: fm('order_details', 'Order'),
  },
  {
    title: fm('project_invoicing', 'Invoicing'),
  },
  {
    title: fm('project_checklist', 'Checklist'),
  },
];

export const orderTabsTypes = {
  orderDetails: 0,
  price: 1,
  checklist: 2,
};

export const employeeApproveTimeTypes = {
  punchTime: 'punch_time',
  shiftTime: 'shift_time',
};

export const getOrderItemsWithQuantityCalculated = (order, orderEmployees) => {
  const { priceType, orderItems = [] } = order;

  if (priceType !== projectPriceTypes.pricePerEmployee) return orderItems;
  const employeeInvoiceHourTotal = orderEmployees.reduce(
    (res, acc) => (acc.destroy ? res : res + Number(acc.invoiceTime ?? 0)),
    0,
  ) || 1;

  return orderItems.map((item) => {
    if (!item.perEmployee) return item;
    const updatedItem = { ...item, quantity: employeeInvoiceHourTotal };
    return { ...updatedItem, ...getItemCost(updatedItem, order) };
  });
};

export const submitButtonTypes = {
  saveAndClose: 'saveClose',
  save: 'save',
};

export const orderActionTypes = {
  changeStatus: 'changeStatus',
  sendSms: 'sendSms',
  copyOrder: 'copyOrder',
  sendEmail: 'sendEmail',
  sendMessage: 'sendMessage',
  deleteOrder: 'deleteOrder',
  addTag: 'addTag',
  attachDocument: 'attachDocument',
  updateInvoiceId: 'updateInvoiceId',
};
