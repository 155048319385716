import { SET_SCHEDULES_FIELDS } from '../actions';

const initialState = {
  records: [],
  selectedScheduleIds: [],
};

function schedules (state = initialState, action) {
  switch (action.type) {
    case SET_SCHEDULES_FIELDS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}

export default schedules;
