import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { requestOrders, requestOrder, requestVirtualOrder, requestConversations } from './thunk';

const orders = createSlice({
  name: 'orders',
  initialState: {
    records: {},
    order: {},
    conversations: {
      records: [],
    },
  },
  reducers: {
    setOrderFields: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(requestOrders.fulfilled, (state, action) => ({
      ...state,
      records: action.payload.records,
    }));
    builder.addCase(requestConversations.fulfilled, (state, action) => ({
      ...state,
      conversations: {
        records: action.payload.records,
      },
    }));
    builder.addMatcher(
      isAnyOf(requestOrder.fulfilled, requestVirtualOrder.fulfilled),
      (state, action) => ({
        ...state,
        order: action.payload,
      }),
    );
  },
});

const { reducer, actions } = orders;

export const { setOrderFields } = actions;

export default reducer;
