import {
  SET_DASHBOARD_FIELDS,
  SET_WEATHER_DATA,
  SET_THINGS_TO_DO_DATA,
  SET_EMPLOYEES_ON_LEAVE,
  SET_SHOW_CHAT_POPOVER,
  SET_EMPLOYEES_DATA,
  SET_NEWS_DATA,
} from '../actions';

const initialState = {
  weatherDetails: {
    weatherTemperature: '',
    weatherDetail: '',
    weatherIcon: '',
  },
  thingsToDoData: {},
  employeesOnLeave: {},
  showChatPopover: false,
  employeesData: [],
  newsData: [],
};

function dashboard (state = initialState, action) {
  switch (action.type) {
    case SET_DASHBOARD_FIELDS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_WEATHER_DATA:
      return {
        ...state,
        weatherDetails: {
          weatherTemperature: action.weatherTemperature,
          weatherDetail: action.weatherDetail,
          weatherIcon: action.weatherIcon,
        },
      };
    case SET_THINGS_TO_DO_DATA:
      return {
        ...state,
        thingsToDoData: action.payload,
      };
    case SET_EMPLOYEES_ON_LEAVE:
      return {
        ...state,
        employeesOnLeave: action.payload,
      };
    case SET_SHOW_CHAT_POPOVER:
      return {
        ...state,
        showChatPopover: action.payload,
      };
    case SET_EMPLOYEES_DATA:
      return {
        ...state,
        employeesData: action.payload,
      };
    case SET_NEWS_DATA:
      return {
        ...state,
        newsData: action.payload,
      };
    default:
      return state;
  }
}

export default dashboard;
