import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Hidden (props) {
  const {
    children,
    only,
    lgDown,
    lgUp,
    mdDown,
    mdUp,
    smDown,
    smUp,
    xlDown,
    xlUp,
    xsDown,
    xsUp,
  } = props;
  const theme = useTheme();

  const breakpointsMap = {
    only: only && theme.breakpoints.only(only),
    lgDown: lgDown && theme.breakpoints.down('lg'),
    lgUp: lgUp && theme.breakpoints.up('lg'),
    mdDown: mdDown && theme.breakpoints.down('md'),
    mdUp: mdUp && theme.breakpoints.up('md'),
    smDown: smDown && theme.breakpoints.down('sm'),
    smUp: smUp && theme.breakpoints.up('sm'),
    xlDown: xlDown && theme.breakpoints.down('xl'),
    xlUp: xlUp && theme.breakpoints.up('xl'),
    xsDown: xsDown && theme.breakpoints.down('xs'),
    xsUp: xsUp && theme.breakpoints.up('xs'),
  };

  const isHidden = Object.values(breakpointsMap).some((breakpoint) => breakpoint && useMediaQuery(breakpoint));

  if (isHidden) {
    return null;
  }

  return children;
}

Hidden.propTypes = {
  children: PropTypes.node,
  only: PropTypes.arrayOf(PropTypes.string),
  lgDown: PropTypes.bool,
  lgUp: PropTypes.bool,
  mdDown: PropTypes.bool,
  mdUp: PropTypes.bool,
  smDown: PropTypes.bool,
  smUp: PropTypes.bool,
  xlDown: PropTypes.bool,
  xlUp: PropTypes.bool,
  xsDown: PropTypes.bool,
  xsUp: PropTypes.bool,
};
