import React from 'react';
import { styled } from '@mui/material/styles';
import { colors } from 'utils/colors';
import MuiIconButton from '@mui/material/IconButton';

const StyledIconButton = styled(MuiIconButton)(
  ({
    theme,
    variant,
    borderColor,
    borderRadius,
    padding,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    backgroundColor,
  }) => ({
    ...(variant === 'bordered' && { border: '1px solid' }),
    ...(borderColor && { borderColor }),
    ...(backgroundColor && {
      backgroundColor,
      '&:hover': {
        backgroundColor: colors.blue,
      },
    }),
    ...(borderRadius && { borderRadius: theme.spacing(borderRadius) }),
    ...(padding && { padding: theme.spacing(padding) }),
    ...(paddingLeft && { paddingLeft: theme.spacing(paddingLeft) }),
    ...(paddingRight && { paddingRight: theme.spacing(paddingRight) }),
    ...(paddingTop && { paddingTop: theme.spacing(paddingTop) }),
    ...(paddingBottom && { paddingBottom: theme.spacing(paddingBottom) }),
  }),
);

export default function IconButton(props) {
  return <StyledIconButton {...props} />;
}
