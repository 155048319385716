import { fm } from '../string';
import { invert } from '../lodash';

export const userPermissionTypeFieldNames = {
  100: 'canEditTime',
  101: 'canViewCustomerName',
  102: 'canViewCustomerMobile',
  103: 'canViewCustomerPhone',
  104: 'canViewKey',
  105: 'canViewDoorCode',
  106: 'canViewAddress',
  107: 'canPunchOnBooking',
  108: 'canCompleteBooking',
  109: 'canViewBreakTime',
  110: 'canEditBreakTime',
  112: 'canUploadDocument',
  113: 'canViewDocument',
  114: 'canEmployeeViewCustomerKeys',
  115: 'canEmployeeViewPersonalDetails',
  116: 'canEmployeeUpdatePersonalDetails',
  117: 'canEmployeeViewBankDetails',
  118: 'canEmployeeUpdateBankDetails',
  119: 'canEmployeeViewBookingNotes',
  120: 'autoCalculateOvertime',
  121: 'canEmployeeViewOtherEmployeeTime',
  122: 'canEmployeeWritePresenceTimeRecord',
  123: 'canEmployeeWriteAbsenceTimeRecord',
  124: 'canEmployeeWriteOtherSalaryRecord',
  200: 'canCustomerViewInvoices',
  201: 'canCustomerViewProfile',
  202: 'canCustomerViewBookingTime',
  300: 'canAdminViewInvoicesPage',
  301: 'canAdminViewTimeManagementPage',
  302: 'canAdminViewUsersPage',
  303: 'canAdminUpdateSettingsPageDetails',
  304: 'canAdminViewDashboardPage',
  305: 'canAdminViewReportPage',
  306: 'canAdminViewCustomerPage',
  307: 'canAdminViewItemPage',
  308: 'canAdminViewServicePage',
  309: 'canAdminViewRequestPage',
  310: 'canAdminViewSchedulePage',
  311: 'canAdminViewChecklistPage',
  312: 'canAdminCreateBooking',
  313: 'canAdminUpdateBooking',
  314: 'canAdminCompleteBooking',
  315: 'canAdminCancelBooking',
  316: 'canAdminReactivateBooking',
  317: 'canAdminSendBookingEmail',
  318: 'canAdminSendBookingSms',
  319: 'canAdminViewBookingPrice',
  320: 'canAdminUpdateBookingPrice',
  321: 'canAdminViewBookingChecklist',
  322: 'canAdminUpdateBookingChecklist',
  323: 'canAdminViewSettingsPage',
  324: 'canAdminUploadBookingAttachment',
  325: 'canAdminViewBookingAttachment',
  326: 'canAdminViewKeyManagementPage',
  327: 'canAdminViewOvertimePage',
  328: 'canAdminUpdateOvertimeSetting',
  329: 'canAdminActivateDeactivateOvertime',
};

export const userPermissionTypes = invert(userPermissionTypeFieldNames);

export const permissionNames = {
  100: fm('employee_modal_can_edit_time', 'Allow employee to update own booking time'),
  101: fm('employee_modal_can_view_customerName', 'Allow employee to view customer name'),
  102: fm('employee_modal_can_view_mobile', 'Allow employee to view customer mobile'),
  103: fm('employee_modal_can_view_phone', 'Allow employee to view customer phone'),
  104: fm('employee_modal_can_view_key', 'Allow employee to view View key'),
  105: fm('employee_modal_can_view_door_code', 'Allow employee to view door code'),
  106: fm('employee_modal_can_view_address', 'Allow employee to view address'),
  107: fm('employee_modal_can_punch_on_booking', 'Allow employee to punch on booking'),
  108: fm('employee_modal_can_complete_booking', 'Allow employee to complete booking'),
  109: fm('employee_modal_can_view_break_time'),
  110: fm('employee_modal_can_edit_break_time'),
  111: fm('employee_modal_can_view_time_management'),
  112: fm('employee_modal_can_upload_document'),
  113: fm('employee_modal_can_view_document'),
  114: fm('employee_can_view_customer_keys'),
  115: fm('employee_can_view_personal_details'),
  116: fm('employee_can_update_personal_details'),
  117: fm('employee_can_view_bank_details'),
  118: fm('employee_can_update_bank_details'),
  119: fm('employee_can_view_booking_notes'),
  120: fm('autocalculate_overtime'),
  121: fm('employee_can_view_other_employee_time'),
  122: fm('employee_can_write_presence_time_record'),
  123: fm('employee_can_read_absence_time_record'),
  124: fm('employee_can_write_other_salary_record'),
  200: fm('customer_can_view_invoices'),
  201: fm('customer_can_view_profile'),
  202: fm('customer_can_view_booking_time'),
  300: fm('admin_can_view_invoices_page'),
  301: fm('admin_can_view_time_management_page'),
  302: fm('admin_can_view_users_page'),
  303: fm('admin_can_update_settings_page_details'),
  304: fm('admin_can_view_dashboard_page'),
  305: fm('admin_can_view_report_page'),
  306: fm('admin_can_view_customer_page'),
  307: fm('admin_can_view_item_page'),
  308: fm('admin_can_view_service_page'),
  309: fm('admin_can_view_request_page'),
  310: fm('admin_can_view_schedule_page'),
  311: fm('admin_can_view_checklist_page'),
  312: fm('admin_can_create_booking'),
  313: fm('admin_can_update_booking'),
  314: fm('admin_can_complete_booking'),
  315: fm('admin_can_cancel_booking'),
  316: fm('admin_can_reactivate_booking'),
  317: fm('admin_can_send_booking_email'),
  318: fm('admin_can_send_booking_sms'),
  319: fm('admin_can_view_booking_price'),
  320: fm('admin_can_update_booking_price'),
  321: fm('admin_can_view_booking_checklist'),
  322: fm('admin_can_update_booking_checklist'),
  323: fm('admin_can_view_settings_page'),
  324: fm('admin_can_upload_booking_attachment'),
  325: fm('can_admin_view_booking_attachment'),
  326: fm('can_admin_view_key_management_page'),
  327: fm('can_admin_view_overtime_page'),
  328: fm('can_admin_update_overtime_setting'),
  329: fm('can_admin_activate_deactivate_overtime'),
};
