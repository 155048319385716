import { createSlice } from '@reduxjs/toolkit';
import {
  requestEmployeeDetailedReports,
  requestEmployeeReports,
  requestReportsStats,
  requestCustomerReports,
  requestCustomerDetailedReports,
  requestEmployeeSchedules,
  requestServiceReports,
  requestServiceDetailedReports,
} from './thunk';

const reportsNext = createSlice({
  name: 'reports',
  initialState: {
    employee: {
      records: [],
      detailedRecords: {},
      subtablePaginations: {},
    },
    customer: {
      records: [],
      detailedRecords: {},
      subtablePaginations: {},
    },
    service: {
      records: [],
      detailedRecords: {},
      subtablePaginations: {},
    },
    stats: {},
    scheduleRecords: {},
  },
  reducers: {
    setReportsOrderFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setReportsEmployeeFields (state, action) {
      return {
        ...state,
        migratedHistory: {
          ...state.migratedHistory,
          ...action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestReportsStats.fulfilled, (state, action) => ({
      ...state,
      stats: action.payload,
    }));
    builder.addCase(requestEmployeeReports.fulfilled, (state, action) => ({
      ...state,
      employee: {
        ...state.employee,
        records: action.payload.records,
      },
    }));
    builder.addCase(requestEmployeeDetailedReports.fulfilled, (state, action) => ({
      ...state,
      employee: {
        ...state.employee,
        detailedRecords: {
          ...state.employee.detailedRecords,
          [action.payload.employeeId]: action.payload.records,
        },
        subtablePaginations: {
          ...state.employee.subtablePaginations,
          [action.payload.employeeId]: action.payload.subtablePagination,
        },
      },
    }));
    builder.addCase(requestCustomerReports.fulfilled, (state, action) => ({
      ...state,
      customer: {
        ...state.customer,
        records: action.payload.records,
      },
    }));
    builder.addCase(requestCustomerDetailedReports.fulfilled, (state, action) => ({
      ...state,
      customer: {
        ...state.customer,
        detailedRecords: {
          ...state.customer.detailedRecords,
          [action.payload.customerId]: action.payload.records,
        },
        subtablePaginations: {
          ...state.customer.subtablePaginations,
          [action.payload.customerId]: action.payload.subtablePagination,
        },
      },
    }));
    builder.addCase(requestServiceReports.fulfilled, (state, action) => ({
      ...state,
      service: {
        ...state.service,
        records: action.payload.records,
      },
    }));
    builder.addCase(requestServiceDetailedReports.fulfilled, (state, action) => ({
      ...state,
      service: {
        ...state.service,
        detailedRecords: {
          ...state.service.detailedRecords,
          [action.payload.serviceId]: action.payload.records,
        },
        subtablePaginations: {
          ...state.service.subtablePaginations,
          [action.payload.serviceId]: action.payload.subtablePagination,
        },
      },
    }));
    builder.addCase(requestEmployeeSchedules.fulfilled, (state, action) => ({
      ...state,
      scheduleRecords: action.payload.scheduleRecords,
    }));
  },
});

const { reducer, actions } = reportsNext;

export const { setReportsOrderFields, setReportsEmployeeFields } = actions;

export default reducer;
