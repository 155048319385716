import { createAsyncThunk } from '@reduxjs/toolkit';
import { setFilters, setSnackbarError } from 'appRedux/actions';
import { getFormattedPaginationFields } from 'appRedux/utility';
import { pick } from 'utils/lodash';

export const requestEbokaBookings = createAsyncThunk(
  'eboka/fetchBookings',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = pick(['page', 'perPage', 'startDate', 'endDate'])(filters);
      const response = await extra.axios.get('/api/v3/eboka/bookings', { params });
      dispatch(setFilters({ name, ...getFormattedPaginationFields(response.data.data) }));
      return { records: response.data.data.records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_bookings_list_failed'));
    }
  },
);

export const requestEbokaBooking = createAsyncThunk(
  'eboka/fetchBooking',
  async (bookingId, { extra, dispatch, rejectWithValue }) => {
    try {
      const response = await extra.axios.get(`/api/v3/eboka/bookings/${bookingId}`);
      return { record: response.data.data };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_booking_get_failed'));
      return rejectWithValue(err.response.data);
    }
  },
);
