import { createAsyncThunk } from '@reduxjs/toolkit';
import { setSnackbarError, setSnackbarSuccess } from 'appRedux/actions';
import { get } from 'utils/lodash';

export const requestEbokaIntegrationStatus = createAsyncThunk(
  'eboka/fetchIntegration',
  async (_, { extra, dispatch, rejectWithValue }) => {
    try {
      const response = await extra.axios.get('/api/v3/eboka/integration');
      return {
        status: response.data.data.integrated,
        serviceProvider: response.data.data.serviceProvider,
      };
    } catch (err) {
      if (get('response.data.tokenExpired')(err)) {
        dispatch(setSnackbarError('snackbar_eboka_integration_token_expired'));
      } else {
        dispatch(setSnackbarError('snackbar_eboka_integration_status_error'));
      }
      return rejectWithValue(err.response.data);
    }
  },
);

export const requestCreateEbokaIntegration = createAsyncThunk(
  'eboka/createIntegration',
  async (details, { extra, dispatch, rejectWithValue }) => {
    try {
      const body = { eboka_integration: details };
      await extra.axios.post('/api/v3/eboka/integration', body);
      dispatch(setSnackbarSuccess('snackbar_eboka_integrated'));
      setTimeout(() => {
        window.location.replace('/');
      }, 500);
      return { status: true };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_eboka_integration_failed'));
      return rejectWithValue(err.response.data);
    }
  },
);

export const requestDeleteEbokaIntegration = createAsyncThunk(
  'eboka/deleteIntegration',
  async (details, { extra, dispatch, rejectWithValue }) => {
    try {
      const body = { eboka_integration: details };
      await extra.axios.delete('/api/v3/eboka/integration', body);
      dispatch(setSnackbarSuccess('snackbar_eboka_integration_removed'));
      return { status: false };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_booking_get_failed'));
      return rejectWithValue(err.response.data);
    }
  },
);
