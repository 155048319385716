import { fm } from 'utils/string';
import { getItemCost } from 'utils/getCost';
import {
  timeStringToFloat,
  getUnixTimeInHoursMinutes,
  getDifferenceBetweenTime,
  getHourMinutesInUnixTime,
  epochTimeToFloat,
} from 'utils/dateTime';

export const projectDialogTabs = [
  {
    title: fm('project_details', 'Project details'),
  },
  {
    title: fm('project_invoicing', 'Invoicing'),
  },
  {
    title: fm('project_checklist', 'Checklist'),
  },
];

export const projectRepeatType = { week: 'week', month: 'month' };

export const projectTabsTypes = {
  projectDetails: 0,
  price: 1,
  checklist: 2,
};

export const projectTimeTypesString = {
  specificTime: 'specific_time',
  totalHours: 'total_hours',
  flexTime: 'flex_time',
};

export const projectOrderTabsTypes = {
  allOrders: 0,
  updated: 1,
};

export const projectPriceTypes = {
  pricePerItem: 'price_per_item',
  pricePerEmployee: 'price_per_employee',
  fixed: 'fixed',
};

export const projectHouseworkTypes = {
  none: 'None',
  rut: 'Rut',
  rot: 'Rot',
};

export const projectOrderTabs = [
  {
    title: fm('all_orders'),
  },
  {
    title: fm('updated'),
  },
];

export const priceTypeOptions = [
  {
    label: fm('project_price_per_item', 'Price Per Item'),
    value: projectPriceTypes.pricePerItem,
  },
  {
    label: fm('project_price_per_employee', 'Hourly price per employee'),
    value: projectPriceTypes.pricePerEmployee,
  },
];

export const projectPriceTypeOptions = [
  {
    label: fm('project_price_per_item', 'Price Per Item'),
    value: projectPriceTypes.pricePerItem,
  },
  {
    label: fm('project_price_per_employee', 'Hourly price per employee'),
    value: projectPriceTypes.pricePerEmployee,
  },
  {
    label: fm('project_fixed', 'Fixed'),
    value: projectPriceTypes.fixed,
  },
];

export const projectHouseworkTypeOptions = [
  {
    label: fm('no_housework'),
    value: projectHouseworkTypes.none,
  },
  {
    label: fm('rut'),
    value: projectHouseworkTypes.rut,
  },
  {
    label: fm('rot'),
    value: projectHouseworkTypes.rot,
  },
];

export const getInvoiceTime = (startTime, endTime, breakTime) => {
  if (!endTime || !startTime) return 0;
  return timeStringToFloat(
    getDifferenceBetweenTime(
      getDifferenceBetweenTime(endTime, startTime), (breakTime || '00:00'),
    ),
  );
};

export const getProjectItemsWithQuantityCalculated = (project, projectEmployees) => {
  const { priceType, projectItems = [] } = project;

  if (priceType !== projectPriceTypes.pricePerEmployee) return projectItems;
  const employeeInvoiceHourTotal = projectEmployees.reduce(
    (res, acc) => (acc.destroy ? res : res + Number(acc.invoiceTime ?? 0)),
    0,
  ) || 1;

  return projectItems.map((item) => {
    if (!item.perEmployee) return item;
    const updatedItem = { ...item, quantity: employeeInvoiceHourTotal };
    return { ...updatedItem, ...getItemCost(updatedItem, project) };
  });
};

export const getDefaultEmployeeEndtime = (startTime) => {
  const startTimeEpoch = getHourMinutesInUnixTime(startTime);
  const fourHoursEpoch = getHourMinutesInUnixTime('04:00');
  const lastPossibleEndTimeEpoch = getHourMinutesInUnixTime('23:59');
  const endTime = startTimeEpoch + fourHoursEpoch;
  return getUnixTimeInHoursMinutes(endTime > lastPossibleEndTimeEpoch
    ? lastPossibleEndTimeEpoch : endTime);
};

export const getEmployeesTotalWorkingTime = (employees) => (
  getUnixTimeInHoursMinutes((employees || []).reduce((tot, emp) => {
    tot += emp.destroy ? 0 : getHourMinutesInUnixTime(emp.paidTime);
    return tot;
  }, 0)) || '00:00'
);

export const getEmployeeFieldsInitializedForProject = (employee, project) => {
  const startTime = project.startTime || '08:00';
  const endTime = project.endTime || getDefaultEmployeeEndtime(startTime);
  const breakTime = '00:00';
  const paidTime = getDifferenceBetweenTime(getDifferenceBetweenTime(endTime, startTime), breakTime);

  return {
    name: employee.name,
    userGroupTitle: employee.userGroupTitle,
    userGroupId: employee.userGroupId,
    colorCode: employee.colorCode,
    employeeId: employee.id || employee.userId,
    sequenceNum: employee.sequenceNum,
    ...(project.timeType === projectTimeTypesString.specificTime && {
      startTime,
      endTime,
      breakTime,
      paidTime,
      invoiceTime: project.priceType === projectPriceTypes.pricePerEmployee
        ? epochTimeToFloat(getHourMinutesInUnixTime(paidTime)) : null,
    }),
    ignoreOvertime: false,
  };
};

export const projectStatus = {
  closed: 'Closed',
  active: 'Active',
};

export const monthRepeatType = {
  day: 'day',
  date: 'date',
};
