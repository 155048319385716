import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Hidden } from 'components/library';
import Image from 'components/common/LoginSignUp/image';
import useStyles from './styles';

export default function RegisterAccount (props) {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Grid
        container
        justifyContent="space-between"
        direction="row-reverse"
        alignContent="center"
        className={classes.content}
      >
        {props.children}
      </Grid>
      <Hidden mdDown>
        <Box className={classes.image}>
          <Image />
        </Box>
      </Hidden>
    </Box>
  );
}

RegisterAccount.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};
