import { createAsyncThunk } from '@reduxjs/toolkit';
import { fm } from 'utils/string';
import bareAxios from 'axios';
import snakify from 'utils/snakify';
import { alert } from 'components/library/Alert';
import { setSnackbarError, setFilters, setSnackbarSuccess, setCustomersFields } from 'appRedux/actions';
import { getFormattedPredefinedChecklist } from 'appRedux/owner/projects/selector';
import { multiStateGet } from 'appRedux/reducers/utility';
import { customerRequestType, names } from 'utils/enum';
import { get, isEmpty } from 'utils/lodash';
import { getFormattedListData } from 'appRedux/utility';
import { requestProjectOrders } from 'appRedux/owner/projects/thunk';
import { requestPunchesData, requestOverviewRecords } from 'appRedux/owner/timeManagement/thunk';
import {
  getFormattedOrderResponse,
  getFormattedOrderRequestBody,
  getFormattedOrderData,
  getCopyOrderData,
  getFormattedOrderFilterParams,
  getFormattedSmsOrEmailRequestBody,
  getFormattedSmsBodyForOrder,
  getFormattedVirtualOrderData,
  getCopyVirtualOrderData,
  formatOrderEmployee,
  getFormattedFieldsToUpdate,
} from './selector';
import { resetOrderState } from './actions';
import { requestUpdateProposalObjectCreated } from '../proposals/thunk';
import { submitButtonTypes } from 'components/Order/utility';

export const requestOrdersStats = createAsyncThunk(
  'order/fetchOrderStats',
  async (name, { extra, dispatch, getState, rejectWithValue }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = getFormattedOrderFilterParams(filters);
      const response = await extra.axios.get('/api/v3/projects/order_stats', { params });
      return {
        orderStats: response.data.data,
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_get_stats_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestOrders = createAsyncThunk(
  'order/fetchOrders',
  async (name, { extra, dispatch, getState, rejectWithValue }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = getFormattedOrderFilterParams(filters);
      const response = await extra.axios.get('/api/v3/orders', { params });
      const { records, ...nextFilters } = getFormattedOrderResponse(response.data.data.orders);
      dispatch(setFilters({ ...nextFilters, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_orders_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestOrder = createAsyncThunk(
  'order/fetchOrder',
  async ({ orderId, companyId }, { extra, rejectWithValue, dispatch, getState }) => {
    try {
      const headers = extra.getHeadersForSubCompany(companyId, getState);
      const response = await extra.axios.get(`/api/v3/orders/${orderId}`, { headers });
      const { order } = response.data.data;
      return {
        order: getFormattedOrderData(order),
        orderDocuments: { records: order.documents || [] },
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_get_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestVirtualOrder = createAsyncThunk(
  'order/fetchVirtualOrder',
  async (data, { extra, dispatch, getState }) => {
    try {
      const headers = extra.getHeadersForSubCompany(data.companyId, getState);
      const params = { date: data.date };
      const response = await extra.axios.get(`/api/v3/projects/${data.projectId}`, { headers, params });
      const { project, projectChecklists, documents } = response.data.data;
      return {
        order: getFormattedVirtualOrderData({
          ...project,
          projectChecklists,
          date: data.date,
        }),
        orderDocuments: { records: documents },
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_get_error'));
    }
  },
);

export const addPredefinedChecklistToOrder = createAsyncThunk(
  'order/addChecklist',
  async (data, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get(
        '/api/v3/checklists/tasks',
        { params: { task_category_id: data.taskCategory.id } },
      );
      const { checklist } = getFormattedPredefinedChecklist(response.data.data);
      const { orderChecklists = [] } = data.order;
      const newOrderChecklists = [...orderChecklists, checklist];
      return { order: { ...data.order, orderChecklists: newOrderChecklists } };
    } catch (err) {
      dispatch(setSnackbarError('snackbarGetPredefinedChecklistTaskError'));
    }
  },
);

export const requestCreateOrder = createAsyncThunk(
  'order/createOrder',
  async (data, { extra, rejectWithValue, dispatch, getState }) => {
    try {
      const { profile } = getState();
      const body = getFormattedOrderRequestBody(data, profile.company);
      const smsOrEmailBody = getFormattedSmsOrEmailRequestBody(data);
      const formData = new FormData();
      formData.append('order', JSON.stringify({ ...body }));
      formData.append('sms_or_email_params', JSON.stringify({ ...smsOrEmailBody }));
      if (data.proposalId) formData.append('proposal_id', data.proposalId);
      if (data.filesToUpload) data.filesToUpload.forEach((file) => formData.append('documents[]', file));
      if (data.orderDocuments) {
        for (const file of data.orderDocuments) {
          const response = await extra.axios.get(file.url);
          const fileData = await bareAxios({ url: response.data.data.url, method: 'GET', responseType: 'blob' });
          formData.append('documents[]', fileData.data);
        }
      }
      if (data.documentIds) formData.append('document_ids', JSON.stringify(data.documentIds));
      const response = await extra.axios.post('/api/v3/orders', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      const { order } = response.data.data;
      dispatch(setSnackbarSuccess('snackbar_order_created'));
      if (data.projectId) {
        dispatch(requestProjectOrders({ id: data.projectId, name: names.projectOrders }));
      }
      if (data.proposalId) {
        dispatch(requestUpdateProposalObjectCreated({ proposalId: data.proposalId }));
      }
      if (data.submitButtonType === submitButtonTypes.save) {
        dispatch(requestOrder({ orderId: order.id }));
      }
      return { ...response.data.data, submitButtonType: data.submitButtonType, orderId: order.id };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_create_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestCheckDuplicateOrders = async (axios, params, dispatch) => {
  const requestBody = {
    params: {
      project_id: params.project_id,
      order_date: params.order_date,
    },
  };

  let shouldCallCreateOrder = true;
  let duplicateOrder = null;

  try {
    const response = await axios.get('/api/v3/orders/check_duplicate_orders', requestBody);
    const { order } = response.data.data;

    if (order) {
      if (await alert(fm('update_on_this_order'))) {
        duplicateOrder = order;
        shouldCallCreateOrder = false;
      }
    }
  } catch (error) {
    if (get('response.data.errors[0].details.code')(error)) {
      const errorCode = get('response.data.errors[0].details.code')(error);
      if (await alert(fm(errorCode))) {
        shouldCallCreateOrder = false;
        dispatch(resetOrderState());
      }
    } else {
      dispatch(setSnackbarError('snackbar_order_update_error'));
    }
  }

  return { shouldCallCreateOrder, duplicateOrder };
};

export const requestCreateVirtualOrder = async (axios, params, dispatch) => {
  const { shouldCallCreateOrder, duplicateOrder } = await requestCheckDuplicateOrders(axios, params, dispatch);

  if (duplicateOrder) {
    return duplicateOrder;
  }

  if (shouldCallCreateOrder) {
    try {
      const response = await axios.post('/api/v3/orders/create_order', params);
      const { order } = response.data.data;
      return order;
    } catch (err) {
      const errorMessage = get('response.data.errors[0].details.error_message[0]')(err);
      if (errorMessage === 'Sequence num has already been taken') {
        if (await alert(fm('update_on_this_order'))) {
          dispatch(resetOrderState());
        }
      }
      throw err;
    }
  }

  return null;
};

export const requestCheckDuplicateOrderOnDateChange = createAsyncThunk(
  'order/checkDuplicateOrder',
  async (data, { extra, dispatch }) => {
    try {
      const params = {
        start_date: data.date,
        end_date: data.date,
      };
      const response = await extra.axios.get(`/api/v3/projects/${data.projectId}/project_orders`, { params });
      const { orders } = response.data.data;
      const virtualOrders = orders.records.filter((order) => !order.id);
      if (!isEmpty(virtualOrders)) {
        if (await alert(fm('order_on_date_already_exist'))) {
          for (const order of virtualOrders) { // eslint-disable-line
            const orderParams = {
              project_id: order.projectId,
              order_date: order.date,
            };
            await requestCreateVirtualOrder(extra.axios, orderParams, dispatch);
          }
        }
      }
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_create_error'));
    }
  },
);

export const requestUpdateOrder = createAsyncThunk(
  'order/updateOrder',
  async (data, { extra, rejectWithValue, dispatch, getState }) => {
    try {
      const { profile, customers } = getState();
      const body = getFormattedOrderRequestBody(data, profile.company);
      const formData = new FormData();
      formData.append('order', JSON.stringify({ ...body }));
      if (data.fieldsToUpdate) {
        formData.append('fields_to_update', JSON.stringify(getFormattedFieldsToUpdate(data.fieldsToUpdate)) ?? {});
      }
      if (data.allActiveOrder) formData.append('all_active_order', data.allActiveOrder);
      if (data.futureActiveOrder) formData.append('future_active_order', data.futureActiveOrder);
      if (data.filesToUpload) {
        for (const file of data.filesToUpload) { // eslint-disable-line
          formData.append('documents[]', file);
        }
      }
      if (data.documentIds) {
        formData.append('document_ids', JSON.stringify(data.documentIds));
      }
      const response = await extra.axios.put(`/api/v3/orders/${data.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(setSnackbarSuccess('snackbar_order_updated'));
      if (data.projectId) {
        dispatch(requestProjectOrders({ id: data.projectId, name: names.projectOrders }));
      }

      // update opened order in customer dialog if virtual order create from customer dialog
      if (customers.openedFromOrder && customers.openedFromOrder.projectId === data.projectId
        && customers.openedFromOrder.date === data.date) {
        dispatch(setCustomersFields({ openedFromOrder: { ...data, virtual: false } }));
      }

      if (data.submitButtonType === submitButtonTypes.save) {
        dispatch(requestOrder({ orderId: data.id }));
      }

      return { ...response.data.data, submitButtonType: data.submitButtonType, orderId: data.id };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_update_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestUpdateOrderEmployeesFromCalendar = createAsyncThunk(
  'order/updateOrderFromCalendar',
  async (data, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get(`/api/v3/orders/${data.id}`);
      const order = getFormattedOrderData(response.data.data.order);
      const existingEmployees = order.orderEmployees || [];
      const newEmployees = data.orderEmployees || [];
      const mergedEmployees = [...existingEmployees, ...newEmployees];
      dispatch(requestUpdateOrder({ ...order, orderEmployees: mergedEmployees }));
      dispatch(setSnackbarSuccess('snackbar_order_updated'));
      return { orderId: data.id, sequenceNum: data.sequenceNum };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_update_error'));
      return { sequenceNum: data.sequenceNum };
    }
  },
);

export const requestCreateVirtualOrderForProject = createAsyncThunk(
  'order/createVirtualOrder',
  async (data, { extra, dispatch, getState }) => {
    const { orders } = getState();
    try {
      const params = {
        project_id: data.projectId,
        order_date: data.originalDate || data.date,
        skip_attribute: data.skipAttribute,
      };

      const response = await requestCreateVirtualOrder(extra.axios, params, dispatch);
      const order = getFormattedOrderData(response);

      // Compare and merge the new employees
      const existingEmployees = data.orderEmployees || [];
      const newEmployees = order.orderEmployees || [];

      const mergedEmployees = existingEmployees.map((existingEmployee) => {
        const matchingNewEmployee = newEmployees.find(
          (newEmployee) => existingEmployee.employeeId === newEmployee.employeeId,
        );

        if (matchingNewEmployee) {
          // If employee ID matches, check if start or end time has changed
          if (
            existingEmployee.startTime !== matchingNewEmployee.startTime
            || existingEmployee.endTime !== matchingNewEmployee.endTime
            || existingEmployee.breakTime !== matchingNewEmployee.breakTime
            || existingEmployee.calculateOvertime !== matchingNewEmployee.calculateOvertime
          ) {
            // If there are changes, create a merged employee object with updated start or end time
            const startTimeChanged = existingEmployee.startTime !== matchingNewEmployee.startTime;
            const endTimeChanged = existingEmployee.endTime !== matchingNewEmployee.endTime;
            const breakTimeChanged = existingEmployee.breakTime !== matchingNewEmployee;
            const overtimeCalculationChanged = existingEmployee.calculateOvertime
              !== matchingNewEmployee.calculateOvertime;

            return {
              ...matchingNewEmployee,
              startTime: startTimeChanged ? existingEmployee.startTime : matchingNewEmployee.startTime,
              endTime: endTimeChanged ? existingEmployee.endTime : matchingNewEmployee.endTime,
              breakTime: breakTimeChanged ? existingEmployee.breakTime : matchingNewEmployee.breakTime,
              calculateOvertime: overtimeCalculationChanged
                ? existingEmployee.calculateOvertime : matchingNewEmployee.calculateOvertime,
            };
          }
          return matchingNewEmployee;
        }
        return existingEmployee;
      });

      // Compare and merge the new addresses
      const existingAddresses = data.orderAddresses || [];
      const newAddresses = order.orderAddresses || [];

      const mergedOrderAddresses = existingAddresses.map((existingAddress) => {
        const matchingNewAddress = newAddresses.find(
          (newAddress) => existingAddress.title === newAddress.title
            && existingAddress.address === newAddress.address,
        );

        if (matchingNewAddress) {
          return matchingNewAddress;
        }
        return existingAddress;
      });

      // Compare and merge the new orderTags
      const existingTags = data.orderTags || [];
      const newTags = order.orderTags || [];
      const mergedTags = existingTags.map((existingTag) => {
        const matchingNewTag = newTags.find(
          (newTag) => existingTag.title === newTag.title,
        );
        return matchingNewTag || existingTag;
      });

      // // Compare and merge the new documents
      const existingDocuments = data.documents || [];
      const newDocuments = order.documents || [];
      const mergedDocuments = existingDocuments.map((existingDocument) => {
        const matchingNewDocument = newDocuments.find(
          (newDocument) => existingDocument.blobId === newDocument.blobId,
        );
        return matchingNewDocument || existingDocument;
      });

      // Compare and merge the new orderItems
      const existingItems = data.orderItems || [];
      const newItems = order.orderItems || [];
      const mergedItems = existingItems.map((existingItem) => {
        const matchingNewItem = newItems.find(
          (newItem) => existingItem.title === newItem.title,
        );
        return matchingNewItem ? { ...existingItem, id: matchingNewItem.id } : existingItem;
      });

      // // Compare and merge the new orderChecklists
      const existingChecklists = data.orderChecklists || [];
      const newChecklists = order.orderChecklists || [];
      const mergedChecklists = existingChecklists.map((existingChecklist) => {
        const matchingNewChecklist = newChecklists.find(
          (newChecklist) => existingChecklist.taskCategory.id === newChecklist.taskCategory.id,
        );

        if (matchingNewChecklist) {
          const mergedTasks = existingChecklist.tasks.map((existingTask) => {
            const matchingNewTask = matchingNewChecklist.tasks.find(
              (newTask) => existingTask.id === newTask.id
                && existingTask.taskCategoryId === newTask.taskCategoryId,
            );
            if (matchingNewTask && existingTask.nextId && existingTask.nextTitle) {
              return { ...matchingNewTask, id: existingTask.nextId, title: existingTask.nextTitle };
            }
            return matchingNewTask || existingTask;
          });
          return {
            ...matchingNewChecklist,
            tasks: mergedTasks,
          };
        }
        return existingChecklist;
      });

      const existingNotes = data.orderNote || {};
      const newNotes = order.orderNote || {};
      const mergedNotes = existingNotes.notes === newNotes.notes
        ? { id: newNotes.id, notes: data.notes } : { notes: existingNotes.notes, id: null };

      const mergedOrderData = {
        ...data,
        id: order.id,
        orderEmployees: mergedEmployees,
        orderAddresses: mergedOrderAddresses,
        orderTags: mergedTags,
        documents: mergedDocuments,
        orderItems: mergedItems,
        orderChecklists: mergedChecklists,
        orderNote: mergedNotes,
      };

      if (orders.openedFromPunches) {
        dispatch(requestPunchesData(names.timeManagementPunches));
      }

      if (orders.openedFromOverview) {
        dispatch(requestOverviewRecords(names.timeManagementNextOverview));
      }

      dispatch(requestUpdateOrder(mergedOrderData));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_update_error'));
    }
  },
);

export const requestDeleteOrder = createAsyncThunk(
  'order/deleteOrder',
  async (order, { extra, dispatch }) => {
    try {
      await extra.axios.delete(`/api/v3/orders/${order.id}`);
      dispatch(setSnackbarSuccess('order_deleted_successfully'));
      if (order.projectId) {
        dispatch(requestProjectOrders({ id: order.projectId, name: names.projectOrders }));
      }
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_delete_error'));
    }
  },
);

export const requestDeleteOrderFromCalendar = createAsyncThunk(
  'order/deleteOrderFromCalendar',
  async (data, { extra, dispatch, rejectWithValue }) => {
    try {
      await extra.axios.delete(`/api/v3/orders/${data.id}`);
      dispatch(setSnackbarSuccess('order_deleted_successfully'));
      return { orderId: data.id, sequenceNum: data.sequenceNum };
    } catch (err) {
      dispatch(setSnackbarError('nackbar_order_delete_error'));
      return rejectWithValue({ sequenceNum: data.sequenceNum });
    }
  },
);

export const requestDeleteVirtualOrder = createAsyncThunk(
  'order/deleteVirtualOrder',
  async (data, { extra, dispatch }) => {
    try {
      const params = {
        project_id: data.projectId,
        order_date: data.date,
      };
      const order = await requestCreateVirtualOrder(extra.axios, params, dispatch);
      await extra.axios.delete(`/api/v3/orders/${order.id}`);
      dispatch(setSnackbarSuccess('order_deleted_successfully'));
      if (data.projectId) {
        dispatch(requestProjectOrders({ id: data.projectId, name: names.projectOrders }));
      }
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_delete_error'));
    }
  },
);

export const requestDeleteVirtualOrderFromCalendar = createAsyncThunk(
  'order/deleteVirtualOrderFromCalendar',
  async (data, { extra, dispatch, rejectWithValue }) => {
    try {
      const params = {
        project_id: data.projectId,
        order_date: data.date,
      };
      const order = await requestCreateVirtualOrder(extra.axios, params, dispatch);
      await extra.axios.delete(`/api/v3/orders/${order.id}`);
      dispatch(setSnackbarSuccess('order_deleted_successfully'));
      return { orderId: order.id, sequenceNum: order.orderSequenceNum };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_delete_error'));
      return rejectWithValue({ sequenceNum: data.sequenceNum });
    }
  },
);

export const requestRemoveVirtualOrderEmployeeFromCalendar = createAsyncThunk(
  'order/removeEmployeeInVirtualOrderFromCalendar',
  async (data, { extra, dispatch, getState }) => {
    const { profile } = getState();
    const params = {
      project_id: data.projectId,
      order_date: data.date,
    };
    const response = await requestCreateVirtualOrder(extra.axios, params, dispatch);
    const order = getFormattedOrderData(response);
    const existingItems = data.orderItems || [];
    const newItems = order.orderItems || [];
    const mergedItems = existingItems.map((existingItem) => {
      const matchingNewItem = newItems.find(
        (newItem) => existingItem.title === newItem.title,
      );
      return matchingNewItem ? { ...existingItem, id: matchingNewItem.id } : existingItem;
    });
    const employees = order.orderEmployees.map(
      (emp) => (emp.employeeId === data.selectedEmployeeId
        ? { ...emp, destroy: true }
        : emp
      ),
    );
    const body = getFormattedOrderRequestBody({
      ...order,
      orderEmployees: employees,
      orderItems: mergedItems,
    }, profile.company);
    const formData = new FormData();
    formData.append('order', JSON.stringify({ ...body }));
    await extra.axios.put(`/api/v3/orders/${order.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch(setSnackbarSuccess('snackbar_order_updated'));
    return { orderId: order.id, sequenceNum: data.sequenceNum };
  },
);

export const requestBulkUpdateOrdersStatus = createAsyncThunk(
  'order/updateOrdersStatus',
  async (data, { extra, dispatch }) => {
    try {
      const params = {
        orders: data.orders,
        status: data.status,
        virtual_orders: data.virtualOrders,
      };
      const response = await extra.axios.put('/api/v3/orders/bulk_update_status', params);
      dispatch(setSnackbarSuccess('snackbar_order_status_updated'));
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_update_status_error'));
    }
  },
);

export const requestCompleteVirtualOrder = createAsyncThunk(
  'order/completeVirtualOrder',
  async (data, { extra, dispatch }) => {
    try {
      const params = {
        project_id: data.projectId,
        order_date: data.date,
      };
      const order = await requestCreateVirtualOrder(extra.axios, params, dispatch);
      await extra.axios.put(`/api/v3/orders/${order.id}/mark_complete`);
      dispatch(setSnackbarSuccess('snackbar_order_complete'));
      dispatch(requestOrder({ orderId: order.id }));
      if (data.projectId) {
        dispatch(requestProjectOrders({ id: data.projectId, name: names.projectOrders }));
      }
      return {
        orderId: order.id,
        sequenceNum: order.orderSequenceNum,
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_complete_error'));
    }
  },
);

export const requestCompleteVirtualOrderFromCalendar = createAsyncThunk(
  'order/completeVirtualOrderFromCalendar',
  async (data, { extra, dispatch, rejectWithValue }) => {
    try {
      const params = {
        project_id: data.projectId,
        order_date: data.date,
      };
      const order = await requestCreateVirtualOrder(extra.axios, params, dispatch);
      await extra.axios.put(`/api/v3/orders/${order.id}/mark_complete`);
      dispatch(setSnackbarSuccess(fm('snackbar_order_complete')));
      return { orderId: order.id, sequenceNum: order.orderSequenceNum };
    } catch (err) {
      dispatch(setSnackbarError(fm('snackbar_order_complete_error')));
      return rejectWithValue({ sequenceNum: data.sequenceNum });
    }
  },
);

export const requestCompleteOrder = createAsyncThunk(
  'order/completeOrder',
  async (data, { extra, dispatch }) => {
    try {
      await extra.axios.put(`/api/v3/orders/${data.id}/mark_complete`);
      dispatch(setSnackbarSuccess('snackbar_order_complete'));
      dispatch(requestOrder({ orderId: data.id }));
      if (data.projectId) {
        dispatch(requestProjectOrders({ id: data.projectId, name: names.projectOrders }));
      }
      return {
        orderId: data.id,
        sequenceNum: data.sequenceNum,
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_complete_error'));
    }
  },
);

export const requestCompleteOrderFromCalendar = createAsyncThunk(
  'order/completeOrderFromCalendar',
  async (data, { extra, dispatch, rejectWithValue }) => {
    try {
      await extra.axios.put(`/api/v3/orders/${data.id}/mark_complete`);
      dispatch(setSnackbarSuccess('snackbar_order_complete'));
      return { orderId: data.id, sequenceNum: data.sequenceNum };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_complete_error'));
      return rejectWithValue({ sequenceNum: data.sequenceNum });
    }
  },
);

export const requestCancelOrder = createAsyncThunk(
  'order/cancelOrder',
  async (data, { extra, dispatch, rejectWithValue }) => {
    try {
      await extra.axios.put(`/api/v3/orders/${data.id}/mark_cancel`);
      dispatch(setSnackbarSuccess('snackbar_order_cancel'));
      dispatch(requestOrder({ orderId: data.id }));
      if (data.projectId) {
        dispatch(requestProjectOrders({ id: data.projectId, name: names.projectOrders }));
      }
      return {
        orderId: data.id,
        sequenceNum: data.sequenceNum,
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_cancel_error'));
      return rejectWithValue({ sequenceNum: data.sequenceNum });
    }
  },
);

export const requestCancelOrderFromCalendar = createAsyncThunk(
  'order/cancelOrderFromCalendar',
  async (data, { extra, dispatch, rejectWithValue }) => {
    try {
      await extra.axios.put(`/api/v3/orders/${data.id}/mark_cancel`);
      dispatch(setSnackbarSuccess('snackbar_order_cancel'));
      return { orderId: data.id, sequenceNum: data.sequenceNum };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_cancel_error'));
      return rejectWithValue({ sequenceNum: data.sequenceNum });
    }
  },
);

export const requestCancelVirtualOrder = createAsyncThunk(
  'order/cancelVirtualOrder',
  async (data, { extra, dispatch, rejectWithValue }) => {
    try {
      const params = {
        project_id: data.projectId,
        order_date: data.date,
      };
      const order = await requestCreateVirtualOrder(extra.axios, params, dispatch);
      await extra.axios.put(`/api/v3/orders/${order.id}/mark_cancel`);
      dispatch(setSnackbarSuccess('snackbar_order_cancel'));
      dispatch(requestOrder({ orderId: order.id }));
      if (data.projectId) {
        dispatch(requestProjectOrders({ id: data.projectId, name: names.projectOrders }));
      }
      return {
        orderId: order.id,
        sequenceNum: order.orderSequenceNum,
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_cancel_error'));
      return rejectWithValue({ sequenceNum: data.sequenceNum });
    }
  },
);

export const requestCancelVirtualOrderFromCalendar = createAsyncThunk(
  'order/cancelVirtualOrderFromCalendar',
  async (data, { extra, dispatch, rejectWithValue }) => {
    try {
      const params = {
        project_id: data.projectId,
        order_date: data.date,
      };
      const order = await requestCreateVirtualOrder(extra.axios, params, dispatch);
      await extra.axios.put(`/api/v3/orders/${order.id}/mark_cancel`);
      dispatch(setSnackbarSuccess('snackbar_order_cancel'));
      return { orderId: order.id, sequenceNum: order.orderSequenceNum };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_cancel_error'));
      return rejectWithValue({ sequenceNum: data.sequenceNum });
    }
  },
);

export const requestActivateOrder = createAsyncThunk(
  'order/activateOrder',
  async (data, { extra, dispatch }) => {
    try {
      await extra.axios.put(`/api/v3/orders/${data.id}/mark_active`);
      dispatch(setSnackbarSuccess('snackbar_order_activated'));
      dispatch(requestOrder({ orderId: data.id }));
      if (data.projectId) {
        dispatch(requestProjectOrders({ id: data.projectId, name: names.projectOrders }));
      }
      return {
        orderId: data.id,
        sequenceNum: data.sequenceNum,
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_activate_error'));
    }
  },
);

export const requestActivateOrderFromCalendar = createAsyncThunk(
  'order/activateOrderFromCalendar',
  async (data, { extra, dispatch, rejectWithValue }) => {
    try {
      await extra.axios.put(`/api/v3/orders/${data.id}/mark_active`);
      dispatch(setSnackbarSuccess('snackbar_order_activated'));
      return { orderId: data.id, sequenceNum: data.sequenceNum };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_activate_error'));
      return rejectWithValue({ sequenceNum: data.sequenceNum });
    }
  },
);

export const requestFreezeOrder = createAsyncThunk(
  'order/freezeOrder',
  async (data, { extra, dispatch }) => {
    try {
      await extra.axios.put(`/api/v3/orders/${data.id}/mark_freeze`);
      dispatch(setSnackbarSuccess('snackbar_order_freezed'));
      dispatch(requestOrder({ orderId: data.id }));
      if (data.projectId) {
        dispatch(requestProjectOrders({ id: data.projectId, name: names.projectOrders }));
      }
      return {
        orderId: data.id,
        sequenceNum: data.sequenceNum,
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_freeze_error'));
    }
  },
);

export const requestFreezeVirtualOrder = createAsyncThunk(
  'order/freezeVirtualOrder',
  async (data, { extra, dispatch }) => {
    try {
      const params = {
        project_id: data.projectId,
        order_date: data.date,
      };
      const order = await requestCreateVirtualOrder(extra.axios, params, dispatch);
      await extra.axios.put(`/api/v3/orders/${order.id}/mark_freeze`);
      dispatch(setSnackbarSuccess('snackbar_order_freezed'));
      dispatch(requestOrder({ orderId: order.id }));
      if (data.projectId) {
        dispatch(requestProjectOrders({ id: data.projectId, name: names.projectOrders }));
      }
      return {
        orderId: order.id,
        sequenceNum: data.sequenceNum,
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_freeze_error'));
    }
  },
);

export const requestOrderToCopy = createAsyncThunk(
  'order/copyOrder',
  async (data, { extra, dispatch }) => {
    try {
      if (!data.virtual) {
        const response = await extra.axios.get(`/api/v3/orders/${data.id}`);
        const { order } = response.data.data;
        return {
          order: getCopyOrderData({ ...order, orderDocuments: (order.documents || []) }),
          orderDocuments: { records: (order.documents || []).map((doc) => ({ ...doc, id: undefined })) },
        };
      }
      return {
        order: getCopyVirtualOrderData({ ...data, orderDocuments: (data.documents || []) }),
        orderDocuments: { records: (data.documents || []).map((doc) => ({ ...doc, id: undefined })) },
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_copy_error'));
    }
  },
);

export const requestOrderToCopyFromCalendar = createAsyncThunk(
  'order/copyOrderFromCalendar',
  async (data, { extra, dispatch, getState }) => {
    try {
      if (!data.virtual) {
        const response = await extra.axios.get(`/api/v3/orders/${data.id}`);
        const { order } = response.data.data;
        return {
          order: getCopyOrderData({ ...order, orderDocuments: (order.documents || []) }),
          orderDocuments: { records: (order.documents || []).map((doc) => ({ ...doc, id: undefined })) },
        };
      }
      const headers = extra.getHeadersForSubCompany(data.companyId, getState);
      const params = { date: data.date };
      const response = await extra.axios.get(`/api/v3/projects/${data.projectId}`, { headers, params });
      const { project, projectChecklists, documents } = response.data.data;
      const order = getFormattedVirtualOrderData({
        ...project,
        projectChecklists,
        date: data.date,
      });
      return {
        order: getCopyVirtualOrderData({ ...order, orderDocuments: (documents || []) }),
        orderDocuments: { records: (documents || []).map((doc) => ({ ...doc, id: undefined })) },
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_copy_error'));
    }
  },
);

export const requestOrderActivity = createAsyncThunk(
  'order/activities',
  async (data, { extra, dispatch, getState }) => {
    try {
      const { profile: { user } } = getState();
      const { filters } = extra.getState(data.name, getState);
      const params = getFormattedOrderFilterParams(filters);
      if (!data.order.virtual) {
        const headers = extra.getHeadersForSubCompany(data.companyId, getState);
        const response = await extra.axios.get(`/api/v3/orders/${data.order.id}/activity`, { params, headers });
        const { records, ...nextFilters } = getFormattedListData(response.data.data.activities);
        dispatch(setFilters({ ...nextFilters, name: data.name }));
        return { records };
      }
      const message = fm(
        'project_created',
        undefined,
        { projectId: data.order.projectId, date: data.order.date },
      );
      const response = {
        page: 1,
        perPage: 10,
        totalRecords: 1,
        records: [{ description: message, actorName: user.name }],
      };
      const { records, ...nextFilters } = getFormattedListData(response);
      dispatch(setFilters({ ...nextFilters, name: data.name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_activity_error'));
    }
  },
);

export const requestBulkDeleteOrders = createAsyncThunk(
  'order/deleteOrders',
  async (data, { extra, dispatch }) => {
    const params = {
      orders: data.orders,
      virtual_orders: data.virtualOrders,
    };
    try {
      await extra.axios.delete('/api/v3/orders/bulk_delete', { data: params });
      dispatch(setSnackbarSuccess('orders_deleted_successfully'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_delete_error'));
    }
  },
);

export const requestDeleteOrderDocument = createAsyncThunk(
  'order/deleteDocument',
  async (data, { extra, dispatch, getState }) => {
    const { orders: { orderDocuments: { records } } } = getState();
    try {
      const params = {
        document_id: data.documentId,
      };
      await extra.axios.delete(`/api/v3/orders/${data.order.id}/delete_document`, { params });
      dispatch(setSnackbarSuccess('document_deleted_successfully'));
      return {
        orderDocuments: { records: records.filter((record) => record.id !== data.documentId) },
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_document_delete_error'));
    }
  },
);

export const requestSendOrderEmail = createAsyncThunk(
  'order/sendEmail',
  async (data, { extra, dispatch }) => {
    try {
      const params = {
        personal_message: data.personalMessage,
        fields_to_include: snakify(data.fieldsToInclude),
      };
      await extra.axios.post(`/api/v3/orders/${data.id}/send_email`, params);
      dispatch(setSnackbarSuccess('order_email_sent'));
    } catch (err) {
      if (get('response.data.errors[0].details')(err)) {
        dispatch(setSnackbarError(
          fm(get('response.data.errors[0].details.error_message')(err),
            'Customer email is missing. Add email in customer and try again.'),
        ));
      } else {
        dispatch(setSnackbarError('snackbar_order_email_error'));
      }
    }
  },
);

export const requestSendVirtualOrderEmail = createAsyncThunk(
  'order/sendEmailForVirtualOrder',
  async (data, { extra, rejectWithValue, dispatch }) => {
    try {
      const params = {
        personal_message: data.personalMessage,
        fields_to_include: snakify(data.fieldsToInclude),
      };
      const createOrderParams = {
        project_id: data.order.projectId,
        order_date: data.order.date,
      };
      const order = await requestCreateVirtualOrder(extra.axios, createOrderParams, dispatch);
      await extra.axios.post(`/api/v3/orders/${order.id}/send_email`, params);
      dispatch(setSnackbarSuccess('order_email_sent'));
      return {
        orderId: order.id,
        order: getFormattedOrderData(order),
        orderDocuments: { records: order.documents },
      };
    } catch (err) {
      if (get('response.data.errors[0].details')(err)) {
        dispatch(setSnackbarError(
          fm(get('response.data.errors[0].details.error_message')(err),
            'Customer email is missing. Add email in customer and try again.'),
        ));
      } else {
        dispatch(setSnackbarError('snackbar_order_email_error'));
      }
      return rejectWithValue(err);
    }
  },
);

export const requestSendOrderSms = createAsyncThunk(
  'order/sendSms',
  async (data, { extra, rejectWithValue, dispatch }) => {
    try {
      const params = getFormattedSmsBodyForOrder(data);
      await extra.axios.post(`/api/v3/orders/${data.orderId}/send_sms`, params);
      dispatch(setSnackbarSuccess('order_sms_sent'));
      dispatch(requestOrder({ orderId: data.orderId }));
      return {
        orderId: data.orderId,
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_sms_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestSendVirtualOrderSms = createAsyncThunk(
  'order/sendSmsForVirtualOrder',
  async (data, { extra, rejectWithValue, dispatch }) => {
    try {
      const params = getFormattedSmsBodyForOrder(data);
      const createOrderParams = {
        project_id: data.order.projectId,
        order_date: data.order.date,
      };
      const order = await requestCreateVirtualOrder(extra.axios, createOrderParams, dispatch);
      await extra.axios.post(`/api/v3/orders/${order.id}/send_sms`, params);
      dispatch(setSnackbarSuccess('order_sms_sent'));
      dispatch(requestOrder({ orderId: order.id }));
      return {
        orderId: order.id,
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_send_sms_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestAddOrderEmployeesFromCalendar = createAsyncThunk(
  'order/createOrderFromCalendar',
  async (data, { extra, dispatch }) => {
    try {
      const params = {
        project_id: data.projectId,
        order_date: data.originalDate || data.date,
        skip_attribute: data.skipAttribute,
      };

      const response = await requestCreateVirtualOrder(extra.axios, params, dispatch);
      const order = getFormattedOrderData(response);
      const existingEmployees = order.orderEmployees || [];
      const newEmployees = data.orderEmployees || [];
      const mergedEmployees = [...existingEmployees, ...newEmployees];
      dispatch(requestUpdateOrder({ ...order, orderEmployees: mergedEmployees }));
      dispatch(setSnackbarSuccess('snackbar_order_created'));
      return { sequenceNum: data.sequenceNum };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_create_error'));
      return { sequenceNum: data.sequenceNum };
    }
  },
);

export const requestApproveEmployeeTime = createAsyncThunk(
  'order/approveTime',
  async (data, { extra, dispatch, getState }) => {
    const { orders: { order } } = getState();
    try {
      const params = {
        start_time: data.startTime,
        end_time: data.endTime,
        time: data.time,
      };
      await extra.axios.put(`/api/v3/employee_presence_times/${data.id}/approve`, params);
      dispatch(requestOrder({ orderId: data.orderId }));
      dispatch(setSnackbarSuccess('snackbar_order_employee_time_approved'));
      const orderEmployees = order.orderEmployees.map(
        (emp) => (emp.employeeId === data.employeeId ? { ...emp, employeePresenceTimeApproval: {} } : emp),
      );
      return {
        selectedEmployee: orderEmployees.find((emp) => emp.employeeId === data.employeeId),
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_time_approve_error'));
    }
  },
);

export const requestApproveVirtualOrderEmployeeTime = createAsyncThunk(
  'order/approveVirtualOrderEmployeeTime',
  async (data, { extra, dispatch }) => {
    try {
      const params = {
        project_id: data.order.projectId,
        order_date: data.order.date,
        employee_presence_time_approve_attribute: [data.id],
      };
      const order = await requestCreateVirtualOrder(extra.axios, params, dispatch);
      dispatch(setSnackbarSuccess('order_time_approve'));
      dispatch(requestOrder({ orderId: order.id }));
      const orderEmployees = order.orderEmployees.map(
        (emp) => (emp.employeeId === data.employeeId ? { ...emp, employeePresenceTimeApproval: {} } : emp),
      );
      const formattedOrderEmployees = orderEmployees.map(formatOrderEmployee);
      return {
        orderId: order.id,
        order: getFormattedOrderData({ ...order, orderEmployees }),
        orderDocuments: { records: order.documents },
        selectedEmployee: formattedOrderEmployees.find((emp) => emp.employeeId === data.employeeId),
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_time_approve_error'));
    }
  },
);

export const requestApproveEmployeeTimeFromCalendar = createAsyncThunk(
  'order/approveTime',
  async (data, { extra, dispatch, getState }) => {
    const { orders: { order } } = getState();
    try {
      const params = {
        start_time: data.startTime,
        end_time: data.endTime,
        time: data.time,
      };
      await extra.axios.put(`/api/v3/employee_presence_times/${data.id}/approve`, params);
      dispatch(setSnackbarSuccess('snackbar_order_employee_time_approved'));
      return {
        orderId: order.id,
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_time_approve_error'));
    }
  },
);

export const requestApproveVirtualOrderEmployeeTimeFromCalendar = createAsyncThunk(
  'order/approveVirtualOrderEmployeeTime',
  async (data, { extra, dispatch }) => {
    try {
      const params = {
        project_id: data.order.projectId,
        order_date: data.order.date,
        employee_presence_time_approve_attribute: [data.id],
      };
      const order = await requestCreateVirtualOrder(extra.axios, params, dispatch);
      dispatch(setSnackbarSuccess('order_time_approve'));
      return {
        orderId: order.id,
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_time_approve_error'));
    }
  },
);

export const requestDeleteEmployeePresenceTimeApproval = createAsyncThunk(
  'order/deleteEmployeePresenceTimeApproval',
  async (data, { extra, dispatch }) => {
    try {
      const params = {
        approval_id: data.approvalId,
      };
      await extra.axios.delete(`/api/v3/employee_presence_times/${data.presenceTimeId}/delete_approval`, { params });
      dispatch(setSnackbarSuccess('snackbar_order_time_unapprove'));
      dispatch(requestOrder({ orderId: data.order.id }));
      const orderEmployees = data.order.orderEmployees.map(
        (emp) => (emp.employeeId === data.employeeId ? { ...emp, employeePresenceTimeApproval: null } : emp),
      );
      return {
        selectedEmployee: orderEmployees.find((emp) => emp.employeeId === data.employeeId),
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_time_approve_error'));
    }
  },
);

export const requestDeleteEmployeePresenceTimeApprovalFromCalendar = createAsyncThunk(
  'order/deleteEmployeePresenceTimeApproval',
  async (data, { extra, dispatch }) => {
    try {
      const params = {
        approval_id: data.approvalId,
      };
      await extra.axios.delete(`/api/v3/employee_presence_times/${data.presenceTimeId}/delete_approval`, { params });
      dispatch(setSnackbarSuccess('snackbar_order_time_unapprove'));
      return {
        orderId: data.order.id,
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_time_approve_error'));
    }
  },
);

export const requestUpdateOrderInvoiceId = createAsyncThunk(
  'order/updateInvoiceId',
  async (data, { extra, dispatch }) => {
    try {
      const params = {
        invoice_id: data.invoiceId,
      };
      await extra.axios.put(`/api/v3/orders/${data.id}/update_invoice_id`, params);
      dispatch(setSnackbarSuccess('order_invoice_id_updated'));
      dispatch(requestOrder({ orderId: data.id }));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_invoice_id_update_error'));
    }
  },
);

export const requestConversations = createAsyncThunk(
  'order/getConversations',
  async (orderId, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get(`/api/v3/orders/${orderId}/conversation_list`);
      const { records } = response.data.data;
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_fetch_conversations_failed'));
    }
  },
);

export const requestCreateConversation = createAsyncThunk(
  'order/createConversations',
  async (data, { extra, dispatch }) => {
    try {
      const params = {
        conversation: {
          body: data.message,
        },
      };
      await extra.axios.post(`/api/v3/orders/${data.orderId}/create_conversation`, params);
      dispatch(requestConversations(data.orderId));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_message_send_error'));
    }
  },
);

export const requestCreateConversationForVirtualOrder = createAsyncThunk(
  'order/createConversationsForVirtualOrder',
  async (data, { extra, dispatch }) => {
    try {
      const createOrderParams = {
        project_id: data.order.projectId,
        order_date: data.order.date,
      };
      const params = {
        conversation: {
          body: data.message,
        },
      };
      const order = await requestCreateVirtualOrder(extra.axios, createOrderParams, dispatch);
      await extra.axios.post(`/api/v3/orders/${order.id}/create_conversation`, params);
      dispatch(requestConversations(order.id));
      return {
        orderId: order.id,
        order: getFormattedOrderData(order),
        orderDocuments: { records: order.documents },
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_message_send_error'));
    }
  },
);

export const requestMarkConversationsAsRead = createAsyncThunk(
  'order/markAllRead',
  async (ids, { extra, dispatch }) => {
    try {
      const params = { conversation: { ids } };
      await extra.axios.put('/api/v3/orders/mark_all_conversation_as_read', params);
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_message_mark_all_read_error'));
    }
  },
);

export const requestMarkConversationAsRead = createAsyncThunk(
  'order/markRead',
  async (data, { extra, dispatch, getState }) => {
    const { orders: { allConversations = {} } } = getState();
    const { records = [] } = allConversations;
    try {
      await extra.axios.put(
        `/api/v3/orders/${data.orderId}/conversations/${data.conversationId}/mark_conversation_as_read`,
      );
      const newRecords = records.map((n) => (n.id === data.conversationId ? { ...n, read: true } : n));
      return { records: newRecords };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_message_mark_read_error'));
    }
  },
);

export const requestAllConversations = createAsyncThunk(
  'order/getAllConversations',
  async (data, { extra, dispatch, getState }) => {
    const { orders: { allConversations = {} }, filters } = getState();
    const { records = [] } = allConversations;
    try {
      const conversationFilters = multiStateGet(names.conversations, filters);
      const query = { per_page: conversationFilters.perPage, page: conversationFilters.page };
      const response = await extra.axios.get('/api/v3/orders/all_conversation', { params: query });
      const { records: newRecords, ...rest } = response.data.data;
      dispatch(setFilters({ ...rest, name: names.conversations }));
      if (query.page > 1) {
        const nextRecords = [...records, ...newRecords];
        return { records: nextRecords };
      }
      return { records: newRecords };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_conversation_error'));
    }
  },
);

export const requestSendOrderTestEmail = createAsyncThunk(
  'order/sendTestEmail',
  async (data, { extra, dispatch }) => {
    try {
      const params = {
        email_address: data.emailAddress,
        fields_to_include: snakify(data.fieldsToInclude),
        personal_message: data.personalMessage,
      };
      await extra.axios.post(`/api/v3/orders/${data.id}/send_test_email`, params);
      dispatch(setSnackbarSuccess('order_test_email_sent'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_orders_test_email_error'));
    }
  },
);

export const requestSendVirtualOrderTestEmail = createAsyncThunk(
  'order/sendEmailForVirtualOrder',
  async (data, { extra, dispatch }) => {
    try {
      const params = {
        email_address: data.emailAddress,
        fields_to_include: snakify(data.fieldsToInclude),
        personal_message: data.personalMessage,
      };
      const createOrderParams = {
        project_id: data.order.projectId,
        order_date: data.order.date,
      };
      const order = await requestCreateVirtualOrder(extra.axios, createOrderParams, dispatch);
      await extra.axios.post(`/api/v3/orders/${order.id}/send_test_email`, params);
      dispatch(setSnackbarSuccess('order_test_email_sent'));
      return {
        orderId: order.id,
        order: getFormattedOrderData(order),
        orderDocuments: { records: order.documents },
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_email_error'));
    }
  },
);

export const requestSendBulkEmail = createAsyncThunk(
  'order/bulkEmail',
  async (data, { extra, dispatch }) => {
    const params = {
      orders: data.orders,
      virtual_orders: data.virtualOrders,
      fields_to_include: snakify(data.fieldsToInclude),
      personal_message: data.personalMessage,
    };
    try {
      await extra.axios.post('/api/v3/orders/send_bulk_email', params);
      dispatch(setSnackbarSuccess('orders_email_sent'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_email_error'));
    }
  },
);

export const requestOrderCustomerRequests = createAsyncThunk(
  'order/getCustomerRequests',
  async (data, { extra, rejectWithValue, dispatch, getState }) => {
    const { filters } = getState();
    try {
      const { page, perPage } = multiStateGet(data.name, filters);
      const params = { per_page: perPage, page };
      const headers = extra.getHeadersForSubCompany(data.companyId, getState);
      const response = await extra.axios.get(
        `/api/v3/orders/${data.orderId}/order_customer_request`,
        { params, headers },
      );
      return { records: response.data.data };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_fetch_customer_requests_failed'));
      return rejectWithValue(err);
    }
  },
);

export const requestApproveCustomerRequest = createAsyncThunk(
  'order/approveRequest',
  async (data, { extra, dispatch }) => {
    try {
      if (data.projectId && data.requestType === customerRequestType.date) {
        await dispatch(requestCheckDuplicateOrderOnDateChange({
          projectId: data.projectId,
          date: data.date,
        }));
      }
      const params = {
        order_customer_request_id: data.requestId,
      };
      await extra.axios.put(`/api/v3/orders/${data.orderId}/accept_customer_request`, params);
      dispatch(requestOrder({ orderId: data.orderId }));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_message_mark_read_error'));
    }
  },
);

export const requestDenyCustomerRequest = createAsyncThunk(
  'order/denyRequest',
  async (data, { extra, dispatch }) => {
    try {
      const params = {
        order_customer_request_id: data.requestId,
      };
      await extra.axios.put(`/api/v3/orders/${data.orderId}/denied_customer_request`, params);
      dispatch(requestOrder({ orderId: data.orderId }));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_message_mark_read_error'));
    }
  },
);

export const requestOrdersCustomerRequests = createAsyncThunk(
  'order/getAllCustomerRequests',
  async (name, { extra, dispatch, getState }) => {
    const { filters, orders: { allCustomerRequests } } = getState();
    try {
      const { page, perPage: per_page = 10, requestStatus } = multiStateGet(name, filters);
      const params = { per_page, page, request_status: requestStatus === 'view_all' ? undefined : requestStatus };
      const response = await extra.axios.get('/api/v3/orders/customer_requests', { params });
      const { records, ...paginationFields } = getFormattedListData(response.data.data);
      dispatch(setFilters({ ...paginationFields, name }));
      return { records: page === 1 ? records : [...allCustomerRequests.records, ...records] };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_fetch_all_customer_requests_failed'));
    }
  },
);

export const requestOrderSmsEmailHistory = createAsyncThunk(
  'order/fetchOrderSmsHistory',
  async (orderId, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get(`/api/v3/orders/${orderId}/customer_message_histories`);
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_orders_fetch_error'));
    }
  },
);
