import * as Yup from 'yup';
import { fm } from 'utils/string';
import Organisationsnummer from 'organisationsnummer';
import { organisationTypes } from 'utils/enum';

const signupFormValidationSchema = Yup.object().shape({
  acceptTerms: Yup.boolean().required(fm('accept_signup_terms')).oneOf([true], fm('accept_signup_terms')),
  companyName: Yup.string().shouldNotContainEmoji().required(fm('company.company_name.required')),
  email: Yup.string().shouldNotContainEmoji().required(fm('company.email.required')).email(),
  name: Yup.string().shouldNotContainEmoji().required(fm('company.name.required')),
  password: Yup.string().shouldNotContainEmoji().required(fm('company.password.required'))
    .test('password', fm('password_must_be_8_letter'), (password) => password?.length > 7),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], fm('company.password_match.required')),
  orgType: Yup.string().oneOf(Object.values(organisationTypes), fm('company.organisation_type.required')),
  identificationNum: Yup
    .string('only_numbers_are_allowed')
    .nullable()
    .shouldNotContainEmoji()
    .required(fm('company.org_number.required'))
    .test(
      'identificationNum',
      fm('identifiication_num_invalid'),
      function testOrgType (identificationNum) { // eslint-disable-line
        const { orgType } = this.parent;
        try {
          if (orgType === organisationTypes.soleTrader) {
            const numberInstance = new Organisationsnummer(identificationNum);
            const validOrgType = numberInstance.type() === 'Enskild firma';
            return numberInstance.isPersonnummer() && validOrgType;
          }
          if (orgType === organisationTypes.limitedLiability) {
            const numberInstance = new Organisationsnummer(identificationNum);
            const validOrgType = numberInstance.type() === 'Aktiebolag';
            return Organisationsnummer.valid(identificationNum) && validOrgType;
          }
          if (orgType === organisationTypes.tradingPartnership) {
            const numberInstance = new Organisationsnummer(identificationNum);
            const validOrgType = numberInstance.type() === 'Handelsbolag, kommanditbolag och enkelt bolag';
            return Organisationsnummer.valid(identificationNum) && validOrgType;
          }
          return Organisationsnummer.valid(identificationNum);
        } catch (err) {
          console.log(err);
          return false;
        }
      },
    ),
});

export default signupFormValidationSchema;
