import { createAsyncThunk } from '@reduxjs/toolkit';
import { getStandardDate } from 'utils/dateTime';
import { setSnackbarError, setTimeManagementFields, setSnackbarSuccess } from 'appRedux/actions';
import { groupBy } from 'utils/lodash';
import { keyStatusType } from 'utils/enum';
import { setBookingFilters } from 'appRedux/employee/booking';
import {
  getFormattedDataForVirtualOrder,
  getFormattedEmployeeOrdersList,
  getFormattedOrderData,
  validateAndGetFormattedEmployeeOrderTimeBody,
  getFormattedOrderChecklists,
  getFormattedBodyForPunchInOut,
} from './selector';

export const requestCreateVirtualOrder = async (axios, params) => {
  const response = await axios.post('/api/v3/orders/create_order', params);
  const { order } = response.data.data;
  return order;
};

export const requestOrders = createAsyncThunk(
  'orders/fetchOrders',
  async (filters, { extra, dispatch, getState }) => {
    const { employee: { profile } } = getState();
    try {
      const params = {
        start_date: filters.startDate || getStandardDate(),
        end_date: filters.endDate,
        supervisor_only: filters.supervisorOnly,
      };
      const response = await extra.axios.get('/api/v3/employee/projects/orders', { params });
      const { records } = getFormattedEmployeeOrdersList(response.data.data.orders, profile.user);
      const employeeOrders = groupBy('date')(records);
      return { records: employeeOrders };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_orders_error'));
    }
  },
);

export const requestOrder = createAsyncThunk(
  'orders/fetchOrder',
  async (orderId, { extra, rejectWithValue, dispatch }) => {
    try {
      const response = await extra.axios.get(`/api/v3/employee/orders/${orderId}`);
      const order = getFormattedOrderData(response.data.data);
      return order;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_get_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestVirtualOrder = createAsyncThunk(
  'orders/fetchVirtualOrder',
  async (data, { extra, rejectWithValue, dispatch, getState }) => {
    try {
      const { employee: { profile: { user } } } = getState();
      const response = await extra.axios.get(`/api/v3/employee/projects/${data.projectId}`);
      const order = getFormattedDataForVirtualOrder(response.data.data, data.date, user);
      return order;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_get_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestUpdateOrderApprovalStatus = createAsyncThunk(
  'orders/approveOrder',
  async (orderId, { extra, dispatch }) => {
    try {
      await extra.axios.put(`api/v3/employee/orders/${orderId}/confirm`);
      dispatch(setBookingFilters({ employeeConfirmationRequired: false }));
    } catch (err) {
      dispatch(setSnackbarError('snackbar.employeeView.bookingApproval.error'));
    }
  },
);

export const requestUpdateVirtualOrderApprovalStatus = createAsyncThunk(
  'orders/approveVirtualOrder',
  async (data, { extra, dispatch }) => {
    try {
      const params = {
        project_id: data.projectId,
        order_date: data.date,
      };
      const order = await requestCreateVirtualOrder(extra.axios, params, dispatch);
      await extra.axios.put(`api/v3/employee/orders/${order.id}/confirm`);
      data.history.replace(`/employee/order?orderId=${order.id}`);
      dispatch(setBookingFilters({ employeeConfirmationRequired: false }));
    } catch (err) {
      dispatch(setSnackbarError('snackbar.employeeView.bookingApproval.error'));
    }
  },
);

export const requestUpdateOrderTime = createAsyncThunk(
  'orders/updateOrderTime',
  async (data, { extra, dispatch, getState }) => {
    try {
      const { employee: { orders: { order }, profile: { user } } } = getState();
      let orderId = order.id;
      const { details, history } = data;
      let { presenceTimeId } = details;
      if (order.virtual) {
        const params = {
          project_id: order.projectId,
          order_date: order.date,
        };
        const orderData = await requestCreateVirtualOrder(extra.axios, params, dispatch);
        orderId = orderData.id;
        presenceTimeId = orderData.orderEmployees.find((emp) => emp.employeeId === user.id).id;
      }
      const formattedData = await validateAndGetFormattedEmployeeOrderTimeBody(details, order.date);
      await extra.axios.put(`/api/v3/employee_presence_times/${presenceTimeId}`, formattedData);
      history.replace(`/employee/order?orderId=${orderId}`);
      dispatch(setTimeManagementFields({ showEditTimeModal: false }));
    } catch (err) {
      dispatch(setSnackbarError('snackbar.timeManagement.timeUpdate.error'));
    }
  },
);

export const requestMarkTaskCompleteForOrder = createAsyncThunk(
  'orders/updateTaskStatus',
  async (data, { extra, dispatch, getState }) => {
    try {
      const { employee: { orders: { order } } } = getState();
      let orderId = order.id;
      let { bookingTaskId } = data;
      if (order.virtual) {
        const params = {
          project_id: order.projectId,
          order_date: order.date,
        };
        const orderData = await requestCreateVirtualOrder(extra.axios, params, dispatch);
        const formattedOrderChecklist = getFormattedOrderChecklists(orderData.orderChecklists);
        orderId = orderData.id;
        const orderChecklist = formattedOrderChecklist.find(
          (checklist) => checklist.tasks.some((task) => task.id === data.id),
        );
        bookingTaskId = orderChecklist.tasks.find((task) => task.id === data.id).bookingTaskId;
      }

      const params = { order_checklist: { completed: !data.completed } };
      await extra.axios.put(`/api/v3/order_checklists/${bookingTaskId}`, params);
      dispatch(requestOrder(orderId));
    } catch (err) {
      dispatch(setSnackbarError('snackbar.timeManagement.timeUpdate.error'));
    }
  },
);

export const requestCompleteVirtualOrder = createAsyncThunk(
  'orders/completeVirtualOrder',
  async (data, { extra, dispatch }) => {
    try {
      const params = {
        project_id: data.order.projectId,
        order_date: data.order.date,
      };
      const order = await requestCreateVirtualOrder(extra.axios, params, dispatch);
      await extra.axios.put(`/api/v3/employee/orders/${order.id}/mark_complete`);
      data.history.replace(`/employee/order?orderId=${order.id}`);
      dispatch(requestOrder(order.id));
      dispatch(setSnackbarSuccess('snackbar_employeeView_order_markCompleted'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar.booking.complete.error'));
    }
  },
);

export const requestCompleteOrder = createAsyncThunk(
  'booking/completeOrder',
  async (orderId, { extra, dispatch }) => {
    try {
      await extra.axios.put(`/api/v3/employee/orders/${orderId}/mark_complete`);
      dispatch(requestOrder(orderId));
      dispatch(setSnackbarSuccess('snackbar_employeeView_order_markCompleted'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar.booking.complete.error'));
    }
  },
);

export const requestChangeKeyStatus = createAsyncThunk(
  'orders/changeKeyStatus',
  async (data, { extra, dispatch }) => {
    try {
      const { key, order } = data;
      let orderId = order.id;
      if (order.virtual) {
        const params = {
          project_id: order.projectId,
          order_date: order.date,
        };
        const orderData = await requestCreateVirtualOrder(extra.axios, params, dispatch);
        orderId = orderData.id;
      }
      const params = {
        key_id: key.keyManagement.id,
        status: key.keyManagement.status === keyStatusType.in ? keyStatusType.out : keyStatusType.in,
      };
      await extra.axios.put(`/api/v3/employee/orders/${orderId}/update_key_status`, params);
      dispatch(requestOrder(orderId));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_key_status_error'));
    }
  },
);

export const requestPunchInOnOrder = createAsyncThunk(
  'orders/punchInOnOrder',
  async (data, { extra, dispatch, getState }) => {
    try {
      const { details, history } = data;
      const { employee: { orders: { order } } } = getState();
      let orderId = order.id;
      if (order.virtual) {
        const params = { project_id: order.projectId, order_date: order.date };
        const orderData = await requestCreateVirtualOrder(extra.axios, params, dispatch);
        orderId = orderData.id;
      }
      const body = getFormattedBodyForPunchInOut({ ...details, orderId });
      await extra.axios.post('/api/v3/employee/employee_punches', body);
      history.replace(`/employee/order?orderId=${orderId}`);
      dispatch(requestOrder(orderId));
      dispatch(setSnackbarSuccess('snackbar.employeeView.punchedIn'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar.employeeView.punch.error'));
    }
  },
);

export const requestPunchOutOnOrder = createAsyncThunk(
  'orders/punchOutOnOrder',
  async (punch, { extra, dispatch, getState }) => {
    try {
      const { employee: { orders: { order } } } = getState();
      const body = getFormattedBodyForPunchInOut(punch);
      await extra.axios.put(`/api/v3/employee/employee_punches/${punch.id}`, body);
      dispatch(requestOrder(order.id));
      dispatch(setSnackbarSuccess('snackbar.employeeView.punchedOut'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar.employeeView.punch.error'));
    }
  },
);

export const requestConversations = createAsyncThunk(
  'orders/getEmployeeConversations',
  async (orderId, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get(`/api/v3/orders/${orderId}/conversation_list`);
      const { records } = response.data.data;
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_fetch_conversations_failed'));
    }
  },
);

export const requestCreateConversation = createAsyncThunk(
  'orders/createEmployeeConversations',
  async (data, { extra, dispatch }) => {
    try {
      const params = {
        conversation: {
          body: data.message,
        },
      };
      await extra.axios.post(`/api/v3/orders/${data.orderId}/create_conversation`, params);
      dispatch(requestConversations(data.orderId));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_message_send_error'));
    }
  },
);

export const requestCreateConversationForVirtualOrder = createAsyncThunk(
  'orders/createEmployeeConversationsForVirtualOrder',
  async (data, { extra, dispatch, getState }) => {
    const { employee: { booking: { filters } } } = getState();
    try {
      const createOrderParams = {
        project_id: data.order.projectId,
        order_date: data.order.date,
      };
      const params = {
        conversation: {
          body: data.message,
        },
      };
      const order = await requestCreateVirtualOrder(extra.axios, createOrderParams);
      await extra.axios.post(`/api/v3/orders/${order.id}/create_conversation`, params);
      data.history.replace(`/employee/order?orderId=${order.id}`);
      dispatch(requestConversations(order.id));
      dispatch(requestOrders(filters));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_message_send_error'));
    }
  },
);
