export const weekViewResourceWidth = '14%';
export const nonWeekViewResourceWidth = '4.8%';

export const eventPadding = 22;
export const supervisorNoEventPadding = 25;
export const eventMargin = 1;
export const timeReportEventHeight = 140;
export const eventHeightInclusiveEmployee = 100;
export const eventHeightInclusiveEmployeeForBooking = 68;
export const eventHeightResourceTypeUserGroup = 117;
export const eventHeightResourceTypeUserGroupForBooking = 86;
export const eventHeightExclusiveEmployee = 83;
export const eventHeightExclusiveEmployeeForBooking = 56;
export const timeSlotHeight = 18;
export const punchEventHeight = 30;

export const unassignedId = 'unassigned';

export const viewTypes = {
  day: 1,
  week: 2,
  month: 3,
};

export const viewTypeNames = {
  1: 'day',
  2: 'week',
  3: 'month',
};

export const itemTypes = {
  event: 'event',
  timeReport: 'timeReport',
  request: 'request',
};

export const eventColor = {
  Active: '#d1d1d1',
  Canceled: '#eab7b7',
  Completed: '#b7eab9',
};

export const orderEventColor = {
  Active: '#E7F2FF',
  Completed: '#E6FFED',
  Canceled: '#FFDBDB',
  Freeze: '#DEDEDE',
};

export const orderEventTextColor = {
  Active: '#0573F0',
  Completed: '#0DBB00',
  Canceled: '#FF3131',
  Freeze: '#707070',
};

export const orderEventIconColor = {
  Active: '#98C8FF',
  Completed: '#5BDB51',
  Canceled: '#FE9999',
  Freeze: '#707070',
};

export const eventFlagColor = {
  Active: '#6B6B6B',
  Canceled: '#E6194B',
  Completed: '#3BC062',
};

export const eventColorLight = {
  Active: '#F5F5F5',
  Completed: '#F6FAF2',
  Canceled: '#FAF2F2',
};

export const resourceTypes = {
  employee: 1,
  service: 2,
  customer: 3,
  userGroup: 4,
};

export const eventsOrderTypes = {
  bookingsOnTop: 0,
  time: 1,
};
