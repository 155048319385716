/* eslint-disable init-declarations */
/* eslint-disable no-bitwise */
/* eslint-disable require-unicode-regexp */
// Shards Dashboards Colors

import camelize from 'camelize';
import { bookingStatus, parentBookingStatus } from './enum';
import { fm } from './string';

// Base color class
class Color {
  constructor (value) {
    this.value = value;
  }

  toHex () {
    return this.value;
  }

  toRGBA (opacity = 1) {
    let c;
    if ((/^#([A-Fa-f0-9]{3}){1,2}$/).test(this.value)) {
      c = this.value.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = `0x${c.join('')}`;
      return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${opacity})`;
    }
  }
}

const grays = {
  white: new Color('#ffffff'),
  gray100: new Color('#f8f9fa'),
  gray200: new Color('#e9ecef'),
  gray300: new Color('#dee2e6'),
  gray400: new Color('#ced4da'),
  gray500: new Color('#adb5bd'),
  gray600: new Color('#868e96'),
  gray700: new Color('#495057'),
  gray800: new Color('#343a40'),
  gray900: new Color('#212529'),
  black: new Color('#000'),
};

export const colors = {
  black: '#1E1E1E',
  blueishGrey: '#5A6169',
  skyBlue: '#E7F2FF',
  blue: '#007bff',
  black: '#1E1E1E',
  blueLight: '#418CFD',
  aliceBlue: '#F0F5FC',
  indigo: '#674eec',
  purple: '#8445f7',
  purpleLight: '#A887E7',
  pink: '#ff4169',
  red: '#F55145',
  danger2: '#FFECEC',
  orange: '#fb7906',
  orangeLight: '#ED7D58',
  yellow: '#ffb400',
  green: '#17c671',
  lightGreen: '#F2FFF0',
  darkGreen: '#56B747',
  teal: '#1adba2',
  cyan: '#00b8d8',
  gray: '#B7B7B7',
  grey1: '#8A8A8A',
  grey3: '#E0E0E0',
  grey4: '#EEEEEE',
  grey5: '#737373',
  white2: '#F8F8F8',
  white3: '#FAFAFA',

  grayDark: grays.gray800,
  grayLight: '#707070',
  grey1: '#8A8A8A',
  white: '#FFFFFF',
  white3: '#FAFAFA',
};

// Custom colors specific to Shards Dashboards, includes the new "grays".
const newColors = {
  fiordBlue: new Color('#3D5170'),
  reagentGray: new Color('#818EA3'),
  shuttleGray: new Color('#5A6169'),
  mischka: new Color('#CACEDB'),
  athensGray: new Color('#E9ECEF'),
  salmon: new Color('#FF4169'),
  royalBlue: new Color('#674EEC'),
  java: new Color('#1ADBA2'),
};

const themeColors = {
  accent: colors.blue,
  primary: colors.blue,
  secondary: colors.blueishGrey,
  success: colors.green,
  info: colors.cyan,
  warning: colors.yellow,
  danger: colors.red,
  light: grays.gray200,
  dark: grays.gray800,
};

export const employeeColors = {
  grey: '#a9a9a9',
  maroon: '#800000',
  olive: '#808000',
  teal: '#469990',
  navy: '#000075',
  black: '#000000',
  red: '#e6194B',
  orange: '#f58231',
  yellow: '#ffe119',
  green: '#3cb44b',
  cyan: '#42d4f4',
  purple: '#911eb4',
};

export const colourOptions = [
  { value: '#a9a9a9', color: '#a9a9a9', label: fm('Gray', 'Gray') },
  { value: '#800000', color: '#800000', label: fm('Maroon', 'Maroon') },
  { value: '#808000', color: '#808000', label: fm('Olive', 'Olive') },
  { value: '#469990', color: '#469990', label: fm('Teal', 'Teal') },
  { value: '#000075', color: '#000075', label: fm('Blue', 'Blue') },
  { value: '#000000', color: '#000000', label: fm('Black', 'Black') },
  { value: '#e6194B', color: '#e6194B', label: fm('Pink', 'Pink') },
  { value: '#f58231', color: '#f58231', label: fm('Orange', 'Orange') },
  { value: '#ffe119', color: '#ffe119', label: fm('Yellow', 'Yellow') },
  { value: '#3cb44b', color: '#3cb44b', label: fm('Green', 'Green') },
  { value: '#42d4f4', color: '#42d4f4', label: fm('SkyBlue', 'SkyBlue') },
  { value: '#911eb4', color: '#911eb4', label: fm('Purple', 'Purple') },
];

// Booking status colors
export const statusColors = { Active: '#D1D1D1', Completed: '#B7EAB9', Canceled: '#EAB7B7' };
// Allow users to override any color
const overrides = (window.ShardsDashboards && window.ShardsDashboards.colors)
  ? window.ShardsDashboards.colors : {};

// Parse overriden colors
if (Object.keys(overrides).length !== 0 && overrides.constructor === Object) {
  for (const colorName in overrides) {
    if (overrides.hasOwnProperty(colorName)) { // eslint-disable-line
      if (!(/^#([A-Fa-f0-9]{3}$)|([A-Fa-f0-9]{6}$)/).test(overrides[colorName])) {
        throw new Error('Please provide a hexadecimal color value if you are trying to override the Shards Dashboards colors.');
      }
      overrides[camelize(colorName)] = new Color(overrides[colorName]);
    }
  }
}

export default ({
  icon: '#a9a9a9',
  tabColor: '#363D46',
  projectIndicatorColor: '#F0AF07',
  buttonLink: '#868d92',
  settingsIconBgColor: '#1891DC',
  borderColor: '#E2E2E2',
  ...grays,
  ...colors,
  ...newColors,
  ...themeColors,
  ...overrides,
  ...statusColors,
});

export const bookingModalStatusColor = {
  [bookingStatus.active]: '#f5f5f5',
  [bookingStatus.completed]: '#90ee90',
  [bookingStatus.cancelled]: '#ffcccb',
};

export const recurringParentStatus = {
  [parentBookingStatus.ongoing]: '#f5f5f5',
  [parentBookingStatus.tohandle]: '#F2F0B7',
  [parentBookingStatus.expired]: '#ff9ca1',
};

export function isColorLight(color = '') {
  const hex = color.replace('#', '');
  const cR = parseInt(hex.substr(0, 2), 16);
  const cG = parseInt(hex.substr(2, 2), 16);
  const cB = parseInt(hex.substr(4, 2), 16);
  const brightness = ((cR * 299) + (cG * 587) + (cB * 114)) / 1000;
  return brightness > 155;
}
