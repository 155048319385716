import { makeStyles } from 'components/library';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    color: '#7d7d7d',
    cursor: 'pointer',
    '&:hover': {
      color: '#1891DC',
    },
  },
});

export default useStyles;
