import { makeStyles } from 'components/library';

const useStyles = makeStyles({
  container: { backgroundColor: '#F5F5F5', height: '100%' },
  lightGray: { color: '#5d5d5d' },
  freeTrial: { color: '#5d5d5d' },
  aboutYourselfText: { color: '#484848' },
  link: {
    textDecoration: 'none',
    color: '#7d7d7d',
    cursor: 'pointer',
    '&:hover': {
      color: '#1891DC',
    },
  },
  readMoreButton: { cursor: 'pointer' },
});

export default useStyles;
