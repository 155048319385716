/* eslint-disable camelcase */
import axios from 'utils/Api';
import axiosNext from 'utils/axios';
import { storeAxios } from 'appRedux/store';
import { batch } from 'react-redux';
import initialFiltersState from 'appRedux/reducers/filters/initialState';
import { fm, deepTrim } from 'utils/string';
import { appRoutes, signUpSteps } from 'utils/enum';
import { get, omit, isEmpty } from 'utils/lodash';
import {
  FETCH_ERROR,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  setAuthFields,
  setSnackbarError,
  setSnackbarSuccess,
  setInitialFilters,
} from 'appRedux/actions';
import {
  getFormattedContractDetailsRequestBody,
  getFormattedUserDataForRegister,
} from 'appRedux/thunks/auth/selector';
import { requestProfile } from '../profile';

export const setInitUrl = (url) => ({
  payload: url,
  type: INIT_URL,
});

// Sign out
export const userSignOut = () => (dispatch) => {
  const userWasLoggedIn = Boolean(localStorage.getItem('token'));
  localStorage.removeItem('token');
  localStorage.removeItem('main_company_token');
  localStorage.removeItem('role');
  dispatch({ type: SIGNOUT_USER_SUCCESS });
  if (userWasLoggedIn) window.location.reload();
};

export const resetInitialFilters = () => (dispatch) => {
  try {
    const savedFilters = JSON.parse(localStorage.getItem('filters') || {});
    if (!savedFilters) return;

    const fieldsToOmit = ['startDate', 'endDate', 'date', 'page', 'view'];
    const names = Object.keys(savedFilters.names).reduce((result, name) => {
      const savedNamedFilters = omit(fieldsToOmit)(savedFilters.names[name]);
      result[name] = { ...initialFiltersState.names[name], ...savedNamedFilters };
      return result;
    }, {});

    dispatch(setInitialFilters({ names }));
  } catch (err) {
    console.log(err); // eslint-disable-line
  }
};

export const requestSignupSteps = (history) => async (dispatch) => {
  try {
    const response = await axios.get('/api/v3/registration_steps');
    if (!isEmpty(response.data.data.steps)) {
      const { step } = response.data.data.steps;
      dispatch(setAuthFields({ step }));
      if (history && step < signUpSteps.createEmployee) {
        history.push(appRoutes.companyDetails);
      }
    } else {
      dispatch(setAuthFields({ step: signUpSteps.createEmployee }));
    }
  } catch (err) {
    dispatch(setSnackbarError('snackbar.auth.signup.steps.error'));
  }
};

export const requestDeleteRegistrationSteps = (history) => async (dispatch) => {
  try {
    await axios.delete('/api/v3/registration_steps');
    if (history) {
      history.replace(appRoutes.calendar);
    }
  } catch (err) {
    dispatch(setSnackbarError('snackbar.registrationStep.delete.error'));
  }
};

// Signin
export const userSignIn = (details) => async (dispatch) => {
  try {
    const body = deepTrim({
      email: details.email,
      password: details.password,
      authorization_code: details.authorizationCode,
    });
    const { data } = await axios.post('/api/v3/auth/login', body);
    storeAxios.defaults.headers.Authorization = `Bearer ${data.data.user.token}`;
    axios.defaults.headers.Authorization = `Bearer ${data.data.user.token}`;
    axiosNext.defaults.headers.Authorization = `Bearer ${data.data.user.token}`;
    localStorage.setItem('token', data.data.user.token);
    localStorage.setItem('role', data.data.user.role);
    dispatch(resetInitialFilters());
    dispatch(setAuthFields({
      authUser: data.data.user,
      role: data.data.user.role,
      token: data.data.user.token,
    }));
  } catch (error) {
    if (!error.response) return dispatch(setSnackbarError('snackbar.auth.internet.error'));

    if (error.response.status === 422) {
      return dispatch(setAuthFields({ errorMessage: fm(get('response.data.errors[0].details.code')(error)) }));
    }

    if ([423, 400].includes(error.response.status)) {
      const { status, data } = error.response;
      const remainingLoginAttempts = get('errors[0].details.remaining_attempts')(data);
      let loginError = null;
      if (status === 423) loginError = fm('auth.login.423');
      if (status === 400) {
        loginError = remainingLoginAttempts
          ? fm('auth.login.400.attempts.remaining', undefined, { remainingLoginAttempts })
          : fm('auth.login.400');
      }
      return dispatch(setAuthFields({ errorMessage: fm(loginError) }));
    }
    dispatch(setSnackbarError('snackbar.auth.signIn.error'));
  }
};

// sign up
export const userSignUp = (details, history) => async (dispatch) => {
  try {
    dispatch(setAuthFields({ fsip: true }));
    const timestamp = Date.now();
    const requestBody = deepTrim(getFormattedUserDataForRegister(details));
    const singupFingerprint = btoa(`${timestamp}-${requestBody.user.email}`);
    const { data } = await axios.post(
      '/api/v3/auth/signup',
      requestBody,
      { headers: { 'X-Timestamp': timestamp, 'X-Session-token': singupFingerprint } },
    );
    if (data.success) {
      dispatch(setSnackbarSuccess('snackbar.auth.email.sent'));
      setTimeout(() => {
        history.push('/login');
        dispatch(setAuthFields({ fsip: false, signupFormErrors: {} }));
      }, 2000);
    } else {
      dispatch({ payload: 'Network Error', type: FETCH_ERROR });
    }
  } catch (error) {
    batch(() => {
      if (!isEmpty(get('response.data.errors[0].details')(error))) {
        const fieldName = Object.keys(get('response.data.errors[0].details')(error))[0];
        const code = get(`response.data.errors[0].details.${fieldName}[0].code`)(error);
        dispatch(setAuthFields({ signupFormErrors: { [fieldName]: fm(code) } }));
      } else {
        dispatch(setSnackbarError('snackbar.auth.singup.error'));
      }
      dispatch(setAuthFields({ fsip: false }));
    });
  }
};

export const requestResetPassword = (email, history) => async (dispatch) => {
  try {
    await axios.post('/users/password', { user: { email } });
    dispatch(setSnackbarSuccess('snackbar.auth.resetPasswordEmail.sent'));
    history.push('/login');
  } catch (err) {
    dispatch(setSnackbarError('snackbar.auth.resetPasswordEmail.error'));
  }
};

export const requestChangePassword = (data, history) => async (dispatch) => {
  try {
    const body = deepTrim({
      password: data.password,
      password_confirmation: data.confirmPassword,
      reset_password_token: data.queryParams.resetPasswordToken,
    });
    await axios.put('/users/password', { user: body });
    dispatch(setSnackbarSuccess('snackbar.auth.passwordChanged'));
    history.push('/login');
  } catch (err) {
    if (get('response.data.errors[0].details.code')(err)) {
      dispatch(setSnackbarError('snackbar.auth.link.expired'));
    }
  }
};

export const requestSubmitContract = (details, history) => async (dispatch) => {
  try {
    dispatch(setAuthFields({ fsip: true }));
    const body = deepTrim(getFormattedContractDetailsRequestBody(details));
    await axios.post('/api/v1/company/submit_contract', { company: body });
    batch(() => {
      dispatch(setAuthFields({ fsip: false }));
      dispatch(requestProfile());
      history.push(appRoutes.signedContractWelcome);
    });
  } catch (err) {
    dispatch(setAuthFields({ fsip: false }));
    dispatch(setSnackbarError('snackbar.auth.submitContract.error'));
  }
};
