class Formatting {
  number = (val, maximumFractionDigits, invalidValue) => {
    const locale = window.locale || 'sv-SE';

    if (!val || Number.isNaN(val)) {
      return Number.isNaN(invalidValue)
        ? invalidValue
        : invalidValue.toLocaleString(locale, { style: 'decimal', maximumFractionDigits, minimumFractionDigits: 0 });
    }

    return Number(val).toLocaleString(locale, { style: 'decimal', maximumFractionDigits, minimumFractionDigits: 0 });
  };

  currency = (val, maximumFractionDigits, invalidValue) => {
    const currency = window.currency || 'SEK';
    const locale = window.locale || 'sv-SE';
    const localeStringOptions = { style: 'currency', currency, maximumFractionDigits, minimumFractionDigits: 0 };
    if (!val || Number.isNaN(val)) {
      return Number.isNaN(invalidValue)
        ? invalidValue
        : invalidValue.toLocaleString(locale, localeStringOptions);
    }
    return Number(val).toLocaleString(locale, localeStringOptions);
  }

  bytesToSize = (valueInBytes, defaultValue = 0) => {
    const bytes = valueInBytes || defaultValue;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10) || 0;
    if (i === 0) return `${bytes} ${sizes[i]}`;
    return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`;
  };
}

export default new Formatting();
