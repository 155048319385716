import React from 'react';
import PropTypes from 'prop-types';
import Logo from 'images/Logo-bos.png';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userSignIn } from 'appRedux/thunks/auth';
import { setAuthFields } from 'appRedux/actions';
import { parseQueryString, fm } from 'utils/string';
import {
  Box,
  Alert,
  ComposedTextField,
  IconButton,
  Hidden,
  Grid,
  Container,
  Typography,
  InputAdornment,
} from 'components/library';
import LoadingButton from 'components/common/LoadingButton';
import { VisibilityIcon, VisibilityOffIcon } from 'components/library/icons';
import ErrorBoundary from 'components/common/ErrorBoundary';
import Styles from './style.module.scss';

export class Login extends React.Component {
  constructor (props) {
    super(props);
    const { fortnoxAuthCode } = parseQueryString(props.location.search);
    props.setAuthFields({ errorMessage: '' });

    this.state = {
      email: '',
      password: '',
      authorizationCode: fortnoxAuthCode,
    };
  }

  componentDidUpdate () {
    if (this.props.auth.token) {
      this.props.history.push('/');
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.userSignIn(this.state);
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleClickShowPassword = () => {
    const { hidePassword } = this.props.auth;
    this.props.setAuthFields({ hidePassword: !hidePassword });
  }

  render () {
    const { email, password } = this.state;
    const { application, auth } = this.props;
    const { hidePassword, errorMessage } = auth;
    const hasError = Boolean(errorMessage);

    return (
      <ErrorBoundary>
        <Container component="main" maxWidth="xs">
          <Grid container alignItems="center" alignContent="center" spacing={2}>
            <Hidden lgUp>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <img
                    height={24}
                    width="auto"
                    src={Logo}
                    alt="Bokning och Schema Online Logo"
                  />
                </Grid>
              </Grid>
            </Hidden>
            <Grid item xs={12}>
              <Typography component="span">
                <Box fontWeight="bold" fontSize={30} color="#484848">
                  {fm('login')}
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <form onSubmit={this.handleSubmit}>
                <Grid container alignItems="center" justifyContent="center" spacing={2}>
                  <Grid item xs={12}>
                    <ComposedTextField
                      size="small"
                      type="email"
                      id="username"
                      name="email"
                      placeholder={fm('email', 'Email')}
                      value={email}
                      onChange={this.handleChange}
                      autoFocus
                      fullWidth
                      label={fm('email', 'Email')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ComposedTextField
                      size="small"
                      type={hidePassword ? 'password' : 'text'}
                      id="password"
                      placeholder={fm('password', 'Password')}
                      name="password"
                      value={password}
                      onChange={this.handleChange}
                      autoComplete="password"
                      fullWidth
                      label={fm('password', 'Password')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={this.handleClickShowPassword}
                              edge="end"
                            >
                              {hidePassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  {hasError && (
                    <Alert severity="error">
                      {errorMessage}
                    </Alert>
                  )}
                  <Grid item xs={12}>
                    <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                      <Grid item className={Styles.resetLink}>
                        <Link to="/forgot-password" variant="body2" className={Styles.link}>
                          {fm('change_password')}
                        </Link>
                      </Grid>
                      <Grid item>
                        <LoadingButton type="submit" id="login-form-submit" loading={application.isLoading}>
                          {fm('login', 'Access Account')}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </form>
            </Grid>
            <Grid item xs={12}>
              <Box textAlign="right">
                <Link to="/register" className={Styles.createNewLink}>
                  {fm('create_company_account')}
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  application: state.application,
});

Login.propTypes = {
  setAuthFields: PropTypes.func,
  userSignIn: PropTypes.func,
  auth: PropTypes.shape({
    errorMessage: PropTypes.string,
    hidePassword: PropTypes.bool,
    token: PropTypes.string,
  }),
  application: PropTypes.shape({
    isLoading: PropTypes.bool,
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default connect(mapStateToProps, { userSignIn, setAuthFields })(Login);
