import { createSlice } from '@reduxjs/toolkit';
import { startOfWeek, formatDate, addDays, formatStrings } from 'utils/dateTime';
import {
  requestTodaysShiftDetails,
  requestCreateSalaryCodeRecord,
  requestTimeManagementDayShift,
  requestTimeCodes,
  requestEmployeeShifts,
  requestUpdateSalaryCodeRecord,
  requestCreateExtraTimeRecord,
  requestUpdateExtraTimeRecord,
  requestShiftDetails,
  requestApproveShiftTime,
  requestDeleteShiftTime,
  requestShiftTimes,
  requestEmployeeShiftsRecords,
} from './thunk';

const profile = createSlice({
  name: 'timeManagement',
  initialState: {
    filters: {
      startDate: formatDate(startOfWeek(new Date()), formatStrings.filtersDate),
      endDate: formatDate(addDays(startOfWeek(new Date()), 7), formatStrings.filtersDate),
      page: 1,
      perPage: 10,
      totalRecords: 0,
    },
    employeeDayShifts: [],
    timeCodes: {},
    dayShift: {},
    shiftTime: {},
    punchedShift: {},
    showEmployeeShiftTimeModal: false,
    openShiftTimeId: null,
    shiftTimeModalErrors: {},
    totals: {},
    employeeDayShiftsRecords: [],
  },
  reducers: {
    setTimeManagementFields: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setTimeManagementFilters: (state, action) => ({
      ...state,
      filters: {
        ...state.filters,
        ...action.payload,
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(requestTodaysShiftDetails.fulfilled, (state, action) => ({
      ...state,
      dayShift: action.payload,
    }));
    builder.addCase(requestTimeManagementDayShift.fulfilled, (state, action) => ({
      ...state,
      dayShift: action.payload,
    }));
    builder.addCase(requestTimeCodes.fulfilled, (state, action) => ({
      ...state,
      activeTimeCodes: action.payload,
    }));
    builder.addCase(requestEmployeeShifts.fulfilled, (state, action) => ({
      ...state,
      employeeDayShifts: action.payload,
    }));
    builder.addCase(requestEmployeeShiftsRecords.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }));
    builder.addCase(requestUpdateSalaryCodeRecord.rejected, (state, action) => ({
      ...state,
      shiftTimeModalErrors: action.payload.shiftTimeModalErrors,
    }));
    builder.addCase(requestUpdateSalaryCodeRecord.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }));
    builder.addCase(requestCreateSalaryCodeRecord.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }));
    builder.addCase(requestCreateSalaryCodeRecord.rejected, (state, action) => ({
      ...state,
      shiftTimeModalErrors: action.payload.shiftTimeModalErrors,
    }));
    builder.addCase(requestCreateExtraTimeRecord.rejected, (state, action) => ({
      ...state,
      shiftTimeModalErrors: action.payload.shiftTimeModalErrors,
    }));
    builder.addCase(requestCreateExtraTimeRecord.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }));
    builder.addCase(requestUpdateExtraTimeRecord.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }));
    builder.addCase(requestShiftDetails.fulfilled, (state, action) => ({
      ...state,
      shiftTime: action.payload,
    }));
    builder.addCase(requestApproveShiftTime.fulfilled, (state, action) => ({
      ...state,
      shiftTime: action.payload,
    }));
    builder.addCase(requestDeleteShiftTime.fulfilled, (state, action) => ({
      ...state,
      showEmployeeShiftTimeModal: false,
      employeeDayShifts: state.employeeDayShifts.filter((shift) => shift.id !== action.payload.deletedShiftId),
    }));
    builder.addCase(requestShiftTimes.fulfilled, (state, action) => ({
      ...state,
      records: action.payload.records,
      totals: action.payload.totals,
    }));
  },
});

const { reducer, actions } = profile;

export const { setTimeManagementFields, setTimeManagementFilters } = actions;

export default reducer;
