import React from 'react';
import { Typography, Box } from 'components/library';
import axios from 'utils/Api';
import axiosNext from 'utils/axios';
import { batch } from 'react-redux';
import { fm, deepTrim } from 'utils/string';
import camelize from 'camelize';
import { get, groupBy, noop } from 'utils/lodash';
import { multiStateGet } from 'appRedux/reducers/utility';
import { names, signUpSteps, userRoles } from 'utils/enum';
import { validateBreakTime, validateSmsSenderName } from 'appRedux/validators/profile';
import { requestSmsTemplates } from 'appRedux/thunks/sms';
import { userSignOut, requestSignupSteps, requestDeleteRegistrationSteps } from 'appRedux/thunks/auth';
import {
  getFormattedProfile,
  formatProfileDataForApiRequest,
  getFormattedFortnoxAccountsData,
  formatCompanyDataForApiRequest,
  getFormattedProfileUser,
  getFormattedUpdateBreakTimeApiRequest,
  getFormattedUpdateInvoiceRowDateApiRequest,
  getFormattedFeedbackRequestBody,
} from 'appRedux/selectors/profile';
import { setProfileFields as setEmployeeProfileFields } from 'appRedux/employee/profile';
import {
  setFilters,
  resetFilters,
  setProfile,
  setSnackbarError,
  setSnackbarSuccess,
  setFortnoxAccountsFields,
  setBreakTimeSettingFields,
  setInfoModalFields,
  setUserFeedbackFields,
} from 'appRedux/actions';

export * from './billing';
export * from './storage';
export * from './organizationSettings';

// get user profile detail
export const requestProfile = () => async (dispatch) => {
  try {
    const response = await axiosNext.get('/api/v1/profile/details');
    const profile = getFormattedProfile(response.data.data);
    dispatch(setProfile(profile));
  } catch (err) {
    dispatch(setProfile({ companyActive: get('response.data.errors[0].details.company_active')(err) }));
    dispatch(setSnackbarError('snackbar.profile.gettingProfile.error'));
  }
};

export const requestSubmitUserFeedbackFields = (details) => async (dispatch) => {
  try {
    const requestBody = deepTrim(getFormattedFeedbackRequestBody(details));
    await axios.post('/api/v3/company/submit_review_detail', requestBody);
    dispatch(setSnackbarSuccess('snackbar_feedback_success_submit'));
    dispatch(setUserFeedbackFields({ showFeedbackDialog: false }));
  } catch {
    dispatch(setSnackbarError('snackbar_feedback_error'));
  }
};

export const requestCancelUserFeedbackFields = () => async (dispatch) => {
  try {
    await axios.post('/api/v3/company/submit_review_detail', {});
    dispatch(setUserFeedbackFields({ showFeedbackDialog: false }));
  } catch {
    dispatch(setSnackbarError('snackbar_feedback_error'));
  }
};

export const requestUpdateProfile = (details) => async (dispatch, getState) => {
  try {
    const { auth: { role } } = getState();
    const body = formatProfileDataForApiRequest(details);
    dispatch(setProfile({ fsip: true }));
    const response = await axios.put('/api/v3/company/update_user_detail', body);
    const userData = getFormattedProfileUser(response.data.data);
    if (role === userRoles.employee) {
      dispatch(setEmployeeProfileFields({ user: userData, showProfileModal: false }));
      dispatch(setProfile({ showEmailModal: false, fsip: false }));
    } else {
      dispatch(setProfile({
        showProfileModal: false,
        user: userData,
        showEmailModal: false,
        profileModalError: null,
        fsip: false,
      }));
    }
    dispatch(setSnackbarSuccess('snackbar.profile.updated'));
  } catch (err) {
    const errorCode = get('response.data.errors[0].details.email[0].code')(err);
    if (errorCode) {
      dispatch(setProfile({ emailUpdateDialogError: fm(errorCode) }));
    }
    dispatch(setSnackbarError('snackbar.profile.update.error'));
  } finally {
    dispatch(setProfile({ fsip: false }));
  }
};

export const requestUpdateCompanyDetails = (details) => async (dispatch, getState) => {
  const { profile: { company } } = getState();
  try {
    dispatch(setProfile({ fsip: true }));
    const body = formatCompanyDataForApiRequest(details);
    await axios.put('/api/v3/company', body);
    dispatch(setProfile({ company: { ...company, ...details.company }, showCompanyUpdateModal: false }));
    dispatch(setSnackbarSuccess('snackbar_company_profile_updated'));
    dispatch(setProfile({ fsip: false }));
  } catch (err) {
    dispatch(setProfile({ fsip: false }));
    if (err.name === 'ValidationError') return dispatch(setProfile({ profileModalError: err.message }));
    dispatch(setSnackbarError('snackbar_company_profile_update_error'));
  }
};

export const requestFortnoxIntegration = (details) => async (dispatch, getState) => {
  const { auth } = getState();
  try {
    const [endPoint, axiosFn] = auth.role === userRoles.superAdmin
      ? ['/api/v3/super_admin/fortnox_integrations/add', axios.post]
      : ['/api/v1/company/integrate_fortnox', axios.put];
    await axiosFn(endPoint, details);
    dispatch(setSnackbarSuccess('snackbar.profile.fortnoxIntegrated'));
    setTimeout(() => {
      window.location.replace('/');
    }, 500);
    dispatch(requestProfile());
  } catch (err) {
    if (get('response.data.errors[0].status')(err) === 409) {
      return dispatch(setSnackbarError('snackbar.profile.fortnoxAlreadyIntegrated.error'));
    }
    dispatch(setSnackbarError('snackbar.profile.fortnoxIntegrated.error'));
  }
};

// integrate fortnox
export const requestVismaIntegration = ({ code }) => async (dispatch) => {
  try {
    await axios.put('/api/v1/company/integrate_visma', { code });
    dispatch(setSnackbarSuccess('snackbar.profile.vismaIntegrated'));
    setTimeout(() => {
      window.location.replace('/');
    }, 500);
    dispatch(requestProfile());
  } catch (err) {
    const errorCode = get('response.data.errors[0].details.ErrorCode')(err);
    if (errorCode === 4002 || errorCode === 4005) {
      const errorMessage = get('response.data.errors[0].details.DeveloperErrorMessage')(err);
      dispatch(setInfoModalFields({
        callBack: noop,
        message: (
          <>
            <Typography variant="body2"><Box mt={2}>{fm('visma_integration_licence_missing')}</Box></Typography>
            <Typography variant="caption"><Box mt={2}>{fm('error_missing_license')}</Box></Typography>
            <Typography variant="caption"><Box mt={2}>{fm('visma_integration_error_message')}</Box></Typography>
            <Typography variant="caption">
              <Box fontStyle="italic">{errorMessage}</Box>
            </Typography>
          </>
        ),
        open: true,
        title: fm('visma_error'),
      }));
    }
    if (get('response.errors[0].status')(err) === 409) {
      return dispatch(setSnackbarError('snackbar.profile.fortnoxIntegrated.error'));
    }
    dispatch(setSnackbarError('snackbar.profile.vismaIntegrated.error'));
  }
};

// remove fortnox integration
export const requestRemoveFortnoxIntegration = () => async (dispatch, getState) => {
  const { auth } = getState();
  try {
    const [endPoint, axiosFn] = auth.role === userRoles.superAdmin
      ? ['/api/v3/super_admin/fortnox_integrations/remove', axios.delete]
      : ['/api/v1/company/remove_fortnox', axios.put];
    await axiosFn(endPoint);
    dispatch(resetFilters(names.items));
    dispatch(requestProfile());
    dispatch(setSnackbarSuccess('snackbar.profile.fortnoxIntegrationRemoved'));
  } catch (err) {
    dispatch(setSnackbarError('"snackbar.profile.fortnoxIntegrationRemove.error'));
  }
};

// remove visma integration
export const requestRemoveVismaIntegration = () => async (dispatch) => {
  try {
    await axios.put('/api/v1/company/remove_visma');
    dispatch(setSnackbarSuccess('snackbar.profile.vismaIntegrationRemoved'));
    dispatch(resetFilters(names.items));
    dispatch(requestProfile());
  } catch (err) {
    dispatch(setSnackbarError('"snackbar.profile.vismaIntegrationRemove.error'));
  }
};

// activate sms
export const requestActivateSms = () => async (dispatch) => {
  try {
    await axios.put('/api/v1/company/activate_sms');
    batch(() => {
      dispatch(requestProfile());
      dispatch(requestSmsTemplates());
      dispatch(setSnackbarSuccess('snackbar.profile.smsActivated'));
    });
  } catch (err) {
    dispatch(setSnackbarSuccess('snackbar.profile.smsActivate.error'));
  }
};

// deactivate sms
export const requestDeactivateSms = () => async (dispatch) => {
  try {
    await axios.put('/api/v1/company/deactivate_sms');
    dispatch(requestProfile());
    dispatch(setSnackbarSuccess('snackbar.profile.smsDeactivated'));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.profile.smsDeactivate.error'));
  }
};

// get fortnox accounts
export const requestFortnoxAccounts = (name) => async (dispatch, getState) => {
  const { filters, profile } = getState();
  try {
    const { page, perPage } = multiStateGet(name, filters);
    const query = { per_page: perPage, page };
    const response = await axios.get('/api/v3/accounts', { params: query });
    const defaultAccount = camelize(get('data.data.default_account')(response) || {});
    const accounts = get('data.data.accounts')(response);
    const { records, ...rest } = getFormattedFortnoxAccountsData(accounts, profile);
    dispatch(setFortnoxAccountsFields({ records, defaultAccount }));
    dispatch(setFilters({ ...rest, name }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.profile.getFortnoxAccounts.error'));
  }
};

// request import fortnox accounts
export const requestImportFortnoxAccounts = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/v3/accounts/import');
    dispatch(setFortnoxAccountsFields({ accountsImported: response.data.data.accounts_imported }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.profile.getFortnoxAccounts.error'));
  }
};

// request set default fortnox accounts
export const requestSetDefaultFortnoxAccout = (defaultAccount) => async (dispatch, getState) => {
  const { profile } = getState();
  try {
    const body = { default_account: defaultAccount };
    await axios.put('/api/v1/profile/set_default_account', body);
    const company = { ...profile.company, defaultAccount };
    dispatch(setProfile({ company }));
    dispatch(requestFortnoxAccounts(names.fortnoxAccountsList));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.profile.getFortnoxAccounts.error'));
  }
};

// request search fortnox accounts
export const requestSearchFortnoxAccounts = (query, name) => async (dispatch, getState) => {
  const { profile } = getState();
  try {
    if (!query) {
      return dispatch(setFortnoxAccountsFields({ records: [] }));
    }
    const response = await axios.get('/api/v3/accounts/search', { params: { query } });
    const { records } = getFormattedFortnoxAccountsData({ records: response.data.data }, profile);
    dispatch(setFortnoxAccountsFields({ records }));
    if (name) {
      dispatch(setFilters({ page: 1, pages: 1, totalRecords: records.length, name }));
    }
  } catch (err) {
    dispatch(setSnackbarError('snackbar.profile.getFortnoxAccounts.error'));
  }
};

export const requestBreakTimeSettings = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/v3/break_time_settings');
    const { records = [] } = camelize(response.data.data.break_time_settings || {});
    dispatch(setBreakTimeSettingFields({ records }));
  } catch (err) {
    dispatch(setSnackbarError(fm('snackbar_get_break_time_error')));
  }
};

export const updateBreakTime = (data) => async (dispatch, getState) => {
  const { profile: { breakTimeSettings } } = getState();
  try {
    const validatedData = await validateBreakTime(data);
    const params = getFormattedUpdateBreakTimeApiRequest(validatedData);
    await axios.put(`/api/v3/break_time_settings/${data.id}`, { ...params });
    dispatch(setBreakTimeSettingFields({
      records: (breakTimeSettings.records || []).map((breakTime) => (breakTime.id === data.id ? data : breakTime)),
    }));
    dispatch(setSnackbarSuccess(fm('snackbar_update_break_time_success')));
  } catch (error) {
    if (error.name === 'ValidationError') {
      dispatch(setSnackbarError(error.errors[0]));
    } else {
      dispatch(setSnackbarError(fm('snackbar_update_break_time_error')));
    }
  }
};

export const requestSetSmsSenderName = (senderName) => async (dispatch, getState) => {
  const { profile } = getState();
  try {
    const sender = await validateSmsSenderName({ senderName });
    dispatch(requestActivateSms());
    await axios.put('/api/v1/company/set_sms_sender', { sms_sender: sender.senderName });
    dispatch(setProfile({
      profileErrors: null,
      showActivateSmsModal: false,
      company: { ...profile.company, smsSender: senderName, activateSms: true },
    }));
    dispatch(setSnackbarSuccess('snackbar.profile.set.smsName.success'));
  } catch (error) {
    if (error.name === 'ValidationError') {
      const profileErrors = error.errors[0];
      dispatch(setProfile({ profileErrors }));
    } else {
      dispatch(setSnackbarError('snackbar.profile.smsName.sender'));
    }
  }
};

export const requestUpdateInvoiceRowDateSetting = (invoiceRowDateChecked) => async (dispatch, getState) => {
  const { profile } = getState();
  try {
    const params = getFormattedUpdateInvoiceRowDateApiRequest(invoiceRowDateChecked);
    await axios.put('/api/v3/company/update_invoice_row_date', params);
    const company = {
      ...profile.company,
      integration: { ...profile.company.integration, invoiceRowDate: invoiceRowDateChecked },
    };
    dispatch(setProfile({ company }));
    dispatch(setSnackbarSuccess(fm('snackbar_update_invoice_date_setting_success')));
  } catch (error) {
    dispatch(setSnackbarError(fm('snackbar_update_invoice_date_setting_error')));
  }
};

export const requestPermissionsRecords = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/v3/permissions');
    const records = camelize(response.data.data);
    const permissionRecords = groupBy('role')(records);
    dispatch(setProfile({ permissionRecords }));
  } catch (err) {
    dispatch(setSnackbarError(fm('snackbar_error_fetching_permissions')));
  }
};

export const requestCompanyPermissions = () => async (dispatch) => {
  try {
    const { data } = await axios.get('/api/v3/company/permissions');
    const companyPermissionRecords = camelize(data.data);
    dispatch(setProfile({ companyPermissionRecords }));
  } catch (err) {
    dispatch(setSnackbarError(fm('snackbar_error_while_fetching_company_permissions')));
  }
};

export const requestUpdateCompanyPermission = (permission, updatedPermissions) => async (dispatch) => {
  try {
    const reqBody = { permission_id: permission.id, status: permission.status };
    await axios.put('/api/v3/company/permission_update', reqBody);
    dispatch(setProfile({ companyPermissionRecords: updatedPermissions }));
    dispatch(setSnackbarSuccess(fm('snackbar_update_company_permission_success')));
  } catch (err) {
    dispatch(setSnackbarError(fm('snackbar_error_while_updating_company_permissions')));
  }
};

export const requestUpdateCompanyEmail = (email, bookingIds, callback) => async (dispatch, getState) => {
  const { profile: { company = {} } } = getState();
  try {
    const companyData = { email };
    await axios.put('api/v3/company/email_update', { company: companyData });
    const newCompany = { ...company, email };
    dispatch(setProfile({ company: newCompany }));
    if (callback) callback(bookingIds);
    dispatch(setSnackbarSuccess(fm('snackbar_update_company_email_success')));
  } catch (err) {
    dispatch(setSnackbarError(fm('snackbar_update_company_email_error')));
  }
};

export const requestUpdateExtendableBookingLimit = (limit) => async (dispatch) => {
  try {
    const extendableLimit = {
      extendable_limit: parseInt(limit, 10),
    };
    await axios.put('/api/v3/company/extendable_limit_update', { company: extendableLimit });
    dispatch(setSnackbarSuccess(fm('snackbar_extendable_booking_limit')));
  } catch (err) {
    dispatch(setSnackbarError(fm('snackbar_extendable_booking_limit_error')));
  }
};

export const requestUpdateAutoExtendBookings = (autoExtendBookings) => async (dispatch) => {
  try {
    const reqBody = { auto_extend_bookings: autoExtendBookings };
    await axios.put('/api/v3/company/auto_extend_update', reqBody);
    dispatch(setSnackbarSuccess(fm('snackbar_auto_extend_bookings_settings')));
  } catch (err) {
    dispatch(setSnackbarError(fm('snackbar_auto_extend_bookings_settings_error')));
  }
};

export const requestChangePassword = (data) => async (dispatch) => {
  try {
    const reqBody = {
      current_password: data.current,
      password: data.password,
      confirm_password: data.confirmPassword,
    };
    await axios.put('/api/v3/profile/change_password', reqBody);
    dispatch(setSnackbarSuccess(fm('snackbar_change_password_success')));
    dispatch(setProfile({ showChangePasswordDialog: false }));
    dispatch(setSnackbarSuccess(fm('snackbar_redirecting_to_login')));
    setTimeout(() => {
      dispatch(userSignOut());
    }, 3000);
  } catch (err) {
    const errorCode = get('response.data.errors[0].details.code')(err);
    if (errorCode) {
      dispatch(setProfile({ changePasswordDialogError: fm(errorCode) }));
    }
    dispatch(setSnackbarError(fm('snackbar_change_password_failed')));
  }
};

export const requestRevokeUserActiveSessions = () => async (dispatch) => {
  try {
    await axios.put('/api/v3/profile/revoke_sessions');
    dispatch(userSignOut());
  } catch (err) {
    dispatch(setSnackbarError(fm('snackbar_revoke_sessions_failed')));
  }
};

export const requestDeleteCompanyLogo = () => async (dispatch) => {
  try {
    await axios.delete('/api/v3/company/documents/delete_company_logo');
    dispatch(setSnackbarSuccess('document_removed_successfully'));
    dispatch(requestProfile());
  } catch (err) {
    dispatch(setSnackbarError('snackbar.profile.image.delete.error'));
  }
};

export const requestUpdateRegistrationStepCount = (step, history) => async (dispatch) => {
  try {
    const params = { step };
    const response = await axios.put('/api/v3/registration_steps', params);
    const { step: finalStep } = response.data.data;
    if (finalStep === signUpSteps.createEmployee) {
      dispatch(requestDeleteRegistrationSteps(history));
    }
    dispatch(requestSignupSteps());
  } catch (err) {
    dispatch(setSnackbarError('snackbar.profile.getFortnoxAccounts.error'));
  }
};
